"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var map_1 = require("./map");
function pluck() {
  var properties = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    properties[_i] = arguments[_i];
  }
  var length = properties.length;
  if (length === 0) {
    throw new Error('list of properties cannot be empty.');
  }
  return function (source) {
    return map_1.map(plucker(properties, length))(source);
  };
}
exports.pluck = pluck;
function plucker(props, length) {
  var mapper = function (x) {
    var currentProp = x;
    for (var i = 0; i < length; i++) {
      var p = currentProp != null ? currentProp[props[i]] : undefined;
      if (p !== void 0) {
        currentProp = p;
      } else {
        return undefined;
      }
    }
    return currentProp;
  };
  return mapper;
}
