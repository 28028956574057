<h1 mat-dialog-title>{{ "recordings.edit-name" | translate }}</h1>
<mat-dialog-content>
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<input formControlName="name" type="text" />
	</form>
	<mat-dialog-actions>
		<button class="error-bttn" (click)="close()">
			{{"buttons.cancel" | translate}}
		</button>
		<button class="ok-bttn" [disabled]="!form.valid" (click)="onSubmit()">
			{{"buttons.save" | translate}}
		</button>
	</mat-dialog-actions>
</mat-dialog-content>
