<mat-dialog-content>
	<div class="content">
		<form [formGroup]="youTubeForm" (submit)="open()">
			<button
				[mat-dialog-close]="true"
				type="button"
				title="close"
				class="closeBtn"
				[attr.aria-label]="'aria-label.close-share-youtube' | translate"
			>
				×
			</button>
			<header class="title">
				<mat-icon class="serviceIcon" svgIcon="service-icon"></mat-icon
				><span>{{ "youtube.title" | translate }}</span>
			</header>
			<div class="kicker">{{ "youtube.description" | translate }}</div>
			<div class="urlWrapper">
				<label class="InputWrapper">
					<div
						class="TextInput TextInput--full TextInput--small"
						[ngClass]="{
							'TextInput--error':
								youTubeUrl.errors &&
								youTubeUrl.dirty &&
								!youTubeUrl.errors.required
						}"
					>
						<input
							formControlName="youTubeUrl"
							[attr.aria-label]="
								'aria-label.youTubeUrl-input' | translate
							"
							autofocus
							class="input"
							type="url"
							name="url"
							value=""
							placeholder="{{
								'youtube.placeholder' | translate
							}}"
						/>
					</div>
					<small
						*ngIf="
							youTubeUrl.errors &&
							youTubeUrl.dirty &&
							!youTubeUrl.errors.required
						"
						class="helpText helpText--with-link"
						>{{ "youtube.url-warn" | translate }}</small
					>
					<!--        <a class="AnchorLink" rel="noopener noreferrer" target="_blank" style="font-size: 12px; line-height:18px; padding-top: 2px; float:right;" href="https://www.youtube.com/t/terms">-->
					<!--          YouTube Terms of Service-->
					<!--        </a>-->
				</label>
			</div>
			<button
				type="submit"
				[ngClass]="{ 'Button--disabled': youTubeForm.invalid }"
				class="Button Button--primary Button--small Button--full Button--filled"
				[disabled]="!youTubeForm.valid"
				[attr.aria-label]="'aria-label.play-video' | translate"
			>
				{{ "youtube.play" | translate }}
			</button>
		</form>
	</div>
</mat-dialog-content>
