import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { CaptureMediaService } from '../../../../services/capture-media.service';
import { CAM_1, SCREEN_SHARE } from '../../data/rtc-constants';
import { StreamSettingsStorage } from '../storage/rtc-storage.service';
import { CallStorage } from '../storage/call-storage.service';
import { DisplayStatus } from '../storage/DisplayStatusVO';
import { RoomStateStorage } from '../storage/RoomStateStorage';
import { PlatformDetectorService } from '../../../../services/platform-detector/platform-detector.service';
import { MediaProperiesUtil } from 'src/app/lib-setting/components/setting-popup/setting-popup.interface';
import { EasyRtcService } from '../../../../lib-rtc/services/easy-rtc.service';
import { VideoSettingsService } from '../../../../video-settings/service/video-settings.service';
import { EventSocketService } from '../../../../services/ws/event-socket.service';
import { UsersService } from '../../../../services/users.service';
// refactor and remove global injectable
@Injectable({
  providedIn: 'root'
})
export class RtcScreenShareCtrl {
  public currentVideo: MediaStream;
  private subscribes: Array<Subscription> = new Array<Subscription>();
  private isRejected = false;
  private isSafari: boolean = false;

  get hasCamera(): boolean {
    return VideoSettingsService.mediaPermissions.value.cam;
  }

  constructor(
    private captureMediaService: CaptureMediaService,
    private callStorage: CallStorage,
    private settingsStorage: StreamSettingsStorage,
    private roomState: RoomStateStorage,
    private platformDetectorService: PlatformDetectorService,
    private eventSocketService: EventSocketService,
    private usersService: UsersService
  ) {
    this.isSafari = this.platformDetectorService.isSafari();
  }
  listen() {
    this.subscribes.push(this.roomState.displayMode$.subscribe((status: DisplayStatus) => {
      switch (status) {
        case DisplayStatus.loadingToActive: {
          this.captureMediaService.getDisplay((audio, video) => {
            this.isRejected = false;
            this.start(audio, video);
          }, (reason: any) => { // just know we have a reason
            this.isRejected = true;
            this.roomState.displayMode = DisplayStatus.inactive;
          });
          break;
        }
        case DisplayStatus.loadingToInactive: {
          if (!this.isRejected) {
            this.stop();
          } else {
            this.isRejected = false;
          }
        }
      }
    }));
  }
  disconnect() {
    this.subscribes.map((subscribe) => subscribe.unsubscribe());
    if (this.roomState.isScreenShare === DisplayStatus.active) {
      EasyRtcService.closeLocalStream(SCREEN_SHARE);
    }
  }
  private start(audio: MediaStream, video: MediaStream) {
    this.currentVideo = video;
    // register 'screenshare' stream
    video.addTrack(audio.getAudioTracks()[0]);
    EasyRtcService.register3rdPartyLocalMediaStream(video, SCREEN_SHARE);
    this.addStreamStopListener(video, () => {
      this.closeScreenSharingStream();
    });
    this.settingsStorage.updateSelectedChanel(SCREEN_SHARE);
    /** Unlike shared video, shared audio tracked comes with .enabled === false,
     *  so we need to change it manually in order for others to hear our shared audio
     */
    if (this.roomState.isMicroOn && video.getAudioTracks() && video.getAudioTracks().length > 0) {
       video.getAudioTracks()[0].enabled = true; // enabling shared audio
    }
    this.roomState.displayMode = DisplayStatus.active;
  }
  private async stop() {

    // old code which was useful to join without a cam, forces to turn off cam in firefox

    // if (!this.hasCamera) {
    //  console.log('no camera');
    //   this.usersService.selfUser.isCamera = false;
    //   this.roomState.isCamOn = false;
    //   StorageUtil.writeMediaPrmsToSessionStorage({ isCam: false });
    //   this.eventSocketService.turn('camera', false);
    // }

    if (this.currentVideo) {
      this.currentVideo.getTracks().map((track) => {
        track.stop();
      });
    }
    this.currentVideo = null;
    const mediaProperies = MediaProperiesUtil.readMediaProperiesFromStorage();
    const constraints = MediaProperiesUtil.createConstraints(mediaProperies);
    const stream = await EasyRtcService.initMediaSourceByBrowserSupport(constraints, 'default');
    if (stream) {
      this.roomState.displayMode = DisplayStatus.inactive;
      this.settingsStorage.updateSelectedChanel(CAM_1);
    }
  }
  private addStreamStopListener(stream, callback) {
    function localCallback() {
      callback();
      callback = () => {
      };
    }
    stream.addEventListener('ended', () => {
      localCallback();
    }, false);
    stream.addEventListener('inactive', () => {
      localCallback();
    }, false);
    stream.getTracks().forEach((track) => {
      track.addEventListener('ended', () => {
        localCallback();
      }, false);
      track.addEventListener('inactive', () => {
        localCallback();
      }, false);
    });
  }
  private closeScreenSharingStream = () => {
    if (this.roomState.isScreenShare === DisplayStatus.active) {
      this.roomState.displayMode = DisplayStatus.loadingToInactive;
    }
  }
}
