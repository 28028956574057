import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { InitialAuthorizationService } from 'src/app/lib-core/services/initial-authorization.service';
import { ProfileService } from 'src/app/lib-profile/services/profile.service';
import { ProfileDto } from 'src/app/lib-profile/services/profile-api.interface';


@Injectable()
export class GetProfileDtoResolver  {

  constructor(
    private initialAuthorizationService: InitialAuthorizationService,
    private profileService: ProfileService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ProfileDto | null> {
    const hasInitialAuthorization = this.initialAuthorizationService.getHasInitialAuthorization();
    if (!!hasInitialAuthorization) {
      return this.profileService.get()
        .catch((error) => null);
    } else {
      return Promise.resolve(null);
    }
  }
}
