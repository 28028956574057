<mat-dialog-content>
    <div class="popup-text-title">{{title}}</div>
    <div class="popup-text"><mat-icon class="person-outline-icon">person_outline</mat-icon>
        <div>
            {{'lessons.student-label' | translate}}: {{studentName}}
        </div>
    </div>
    <div class="popup-text">
        <mat-icon class="lesson-calendar" svgIcon="lesson-calendar"></mat-icon>
        <div [innerHtml]="lessonTimeInterval"></div>
    </div>
    <div class="popup-text">
        <mat-icon class="access-time-icon">access_time</mat-icon>
        <div>
            {{duration}}
        </div>
    </div>

    <div class="popup-text bg-red-200 p-2">
        <p>{{'lessons.reschedule-notice' | translate}}</p>
    </div>

    <div class="popup-text">
        <textarea style="border: 1px solid;border-radius: 5px; width: 100%;" rows="6" name="textarea" required
            placeholder="{{'lessons.reschedule-placeholder' | translate}}"
            [formControl]="textareaForm.get('textarea')"></textarea>
    </div>

    <mat-dialog-actions>
        <span class='error-bttn' [mat-dialog-close]="true" (click)="onCancelClick()">
            {{ cancelButtonData.text | translate }}
        </span>
        <button [disabled]="!textareaForm.valid" class='ok-bttn' (click)="onOkClick()">
            {{ okButtonData.text | translate }}
        </button>
    </mat-dialog-actions>
</mat-dialog-content>