@if (!(isLoading$ | async)) {
	@if (studentsList && studentsList.length > 0) {
		<h1 mat-dialog-title [innerHTML]="'material.share-file-title' | translate: { fileName: data.fileData.name } | safeHtml"></h1>
		<mat-dialog-content>
			@for (student of studentsList; track student.id) {
				@if (student.activationStatus === "activated") {
					<div class="user-item">
						<div class="user-info">
							<strong>{{ student.name }}</strong>
							<p>
								<span [ngClass]="student.lessonPackage.status">
									{{ studentStatuses[student.id] | translate }}
								</span>
							</p>
						</div>
						<mat-checkbox
							[checked]="isStudentInSharedList(student.id)"
							(change)="toggleSelection($event, student.id, data.fileData.id)"
						></mat-checkbox>
					</div>
					<hr />
				}
			}
		</mat-dialog-content>
		<mat-dialog-actions>
			<button mat-stroked-button color="primary" (click)="close()">{{ "buttons.cancel" | translate }}</button>
			<button mat-flat-button color="primary" (click)="close()">{{ "buttons.ok" | translate }}</button>
		</mat-dialog-actions>
	} @else {
		<mat-dialog-content>
			<app-empty-list-container message="{{ 'material.no-student-list-message' | translate }}" />
		</mat-dialog-content>
	}
} @else {
	<div class="spinner">
		<mat-spinner></mat-spinner>
	</div>
}
