import {Subject} from 'rxjs';
import {UserVO} from '../../../../services/data/UserVO';
import {ensureGlobalObject} from '../../../../services/utils';

export class UsersStorage {
  private usersPrivate: UserVO[] =  new Array<UserVO>();
  private selfUserPrivate: UserVO = null;
  // todo refactor
  public selfUpdate$: Subject<UserVO> = new Subject<UserVO>();
  public onUsersUpdate$: Subject<boolean> = new Subject();
  getUser(id: string): UserVO {
    const userSearch = this.users.filter(u => u.id === id || u.rtcId === id );
    if (userSearch.length > 0 ) {
      return userSearch[0];
    } else {
      return null;
    }
  }
  getUsersCount(): number {
    ensureGlobalObject('APP.conference').membersCount = this.usersPrivate.filter(user => user.connected && !user.isSpy).length;
    return this.usersPrivate.filter(user => user.connected && !user.isSpy).length;
  }
  get users(): UserVO[] {
    return this.usersPrivate;
  }
  get selfUser(): UserVO {
    return this.selfUserPrivate;
  }
  updateSelfUser (selfUser: UserVO) {
    if (!this.selfUserPrivate || !this.selfUserPrivate.name) {
      this.selfUserPrivate = selfUser;
      this.selfUpdate$.next(selfUser);
    }
  }
  updateUsers (data: UserVO[], removeNotExist?: boolean , selfUser?: UserVO) {
    if ( selfUser ) {
      this.updateSelfUser(selfUser);
    }
    data.forEach((user) => {
      this.updateUser(user, true);
    });
    if (removeNotExist) {
      this.usersPrivate.forEach((cashUser: UserVO) => {
        if ( data.filter(u => u.id === cashUser.id).length === 0 ) {
          cashUser.connected = false;
        }
      });
    }
    this.onUsersUpdate$.next(true);
  }
  updateUser = (user: UserVO, isSilent: boolean = false) => {
    const existUser: UserVO = this.findUser(user);
    if (!existUser) {
      this.users.push(user);
    } else if (!user.isEqual(existUser)) { // update user when it change
      existUser.update(user);
    }
    if (!isSilent) {
      this.onUsersUpdate$.next(true);
    }
  }
  private findUser = (searchUser: UserVO): UserVO => {
    if (searchUser.id || searchUser.rtcId) {
      if (searchUser.id) {
        const answer = this.users.filter(u => u.id === searchUser.id);
        if (answer.length > 0 ) {
          return answer[0];
        }
      }
      if (searchUser.rtcId) {
        const answer = this.users.filter(u => u.rtcId === searchUser.rtcId);
        if (answer.length > 0 ) {
          return answer[0];
        }
      }
      // unknown user
      return null;
    }
  }
}
