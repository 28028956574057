import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RtcStreamViewModel } from '../components/call-room/service/viev-model/rtc-stream-view-model';
import { PlatformDetectorService } from '../services/platform-detector/platform-detector.service';
import { MediaDevicesService } from '../lib-media-devices/services/media-devices.service';

@Injectable({
  providedIn: 'root'
})
export class UnsupportedBrowserService {

  constructor(
    private router: Router,
    private platformDetectorService: PlatformDetectorService,
    private rtcStreamViewModel: RtcStreamViewModel,
    private mediaDevicesService: MediaDevicesService
  ) { }

  checkBrowserSupport(callback?: Function): boolean {
    const result = !this.platformDetectorService.checkSupportBrowserType() ||
      !this.checkWebRTCSupport() || !this.platformDetectorService.isSupportedSafariVersion();
    if (result) {
      if (callback) {
        callback();
      }
      this.router.navigate(['/unsupported-browser'] );
    }

    return result;
  }

  // https://github.com/muaz-khan/DetectRTC/blob/master/dev/DetectRTC.js
  checkWebRTCSupport(): boolean {
    return !!(
      // getUserMedia
      this.mediaDevicesService.hasGetUserMediaEnableForWebRTC()
      &&
      ( // RTC Peer Connection
        (window as any).RTCPeerConnection
        || (window as any).webkitRTCPeerConnection
        || (window as any).mozRTCPeerConnection
        || (window as any).RTCIceGatherer
      )
    );
  }
}
