
// Options Socket Info
export class OptionsVOInfo {
  camera: boolean;
  demand: boolean;
  microphone: boolean;
  recording: boolean;
  reflection: boolean;
  risehand: boolean;
  screenshare: boolean;
  recordingBot: boolean;
  secondStreamActive: boolean;
}

export class OptionsInfoVOUtil {
  public static create(value: Partial<OptionsVOInfo>): OptionsVOInfo {
    return {
      camera: (value.camera != null ? value.camera : false),
      demand: (value.demand != null ? value.demand : false),
      microphone: (value.microphone != null ? value.microphone : false),
      recording: (value.recording != null ? value.recording : false),
      reflection: (value.reflection != null ? value.reflection : false),
      risehand: (value.risehand != null ? value.risehand : false),
      screenshare: (value.screenshare != null ? value.screenshare : false),
      recordingBot: (value.recordingBot != null ? value.recordingBot : false),
      secondStreamActive: (value.secondStreamActive != null ? value.secondStreamActive : true)
    };
  }
}

// User Socket Info
export class UserVOInfo {
  connected: boolean;
  id: string;
  options: OptionsVOInfo;
  permissions: string[];
}

export class UserVOInfoUtil {
  public static create(value: Partial<UserVOInfo>): UserVOInfo {
    return {
      connected: (value.connected != null ? value.connected : false),
      id: value.id,
      options: OptionsInfoVOUtil.create(value.options),
      permissions: (value.permissions || []).slice()
    };
  }
  public static createList(value: Partial<UserVOInfo>[]): UserVOInfo[] {
    const result: UserVOInfo[] = [];
    if (Array.isArray(value)) {
      for (const item of value) {
        result.push(UserVOInfoUtil.create(item));
      }
    }
    return result;
  }
}
