"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ReplaySubject_1 = require("../ReplaySubject");
function shareReplay(configOrBufferSize, windowTime, scheduler) {
  var config;
  if (configOrBufferSize && typeof configOrBufferSize === 'object') {
    config = configOrBufferSize;
  } else {
    config = {
      bufferSize: configOrBufferSize,
      windowTime: windowTime,
      refCount: false,
      scheduler: scheduler
    };
  }
  return function (source) {
    return source.lift(shareReplayOperator(config));
  };
}
exports.shareReplay = shareReplay;
function shareReplayOperator(_a) {
  var _b = _a.bufferSize,
    bufferSize = _b === void 0 ? Number.POSITIVE_INFINITY : _b,
    _c = _a.windowTime,
    windowTime = _c === void 0 ? Number.POSITIVE_INFINITY : _c,
    useRefCount = _a.refCount,
    scheduler = _a.scheduler;
  var subject;
  var refCount = 0;
  var subscription;
  var hasError = false;
  var isComplete = false;
  return function shareReplayOperation(source) {
    refCount++;
    var innerSub;
    if (!subject || hasError) {
      hasError = false;
      subject = new ReplaySubject_1.ReplaySubject(bufferSize, windowTime, scheduler);
      innerSub = subject.subscribe(this);
      subscription = source.subscribe({
        next: function (value) {
          subject.next(value);
        },
        error: function (err) {
          hasError = true;
          subject.error(err);
        },
        complete: function () {
          isComplete = true;
          subscription = undefined;
          subject.complete();
        }
      });
      if (isComplete) {
        subscription = undefined;
      }
    } else {
      innerSub = subject.subscribe(this);
    }
    this.add(function () {
      refCount--;
      innerSub.unsubscribe();
      innerSub = undefined;
      if (subscription && !isComplete && useRefCount && refCount === 0) {
        subscription.unsubscribe();
        subscription = undefined;
        subject = undefined;
      }
    });
  };
}
