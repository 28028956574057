import { Component, Inject } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef
} from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";

@Component({
	selector: "app-file-delete-confirm",
	standalone: true,
	imports: [MatDialogModule, MatListModule, TranslateModule, MatIconModule],
	templateUrl: "./file-delete-confirm.component.html",
	styleUrl: "./file-delete-confirm.component.scss"
})
export class FileDeleteConfirmComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			file: File;
			callBack: () => {};
		},
		private dialogRef: MatDialogRef<FileDeleteConfirmComponent>
	) {}

	public onClose(): void {
		this.dialogRef.close(false);
	}
	public onOkButton(): void {
		if (this.data.callBack) {
			this.data.callBack();
		}
	}
}
