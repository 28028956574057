import { Injectable } from '@angular/core';
import { toBrowserSupported } from '../../services/utils';
import { BotService } from '../../services/bot/bot.service';
import { VideoSettingsService } from '../../video-settings/service/video-settings.service';

interface RtcIceCandidate {
iceServers: ({urls: string} | {urls: string, credential: string, username: string})[];
}

const DELAY_STOP_TRACKS = 200;

declare const easyrtc: any; // todo change to Easyrtc/any only staging

@Injectable({
  providedIn: 'root'
})
export class EasyRtcService {

  constructor() {
  }

  static get getEasyRtcId() {
    return this.easyRtcId;
  }

  static set setEasyRtcId(id) {
     this.easyRtcId = id;
  }

  public static easyRtcId: string;

  public static initMediaSourceByBrowserSupport(config?: MediaStreamConstraints, name?: string): Promise<MediaStream> {
    const { mic, camCanBeCalled } = VideoSettingsService.mediaPermissions.value;
    easyrtc._presetMediaConstraints = config ? toBrowserSupported(config) : null;
    if (BotService.isBot || (mic && !camCanBeCalled)) {
      easyrtc._presetMediaConstraints.video = false;
      easyrtc.videoEnabled = false;
    }
    return new Promise<MediaStream>((resolve, reject) => {
      easyrtc.initMediaSource((stream: MediaStream) => {
        resolve(stream);
      }, (err) => {
        reject(err);
      }, name);
    });
  }

  public static connect(roomName: string, userName: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // Sets the user name associated with the connection. (selfUser.id)
      let reconnectLimit = 4;
      const connectLocal = () => easyrtc.connect(roomName,
        successCallBack, errorCallBack);
      const successCallBack = () => {
        resolve(true);
      };
      const errorCallBack = () => {
        if (reconnectLimit) {
          reconnectLimit -= 1;
          connectLocal();
        } else {
          resolve(false);
        }
      };
      easyrtc.setUsername(userName);
      connectLocal();
    });
  }

  public static setVideoObjectSrc(video, stream: MediaStream) {
    easyrtc.setVideoObjectSrc(video , stream);
  }

  public static getRemoteStream(id: string, streamName: string) {
     return easyrtc.getRemoteStream(id, streamName);
  }

  public static hangupAll() {
    easyrtc.hangupAll();
  }
  /**Allow an externally created mediastream (ie, created by another library) to be used within easyrtc.
   * Tracking when it closes must be done by the supplying party.
   */
  public static register3rdPartyLocalMediaStream(mediaStream: MediaStream, streamName: string): void  {
    easyrtc.register3rdPartyLocalMediaStream(mediaStream, streamName);
  }

  /** Returns a media stream for your local camera and microphone. */
  public static getLocalStream(streamName?: string): MediaStream {
    return easyrtc.getLocalStream(streamName);
  }

  public static enableMicrophone(enable: boolean, streamName?: string): void {
    // This function is used to enable and disable the local microphone.
    // If you disable the microphone, sounds stops being transmitted to your peers.
    // By default, the microphone is enabled.
    easyrtc.enableMicrophone(enable, streamName);
  }

  public static enableCamera(enable: boolean, streamName?: string): void {
    // This function is used to enable and disable the local camera.
    // If you disable the camera, video objects display it will "freeze" until the camera is re-enabled.
    // By default, a camera is enabled
    easyrtc.enableCamera(enable, streamName);
  }

  public static enableVideo(enable: boolean, streamName?: string)  {
    easyrtc.enableVideo(enable, streamName);
  }
  /** Returns the user assigned id's of currently active local media streams. */
  public static getLocalMediaIds(): string[] {
    return easyrtc.getLocalMediaIds();
  }

  public static closeLocalStream(id: string) {
    easyrtc.closeLocalStream(id);
  }

  public static setSocketUrl(rtcWsUrl: string) {
    easyrtc.setSocketUrl(rtcWsUrl);
  }

  public static setIceUsedInCalls(rtcIceCandidate: RtcIceCandidate) {
    easyrtc.setIceUsedInCalls(rtcIceCandidate);
  }

  public static setSdpFilters() {
    easyrtc.setSdpFilters(
      easyrtc.buildLocalSdpFilter({
        audioRecvBitrate: 192000,
        stereo: false
      }),
      easyrtc.buildRemoteSdpFilter({
        audioSendBitrate: 192000,
        stereo: false
      })
    );
  }

  public static setUseFreshIceEachPeerConnection(isConnect: boolean) {
    easyrtc.setUseFreshIceEachPeerConnection(isConnect);
  }

  public static setRecordingVideoCodec(codec: string) {
    easyrtc.setRecordingVideoCodec(codec);
  }

  public static disconnect() {
    easyrtc.disconnect();
  }

  public static idToName(rtcId: string) {
    return easyrtc.idToName(rtcId);
  }

  public static call(rtcId: string, callSuccessCB?, callFailureCB?, wasAcceptedCB?, streamNames?) {
    easyrtc.call(rtcId,callSuccessCB,callFailureCB, wasAcceptedCB, streamNames);
  }

  public static hasWebSocketConnected() {
    return easyrtc.webSocketConnected;
  }

  public static setRoomOccupantListener(fn: (roomName: string, newCallers: any) => void | null) {
     easyrtc.setRoomOccupantListener(fn);
  }

  public static getPeerConnectionByUserId(id: string) {
    return easyrtc.getPeerConnectionByUserId(id);
  }

  public static setAcceptChecker() {
      return easyrtc.setAcceptChecker;
  }

  public static setStreamAcceptor(fn: (easyrtcid: string, stream: MediaStream, streamName: string) => void
  ) {
    easyrtc.setStreamAcceptor(fn);
  }

  public static setOnStreamClosed(callback) {
    return easyrtc.setOnStreamClosed(callback);
  }

  public static addStreamToCall(easyrtcId, streamName, receiptHandler?) {
    return easyrtc.addStreamToCall(easyrtcId, streamName, receiptHandler);
  }

  public static removeStreamFromCall(userRtcId: string, streamName: string) {
    easyrtc.removeStreamFromCall(userRtcId, streamName);
  }

  public static setDisconnectListener() {
    return easyrtc.setDisconnectListener;
  }

  public static setPeerFailingListener() {
    return easyrtc.setPeerFailingListener;
  }

  public static supportsRecording(): boolean {
    return easyrtc.supportsRecording();
  }

  public getVideoSourceList(callback) {
    return easyrtc.getVideoSourceList(callback);
  }

  public setVideoSource(videoSrcId: string) {
     easyrtc.setVideoSource(videoSrcId);
  }

  public static renegotiate() {
    return easyrtc.renegotiate;
  }

  public static closeLocalMediaStream(streamName: string): void {
    easyrtc.closeLocalMediaStream(streamName);
  }

  /** Initializes your access to a local camera and microphone. */
  public initMediaSource(): Promise<MediaStream> {
    return new Promise<MediaStream>((resolve, reject) => {
      easyrtc.initMediaSource(
        (mediaStream: MediaStream) => { // successCallback
          console.log('easyrtc.initMediaSource() ok'); // TODO aam del;
          resolve(mediaStream);
        },
        (errorCode, errorText) => { // errorCallback
          console.error(errorCode, errorText);
          reject({ errorCode, errorText });
        },
      );
    });
  }

  public initMediaSourceTwo(): Promise<MediaStream> {
    return new Promise<MediaStream>((resolve, reject) => {
      easyrtc.initMediaSource(
        (mediaStream: MediaStream) => { // successCallback
          console.log('easyrtc.initMediaSource() ok'); // TODO aam del;
          resolve(mediaStream);
        },
        (errorCode, errorText) => { // errorCallback
          console.error(errorCode, errorText);
          reject({ errorCode, errorText });
        },
        "secondStream"
      );
    });
  }

  public mediaStreamStop(mediaStream: MediaStream | null): void {
    if (!!mediaStream) {
      const trackList: MediaStreamTrack[] = mediaStream.getTracks();
      for (const track of trackList) {
        track.stop();
      }
    }
  }

  public async stopMediaStreamTrack(mediaStreamTracks: MediaStreamTrack[]): Promise<void> {
    if (Array.isArray(mediaStreamTracks) && mediaStreamTracks.length > 0) {
      for (const mediaStreamTrack of mediaStreamTracks) {
        mediaStreamTrack.stop();
      }
      await this.sleep(DELAY_STOP_TRACKS);
    }
    return Promise.resolve();
  }

  private sleep(ms = 100): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public showError(messageCode, message){
    return easyrtc.showError(messageCode, message)
  }

  public static getRoomOccupantsAsArray(roomName) {
    return easyrtc.getRoomOccupantsAsArray(roomName);
  }

  public static getRoomOccupantsAsMap(roomName) {
    return easyrtc.getRoomOccupantsAsMap(roomName);
  }

  public static getRoomsJoined() {
    return easyrtc.getRoomsJoined();
  }
  
  public static myEasyrtcid() {
    return easyrtc.myEasyrtcid
  }

  public static setPeerListener(callback){
    return easyrtc.setPeerListener(callback);
  }

}
