import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ExpirationFreePlanData {
  countDaysTariffFree: number;
  result: boolean;
}

@Component({
  selector: 'app-expiration-free-plan',
  templateUrl: './expiration-free-plan.component.html',
  styleUrls: ['./expiration-free-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpirationFreePlanComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExpirationFreePlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExpirationFreePlanData
  ) {
  }

  ngOnInit() {
  }

  public close(): void {
    this.dialogRef.close();
  }

  public upgrade(result: boolean): void {
    this.dialogRef.close(result);
  }

}
