export function getSubDomain(): string {
  let subDomain = null;

  if (
    !location.hostname.includes('localhost') && // Localhost
    location.hostname.indexOf('192.') !== 0 // Local network host
  ) {
    const hostWords = location.hostname
      .split('.')
      .filter(word => !['sirius', 'admin', 'test-admin', 'test', 'app', 'app-test','rc-test'].includes(word.toLowerCase()));

    if (hostWords.length > 1) { // To avoid getting 'video' or 'com' as a subdomain
      subDomain = hostWords[0];
    }
  }

  // subDomain = 'test'; // Uncomment for local testing

  return subDomain;
}
