import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Subject, from } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CallStorage } from '../../../../call-room/service/storage/call-storage.service';
import { RoomStateStorage } from '../../../../call-room/service/storage/RoomStateStorage';
import { LobbyPopupBaseComponent } from '../lobby-popup-base.component';
import { RoomAuthService } from 'src/app/services/ws/room-auth.service';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { UnsupportedBrowserService } from '../../../../../unsupported-browser/unsupported-browser.service';
import { CODE_MAX_LENGTH, TEXT_NAME_MAX_LENGTH } from '../../../../../constants/generic.constants';
import { WebSocketService } from '../../../../../services/ws/web-socket.service';
import { UsersService } from '../../../../../services/users.service';
import { LocationUtil } from 'src/app/lib-core/utils/location.util';
import { RecordingService } from '../../../../../services/recording/recording.service';
import { RoomService } from 'src/app/services/room.service';

@Component({
  selector: 'app-popup-desktop',
  templateUrl: './lobby-popup-desktop.component.html',
  styleUrls: [
    './lobby-popup-desktop.component.scss',
    './lobby-popup-desktop.component.tablet.scss',
    './lobby-popup-desktop.component.mobile.scss',
  ]
})
export class LobbyPopupDesktopComponent extends LobbyPopupBaseComponent implements OnInit, OnDestroy {

  private destroy$: Subject<any> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public callStorage: CallStorage,
    public roomState: RoomStateStorage,
    private router: Router,
    private roomAuth: RoomAuthService,
    public dialogRef: MatDialogRef<any>,
    public authenticationService: AuthenticationService,
    protected unsupportedBrowserService: UnsupportedBrowserService,
    protected webSocketService: WebSocketService,
    protected usersService: UsersService,
    protected recordingService: RecordingService,
    protected roomService: RoomService
  ) {
    super(
      data,
      callStorage,
      roomState,
      roomAuth,
      dialogRef,
      authenticationService,
      unsupportedBrowserService,
      webSocketService,
      usersService,
      recordingService,
      roomService
    );
    const password = (LocationUtil.findGetParameter('password') || '');
    this.enterCallForm = new UntypedFormGroup({
      userPassword: new UntypedFormControl(sessionStorage.getItem('roomPassword') || password, [
        Validators.required,
        Validators.minLength(CODE_MAX_LENGTH),
        Validators.maxLength(TEXT_NAME_MAX_LENGTH),
      ])
    });
  }
  ngOnInit() {
    this.authenticationService.setRoomEntryPage(true);
    if (this.enterCallForm.value.userPassword !== '') {
      this.proceedToSettings();
    }
    super.ngOnInit();
    this.router.events
      .pipe(
        filter((item) => item instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        super.close();
      });
  }

  ngOnDestroy(): void {
    this.authenticationService.setRoomEntryPage(false);
    super.ngOnDestroy();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  enterCall(): void {
    super.enterCall();
  }
  cancel(buttonClicked?) {
    super.cancel();
  }

  public doInputUserPassword(): void {
    super.doInputUserPassword();
  }

  private getPasswordFromUrl(): string {
    if (window.location.search) {
      return window.location.search.replace('?password=', '');
    } else {
      return '';
    }
  }
}
