import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // For handling UTC dates
import timezone from "dayjs/plugin/timezone"; // For handling timezones
dayjs.extend(utc);
dayjs.extend(timezone);

export const isInPast = (timestamp, timeZone) => {
	const endTimeInTimeZone = dayjs.utc(timestamp).tz(timeZone);

	const currentDateTimeInTimeZone = dayjs.tz(new Date(), timeZone);
	const inPast = endTimeInTimeZone.isBefore(currentDateTimeInTimeZone);

	return inPast;
};
