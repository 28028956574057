<mat-dialog-content>
    <div class="mb-2 text-sirius-black">{{'lessons.addStudent' | translate}}</div>
    <ng-container [ngSwitch]="emailSent">

        <div *ngSwitchCase="false">

            <form class=" example-form" class="mb-4">

                <mat-form-field>
                    <mat-label>{{'profile.first-name-label'|translate}}</mat-label>
                    <input matInput type="text" [formControl]="studentName">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>E-Mail</mat-label>
                    <input matInput type="email" [formControl]="emailFormControl">
                </mat-form-field>

            </form>
            <button (click)="closeDialog()" class="btn mb-2 cancel-btn mr-2 text-text-1">Abbrechen</button>
            <button [disabled]="!emailFormControl.valid" (click)="onOkClick()"
                [ngClass]="{'inactive': !emailFormControl.valid}" class="btn">{{"lessons.inviteStudent" |
                translate}}</button>
        </div>

        <div *ngSwitchCase="true">
            <p class="text-text-1 mb-2">{{"lessons.inviteSent" | translate}}</p>
            <button (click)="closeDialog()" class="btn mb-2 mr-2 text-text-1">Schließen</button>
        </div>

    </ng-container>

</mat-dialog-content>