import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { flatMap } from 'rxjs/internal/operators';
import { StorageUtil } from '../../utils/storage.util';
import { RoomStateStorage } from '../../components/call-room/service/storage/RoomStateStorage';
import { RecordingService } from '../recording/recording.service';
import { CallStorage } from '../../components/call-room/service/storage/call-storage.service';

// https://localhost:4200/room-watchrtc?skipPrejoin=true&password=0000&userName=Jibri&recordingBot=true&apiKey=3f57b987a506a1207df7bcef9732db12

@Injectable({
  providedIn: 'root'
})
export class BotService {

  static get isBot(): boolean {
     return BotService.hasEnteredBot;
  }

  static set isBot(isBot) {
    BotService.hasEnteredBot = isBot;
  }

  static get skipPreJoin(): boolean {
    return BotService.preJoin;
  }

  static set skipPreJoin(skip: boolean) {
    BotService.preJoin = skip;
  }

  static get roomPassword(): string {
    return BotService.roomPass;
  }

  static set roomPassword(pass: string) {
    BotService.roomPass = pass;
  }

  static get userName(): string {
    return BotService.botName;
  }

  static set userName(skip: string) {
    BotService.botName = skip;
  }

  constructor(
    private route: ActivatedRoute,
    public roomState: RoomStateStorage,
    private recordingService: RecordingService,
    public callStorage: CallStorage,
  ) {
    this.route.queryParamMap.pipe(
      flatMap((params) => {
        this.paramsObject = {...params};
        if (this.paramsObject.params.recordingBot) {
          BotService.isBot = true;
        }
        if (this.paramsObject.params.skipPrejoin) {
          BotService.skipPreJoin = true;
        }
        if (this.paramsObject.params.password) {
          BotService.roomPassword = this.paramsObject.params.password;
          sessionStorage.roomPassword = this.paramsObject.params.password;
        }
        if (this.paramsObject.params.userName) {
          BotService.userName = this.paramsObject.params.userName;
        }
        return of(BotService.isBot);
      }),
      filter((isBot) => isBot)
       ).subscribe((isBot) => {
      if (isBot) {
        this.roomState.isTeacher = true;
        StorageUtil.writeMediaPrmsToSessionStorage({ isMicro: false });
      }
    });
  }
  static recordingBotEvent$ = new Subject();
  static componentComplete$ = new Subject();
  static hasEnteredBot = false;

  static preJoin = false;
  static roomPass = '';
  static botName = '';

  private paramsObject;

}
