import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ButtonType } from '../../../call-room/data/ButtonType';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LessonsService } from '../../../../services/lessons/lessons.service';
import { PopupService } from '../../../../services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { Student } from 'src/app/students/students.service';
import { ConfirmData, RequestType } from '../confirm-lesson/confirm-lesson.component';

@Component({
  selector: 'app-reschedule-calendar-student',
  templateUrl: './reschedule-calendar.component.html',
  styleUrls: [
    './reschedule-calendar.component.scss',
  ],
})
export class RescheduleCalendarComponent implements OnInit {
  text: string = '';
  title: string = '';
  cancelButtonData: {text: string, callBack?: () => {} } = {text: ''};
  okButtonData: {text: string, callBack?: (student: Student) => {} } = {text: ''};
  rescheduleCalendarDialog;
  userId: string;
  bookingID: string;
  textareaValue: string = '';

	@ViewChild('picker') picker: any;

	public disabled = false;
	public enableMeridian = false;
	public stepHour = 1;
	public stepMinute = 15;
	public stepSecond = 1;
	date = new Date();
	minDate = new Date();
	maxDate = new Date();
	selectedDate: Date;
	timeZone: string;

	public formGroup = new FormGroup({
		date: new FormControl(null, [Validators.required]),
		date2: new FormControl(null, [Validators.required])
	})
	public dateControl = new FormControl(new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(),this.date.getHours(),0));

	public options = [
		{ value: true, label: 'True' },
		{ value: false, label: 'False' }
	];
	shouldHideSelectContact;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { bookingId: string; studentName: string; textareaValue:string, timeZone: string;
    buttons: { type: string, text: string, callBack: () => {} }[], dialog: MatDialog },
			  private popupService: PopupService,
			  private lessonService: LessonsService,
			  private translateService: TranslateService) {
	  this.maxDate.setDate(this.maxDate.getDate() + 30);
  }

  ngOnInit() {
	  const confirmationTitle = this.translateService.instant('lessons.confirmation-title');
	  this.timeZone = this.data.timeZone;
      this.bookingID = this.data.bookingId;
      this.textareaValue = this.data.textareaValue;

	  this.dateControl.valueChanges.subscribe(  date => {
		  this.selectedDate = date;
		  const startTime = new Date(date.getTime());
		  const endDate = date;
		  endDate.setMinutes(endDate.getMinutes() + 30);
		  const endTime = new Date(endDate.getTime());
		  const start = startTime.toUTCString();
		  const end = endTime.toUTCString();

		  const bookingData = {
			  startTime: new Date(start).toISOString(),
			  endTime: new Date(end).toISOString(),
			  duration: '30m',
              rescheduleReason: this.textareaValue,
              status: 'rescheduled'
		  }

          let confirmData: ConfirmData = {
            date: bookingData.startTime,
            student: { name:this.data.studentName },
            duration: bookingData.duration,
            title: confirmationTitle,
            timeZone: this.timeZone,
			requestType: RequestType.UPDATE
          }

          this.rescheduleCalendarDialog.close();

          this.popupService.openConfirmLessonDialog(confirmData,bookingData,
              [
                  {
                      type: ButtonType.CANCEL,
                      text: 'lessons.cancel-at-confirmation',
                  },
                  {
                      type: ButtonType.OK,
                      text: 'lessons.confirm-reschedule',
                      callBack: () => {
                        this.lessonService.updateBooking(this.bookingID,bookingData).subscribe( (booking) => {
                            const dialog = this.data.dialog.getDialogById('confirm-lesson-dialog');
                            this.lessonService.updateBookingList$.next();
                        	dialog.close();
                            
                        }, error => {
                        if (error?.status === 400) {
                            this.popupService.openCustomMessagePopup(
                                `lessons.warning-popup`,
                                [ {
                                    type: ButtonType.CANCEL,
                                    text: 'Ok',
                                }], false, true);
                        }
                        });
                      }
                  },
              ]);

	  });
    const okButtons = this.data.buttons.filter(i => i.type === ButtonType.OK);
    if (okButtons.length > 0) {
      this.okButtonData = { text: okButtons[0].text, callBack: okButtons[0].callBack};
    }
    const cancelButtons = this.data.buttons.filter(i => i.type === ButtonType.CANCEL);
    if (cancelButtons.length > 0) {
      this.cancelButtonData = { text: cancelButtons[0].text, callBack: cancelButtons[0].callBack};
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
    const dialog = this.data.dialog.getDialogById('reschedule-calendar-dialog');
    this.picker.open();
    this.rescheduleCalendarDialog = dialog;
    },500)
  }

  datePickerOnClosed() {
      this.rescheduleCalendarDialog.close();
	}

}
