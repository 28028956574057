"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var async_1 = require("../scheduler/async");
var scan_1 = require("./scan");
var defer_1 = require("../observable/defer");
var map_1 = require("./map");
function timeInterval(scheduler) {
  if (scheduler === void 0) {
    scheduler = async_1.async;
  }
  return function (source) {
    return defer_1.defer(function () {
      return source.pipe(scan_1.scan(function (_a, value) {
        var current = _a.current;
        return {
          value: value,
          current: scheduler.now(),
          last: current
        };
      }, {
        current: scheduler.now(),
        value: undefined,
        last: undefined
      }), map_1.map(function (_a) {
        var current = _a.current,
          last = _a.last,
          value = _a.value;
        return new TimeInterval(value, current - last);
      }));
    });
  };
}
exports.timeInterval = timeInterval;
var TimeInterval = function () {
  function TimeInterval(value, interval) {
    this.value = value;
    this.interval = interval;
  }
  return TimeInterval;
}();
exports.TimeInterval = TimeInterval;
