import { Component } from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { DailyRtcService } from "../lib-rtc/services/daily-rtc.service";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { LocalStorageUtil  } from "../lib-core/utils/local-storage.util";

enum DeviceType {
	VIDEO = "videoDeviceId",
	AUDIO = "audioDeviceId"
}

@Component({
	selector: "app-daily-video-settings",
	standalone: true,
	imports: [
		ReactiveFormsModule,
		CommonModule,
		MatSelectModule,
		MatCheckboxModule,
		FormsModule
	],
	templateUrl: "./daily-video-settings.component.html",
	styleUrl: "./daily-video-settings.component.scss"
})
export class DailyVideoSettingsComponent {
	deviceForm: FormGroup;
	videoDevices: MediaDeviceInfo[] = [];
	audioDevices: MediaDeviceInfo[] = [];
	bandwidth: number = 0;
	mediaConstrains;
	audioFilterForm!: FormGroup;
	audioBitrate = 256;
	isMuted = false;



	audioFilter = [
		{ key: "noiseSuppression", name: "Noise Suppression", value: true },
		{ key: "echoCancellation", name: "Echo Cancellation", value: true},
		{ key: "autoGainControl", name: "Auto Gain Control", value: true},
	];

	constructor(
		private fb: FormBuilder,
		private dailyRtcService: DailyRtcService
	) {
		this.deviceForm = this.fb.group({
			videoInput: [""],
			audioInput: [""],
			audioBitrate: [""],
			enableSoundProcessor: [false],
			videoSettings: [""],
			muted: [false]
		});
	}

	ngOnInit() {
		this.initializeForm();
		this.setupValueChangeListeners();
		this.getDevices();

		this.deviceForm.get("audioBitrate")?.valueChanges.subscribe((value) => {
			this.audioBitrate = value;
		})
		this.deviceForm.get("videoInput")?.valueChanges.subscribe((value) => {
			this.dailyRtcService.setInputDevice(value, DeviceType.VIDEO);
			LocalStorageUtil.setItem('media_video_inpDev', value);
		});
		this.deviceForm.get("audioInput")?.valueChanges.subscribe((value) => {
			this.dailyRtcService.setInputDevice(value, DeviceType.AUDIO);
			LocalStorageUtil.setItem('media_audio_inpDev', value);
		});
		this.deviceForm
			.get("videoSettings")
			?.valueChanges.subscribe((value) => {
				const [bandwidth, framerate] = value.split("_").map(Number);
				let width: number, height: number;

				// Set resolution based on bandwidth (maintaining 4:3 ratio)
				switch (bandwidth) {
					case 400: // 360p - 4:3
						width = 480; // Calculated width for 360p at 4:3 aspect ratio
						height = 360; // Height remains 360p for 4:3 aspect ratio
						break;
					case 800: // 480p - 4:3
						width = 640; // Calculated width for 480p at 4:3 aspect ratio
						height = 480; // Height remains 480p for 4:3 aspect ratio
						break;
					case 1500: // 720p - 4:3
						width = 960; // Calculated width for 720p at 4:3 aspect ratio
						height = 720; // Height remains 720p for 4:3 aspect ratio
						break;
					case 3000: // 1080p - 4:3
						width = 1440; // Calculated width for 1080p at 4:3 aspect ratio
						height = 1080; // Height remains 1080p for 4:3 aspect ratio
						break;
					case 5000: // 1440p - 4:3
						width = 1920; // Calculated width for 1440p at 4:3 aspect ratio
						height = 1440; // Height remains 1440p for 4:3 aspect ratio
						break;
					default:
						width = 480; // Default to 360p at 4:3 aspect ratio
						height = 360;
				}
				this.dailyRtcService.setBandwidth(width, height, framerate);
			});
		this.deviceForm.get('videoInput')?.setValue(LocalStorageUtil.getItem('media_video_inpDev'));
		this.deviceForm.get('audioInput')?.setValue(LocalStorageUtil.getItem('media_audio_inpDev'));
		this.deviceForm.get('muted')?.setValue(this.dailyRtcService.$isMuted.value);
		this.isMuted = this.dailyRtcService.$isMuted.value;


		this.audioFilterForm.get('noiseSuppression')?.setValue(localStorage.getItem('media_audio_noiseSuppression') === 'true', {emitEvent: false});
		this.audioFilterForm.get('echoCancellation')?.setValue(localStorage.getItem('media_audio_echoCancellation') === 'true', {emitEvent: false});
		this.audioFilterForm.get('autoGainControl')?.setValue(localStorage.getItem('media_audio_autoGainControl')=== 'true', {emitEvent: false});			
	}

	async getDevices() {
		const devices = await navigator.mediaDevices.enumerateDevices();
		this.videoDevices = devices.filter(
			(device) => device.kind === "videoinput"
		);
		this.audioDevices = devices.filter(
			(device) => device.kind === "audioinput"
		);
	}

	initializeForm() {
		const controls: { [key: string]: FormControl } = {};
		this.audioFilter.forEach((feature) => {
			controls[feature.key] = new FormControl(feature.value);
		});
		this.audioFilterForm = this.fb.group(controls);
	}

	toggleMicrophone() {
		this.isMuted = !this.isMuted;
		this.dailyRtcService.$isMuted.next(this.isMuted);
		this.dailyRtcService.toggleMicrophone();
	}

	setupValueChangeListeners() {
		this.audioFilterForm.valueChanges.subscribe((audioConstraints) => {

		localStorage.setItem("media_audio_noiseSuppression", audioConstraints.noiseSuppression);
		localStorage.setItem("media_audio_echoCancellation", audioConstraints.echoCancellation);
		localStorage.setItem("media_audio_autoGainControl", audioConstraints.autoGainControl);
		audioConstraints.sampleRate = this.audioBitrate;
		this.dailyRtcService.joinWithNewSetting(audioConstraints);
		});

	}
}
