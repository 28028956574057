<mat-dialog-content>
    <div class="popup-text-title">{{'availability.overrides-modal-description' | translate}}</div>
    <div class="popup-text">
        <!-- <mat-icon class="lesson-calendar" svgIcon="lesson-calendar"></mat-icon> -->
        <div [innerHtml]="lessonTimeInterval"></div>
    </div>

    <form [formGroup]="addOverrideForm">
        <div *ngIf="!blockWholeDay" class="popup-text">
            <div class="form-item-time">
                <div>
                    <div formArrayName="timeSlots">
                        <div *ngFor="let timeSlot of timeSlotsArray.controls; let i = index; let isFirst=first">
                            <div [formGroupName]="i" class="form-item-time mb-2">
                                <div class="time-container">
                                    <input readonly formControlName="startTime" #el class="start-time-value"
                                        (click)="toggleTimeInput(el, addOverrideForm.controls.timeSlots.at(i).controls.slots, addOverrideForm.controls.timeSlots.at(i).controls.startTime, addOverrideForm.controls.timeSlots.at(i).controls.showStartTimeListBox, 'startTime', i)"
                                        placeholder="Start Time" autocapitalize="none" autocomplete="off"
                                        autocorrect="off" spellcheck="false" tabindex="0" type="text"
                                        aria-autocomplete="list" aria-expanded="false" aria-haspopup="true"
                                        role="combobox">

                                    <div *ngIf="addOverrideForm.controls.timeSlots.at(i).controls.showStartTimeListBox.value"
                                        class="select-list-box">

                                        <div class="blocker" tabindex="0"
                                            (click)="addOverrideForm.controls.timeSlots.at(i).controls.showStartTimeListBox.value">
                                        </div>

                                        <div class="scrollbar-thin">
                                            <ng-container *ngFor="let sl of slots">
                                                <div *ngIf="!sl?.hide" class="list-box-item"
                                                    (click)="selectSlot(sl.slot, 'startTime', addOverrideForm.controls.timeSlots.at(i).controls.showStartTimeListBox,i)"
                                                    [ngClass]="{'selected': sl.checked}">
                                                    <span>{{sl.slot}}</span>
                                                    <svg *ngIf="sl.checked" stroke="currentColor" fill="none"
                                                        stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                                        stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <polyline points="20 6 9 17 4 12"></polyline>
                                                    </svg>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>

                                <span> - </span>

                                <div class="time-container">

                                    <input readonly formControlName="endTime" #el class="end-time-value"
                                        (click)="toggleTimeInput(el, addOverrideForm.controls.timeSlots.at(i).controls.slots, addOverrideForm.controls.timeSlots.at(i).controls.endTime, addOverrideForm.controls.timeSlots.at(i).controls.showEndTimeListBox, 'endTime', i)"
                                        placeholder="End Time" autocapitalize="none" autocomplete="off"
                                        autocorrect="off" spellcheck="false" tabindex="0" type="text"
                                        aria-autocomplete="list" aria-expanded="false" aria-haspopup="true"
                                        role="combobox">

                                    <div *ngIf="addOverrideForm.controls.timeSlots.at(i).controls.showEndTimeListBox.value"
                                        class="select-list-box">

                                        <div class="blocker" tabindex="0"
                                            (click)="addOverrideForm.controls.timeSlots.at(i).controls.showEndTimeListBox.value">
                                        </div>

                                        <div class="scrollbar-thin">
                                            <ng-container *ngFor="let sl of slots">
                                                <div *ngIf="!sl?.hide" class="list-box-item"
                                                    (click)="selectSlot(sl.slot, 'endTime', addOverrideForm.controls.timeSlots.at(i).controls.showEndTimeListBox,i)"
                                                    [ngClass]="{'selected': sl.checked}">
                                                    <span>{{sl.slot}}</span>
                                                    <svg *ngIf="sl.checked" stroke="currentColor" fill="none"
                                                        stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                                                        stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <polyline points="20 6 9 17 4 12"></polyline>
                                                    </svg>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <button class="delete-timeslot-button" *ngIf="!isFirst">
                                    <mat-icon (click)="deleteTimeslot(i)">delete</mat-icon></button>
                                <button class="add-timeslot-button" *ngIf="isFirst"><mat-icon color="black"
                                        (click)="addTimeslot()">add</mat-icon></button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="checkbox-wrapper">
            <label class="switch mr-1">
                <input type="checkbox" formControlName="blockWholeDay">
                <span class="slider"></span>
            </label>
            <div>
                <p>{{ 'availability.wholeDay' | translate }}</p>
            </div>
        </div>


    </form>


    <mat-dialog-actions>
        <span class='error-bttn' [mat-dialog-close]="true" (click)="onCancelClick()">
            {{ cancelButtonData.text | translate }}
        </span>
        <button class='ok-bttn' (click)="onOkClick()">
            {{ okButtonData.text | translate }}
        </button>
    </mat-dialog-actions>

</mat-dialog-content>