import { environment } from 'src/environments/environment';

class Logger {
  constructor(readonly isProd = false) {
  }
  log(...arg): void {
    if (!this.isProd) { console.log(...arg); }
  }
  groupCollapsed(...arg) {
    if (!this.isProd) { console.groupCollapsed(...arg); }
  }
  groupEnd() {
    if (!this.isProd) { console.groupEnd(); }
  }
}

export const logger = new Logger(environment.production);
