import { Component, Input, OnInit } from "@angular/core";

const RECTANGLES_NUMBER = 18;

@Component({
	selector: "app-volume-meter",
	templateUrl: "./volume-meter.component.html",
	styleUrls: ["./volume-meter.component.scss"]
})
export class VolumeMeterComponent implements OnInit {
	@Input() volume: number;
	rectanglesNumber: number = RECTANGLES_NUMBER;
	rectangleArray = new Array(RECTANGLES_NUMBER);

	constructor() {}

	ngOnInit() {}
}
