import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DisplayStatus } from './DisplayStatusVO';
import { UserRole } from '../../../../services/role.service';
import { logger } from 'src/app/lib-core/logger';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoomStateStorage {
  // todo refactor
  public role: UserRole;
  public roomId: string;
  public state: string;
  public userId: string;
  set isLoading(value: boolean) {
    if (this.isLoadingPrivate !== value) {
      this.isLoadingPrivate = value;
      this.isLoadingPrivate$.next(value);
    }
  }
  get isLoading(): boolean {
    return this.isLoadingPrivate;
  }
  public isTeacher = false;

  private isLoadingPrivate: boolean = false;

  private isCamOnPrivate$: Subject<boolean> = new Subject<boolean>();
  private isMicroOnPrivate$: Subject<boolean> = new Subject<boolean>();
  private isHandOnPrivate$: Subject<boolean> = new Subject<boolean>();
  private isReflectionPrivate$: Subject<boolean> = new Subject<boolean>();
  private isScreenSharePrivate$: Subject<DisplayStatus> = new Subject<DisplayStatus>();
  private isAiOnPrivate$: Subject<boolean> = new Subject<boolean>();
  private isLoadingPrivate$: Subject<boolean> = new Subject<boolean>();
  private isErrorMediaSourcePrivate$: Subject<boolean> = new Subject<boolean>(); // todo move to settings model
  private isRecordingPrivate$: Subject<boolean> = new Subject<boolean>();
  private periodBeforeWarning$: Subject<number> = new Subject<number>();
  private periodBeforeRelease$: Subject<void> = new Subject<void>();
  // default values
  private isCamOnPrivate = true;
  private isMicroOnPrivate = true;
  private isReflectionPrivate = false;
  private isHandOnPrivate = false;
  private isAiOnPrivate = false;
  isScreenShare: DisplayStatus = DisplayStatus.inactive;
  private isErrorMediaSourcePrivate = false;
  isRecording = false;
  recordingDisabled = false;
  private periodWarningTimeout: NodeJS.Timeout;
  private periodReleaseTimeout: NodeJS.Timeout;
  public hasFinishedCall$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isRecording$(): Observable<boolean> {
    return this.isRecordingPrivate$.asObservable();
  }
  updateRecording(isRecording): void {
    this.isRecordingPrivate$.next(isRecording);
  }
  get isMicroOn$(): Observable<boolean> {
    return this.isMicroOnPrivate$.pipe(distinctUntilChanged());
  }
  get isMicroOn(): boolean {
    return this.isMicroOnPrivate;
  }
  set isMicroOn(value: boolean) {
    this.updateMicState(value);
  }
  // get isHandOn$(): Observable<boolean> {
  //   return this.isHandOnPrivate$.asObservable();
  // }
  // get isHandOn(): boolean {
  //   return this.isHandOnPrivate;
  // }
  // set isHandOn(value: boolean) {
  //   if (this.isLoading) {
  //     return;
  //   }
  //   if (this.isHandOnPrivate !== value) {
  //     this.isHandOnPrivate = value;
  //     this.isHandOnPrivate$.next(value);
  //   }
  // }
  get isReflectionOn$(): Observable<boolean> {
    return this.isReflectionPrivate$.asObservable();
  }
  get isCamOn$(): Observable<boolean> {
    return this.isCamOnPrivate$.pipe(distinctUntilChanged());
  }
  get isCamOn(): boolean {
    return this.isCamOnPrivate;
  }
  set isCamOn(value: boolean) {
    this.updateCamState(value);
  }
  set isCamOnImSure(value: boolean) {
    this.updateCamState(value, true);
  }
  set isMicroOnImSure(value: boolean) {
    this.updateMicState(value, true);
  }
  get displayMode$(): Observable<DisplayStatus> {
    return this.isScreenSharePrivate$.asObservable();
  }
  set displayMode(value: DisplayStatus) {
    if (this.isLoading) {
      return;
    }
    if (this.isScreenShare !== value) {
      this.isScreenShare = value;
      if (value === DisplayStatus.active) {
        this.updateCamState(true, true);
      }
      this.isScreenSharePrivate$.next(value);
    }
  }
  get isLoading$(): Observable<boolean> {
    return this.isLoadingPrivate$.asObservable();
  }
  get isAiOn$(): Observable<boolean> {
    return this.isAiOnPrivate$.asObservable();
  }
  get isAiOn(): boolean {
    return this.isAiOnPrivate;
  }
  set isAiOn(value: boolean) {
    this.isAiOnPrivate = value;
    this.isAiOnPrivate$.next(value);
  }
  get isErrorMediaSource$(): Observable<boolean> {
    return this.isErrorMediaSourcePrivate$.asObservable();
  }
  get isErrorMediaSource(): boolean {
    return this.isErrorMediaSourcePrivate;
  }
  set isErrorMediaSource(isError: boolean) {
    if (isError !== this.isErrorMediaSourcePrivate) {
      this.isErrorMediaSourcePrivate = isError;
      this.isErrorMediaSourcePrivate$.next(this.isErrorMediaSourcePrivate);
    }
  }
  get isReflection(): boolean {
    return this.isReflectionPrivate;
  }
  set isReflection(value: boolean) {
    if (this.isLoading) {
      return;
    }
    if (this.isReflectionPrivate !== value) {
      this.isReflectionPrivate = value;
      this.isReflectionPrivate$.next(value);
    }
  }

  get periodWarning$(): Observable<number> {
    return this.periodBeforeWarning$.asObservable();
  }
  get periodRelease$(): Observable<void> {
    return this.periodBeforeRelease$.asObservable();
  }

  public setPeriodWarningAndRelease(joinNow: string, joinExpires: string, joinNotification: string): void {
    if (!!this.periodWarningTimeout) {
      clearTimeout(this.periodWarningTimeout);
      this.periodWarningTimeout = null;
    }
    if (!!this.periodReleaseTimeout) {
      clearTimeout(this.periodReleaseTimeout);
      this.periodReleaseTimeout = null;
    }
    const joinNowDate: Date | null = this.createDate(joinNow);
    const joinExpiresDate: Date | null = this.createDate(joinExpires);
    const joinNotificationDate: Date | null = this.createDate(joinNotification);
    if (joinNowDate !== null && joinExpiresDate !== null && joinNotificationDate !== null) {
      let startRelease = joinExpiresDate.getTime() - joinNowDate.getTime();
      let startWarning = joinNotificationDate.getTime() - joinNowDate.getTime();
      let durationWarning = joinExpiresDate.getTime() - joinNotificationDate.getTime();

      // limit to max possible value of timeout function 
      if (startRelease  >= 2147483647) {
        startRelease = 2147483647;
      }
      if (startWarning >= 2147483647) {
        startWarning = 2147483647;
      }

      if (startRelease <= 0) {
        startRelease = 0;
      } else if (startWarning < 0) {
        startWarning = 0;
        durationWarning = startRelease;
      }
      const currDate = (new Date());
      logger.log('##setPeriodWarningAndRelease()'
        + '\nCurrent="' + currDate.toLocaleString() + '"'
        + '\nWarning="' + (new Date(currDate.getTime() + startWarning)).toLocaleString() + '"'
        + '\nRelease="' + (new Date(currDate.getTime() + startRelease)).toLocaleString() + '"'
        + '\nstartWarning   =', startWarning
        + '\ndurationWarning=', durationWarning
        + '\nstartRelease   =', startRelease
      ); // TODO del;
      if (startWarning >= 0 && durationWarning > 0) {
        this.periodWarningTimeout = setTimeout(() => {
          logger.log('##setPeriodWarningAndRelease() periodBeforeWarning$.next(', durationWarning, ');'); // TODO del;
          this.periodWarningTimeout = null;
          this.periodBeforeWarning$.next(durationWarning);
        }, startWarning);
      }
      this.periodReleaseTimeout = setTimeout(() => {
        logger.log('##setPeriodWarningAndRelease() periodBeforeRelease$.next();'); // TODO del;
        this.periodReleaseTimeout = null;
        this.periodBeforeRelease$.next();
      }, startRelease);
    }
  }
  private createDate(value: string): Date | null {
    return (!!value && value.length === 24 ? new Date(value) : null);
  }
  private updateCamState(value: boolean, prowerful?: boolean) {
    const update = (data: boolean) => {
      if (this.isCamOnPrivate !== data) {
        this.isCamOnPrivate = data;
        this.isCamOnPrivate$.next(data);
      }
    };
    if (prowerful) {
      update(value);
    }
    if (this.isLoading) {
      return;
    }
    if (this.isErrorMediaSource) {
      update(this.isScreenShare === DisplayStatus.active);
    } else if (this.isScreenShare !== DisplayStatus.loadingToActive && this.isScreenShare !== DisplayStatus.active) {
      update(value);
    }
  }
  private updateMicState(value: boolean, prowerful?: boolean) {
    if (this.isLoading && !prowerful) {
      return;
    }
    if (this.isMicroOnPrivate !== value) {
      this.isMicroOnPrivate = value;
      this.isMicroOnPrivate$.next(value);
    }
  }
}
