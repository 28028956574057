<div class="header">
  <div class="cross-icon" (click)="close()"></div>
</div>
<div class="content">
  <span [innerHTML]="'expiration-free-plan.warning' | translate:{value: data.countDaysTariffFree}"></span>
</div>
<div class="footer">
  <button class="default-ui-button update-btn" (click)="upgrade(data.result)">
    <div class="update-icon"></div>
    <div class="upper-case">{{ 'expiration-free-plan.upgrade' | translate }}</div>
  </button>
  <button class="default-ui-button cancel-btn" (click)="close()">
    {{ 'buttons.cancel' | translate }}
  </button>
</div>