import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {LoadingComponent} from './loading.component';

@Directive({
  selector: '[appLoading]'
})
export class LoadingDirective implements OnInit {

  loadingFactory: ComponentFactory<LoadingComponent>;
  loadingComponent: ComponentRef<LoadingComponent>;

  @Input()
  set appLoading(loading: boolean) {
      if (loading) {
        this.loadingComponent = this.vcRef.createComponent(this.loadingFactory);
      } else {
        if (this.loadingComponent) {
          this.loadingComponent.destroy();
        }
      }
  }
  constructor(private templateRef: TemplateRef<any>, private vcRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver)  {
    this.loadingFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingComponent);
  }

  ngOnInit(): void {
    this.vcRef.createEmbeddedView(this.templateRef);
  }

}
