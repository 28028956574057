"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ReplaySubject_1 = require("../ReplaySubject");
var multicast_1 = require("./multicast");
function publishReplay(bufferSize, windowTime, selectorOrScheduler, scheduler) {
  if (selectorOrScheduler && typeof selectorOrScheduler !== 'function') {
    scheduler = selectorOrScheduler;
  }
  var selector = typeof selectorOrScheduler === 'function' ? selectorOrScheduler : undefined;
  var subject = new ReplaySubject_1.ReplaySubject(bufferSize, windowTime, scheduler);
  return function (source) {
    return multicast_1.multicast(function () {
      return subject;
    }, selector)(source);
  };
}
exports.publishReplay = publishReplay;
