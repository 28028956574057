const SESSION_STORAGE_MEDIA_KEY = 'media_params';


export const toBrowserSupported = (unparsedData: MediaStreamConstraints) => {
  const parsedData = { audio: {}, video: {} } ;
  const videoParam = toBrowserSupportedTrack(unparsedData.video as MediaTrackConstraints);
  if (navigator.userAgent.includes('Firefox') && videoParam.deviceId && videoParam.deviceId !== 'any') { // todo refactor
    if (!(videoParam.deviceId as object).hasOwnProperty('exact')) {
      videoParam.deviceId = { exact: videoParam.deviceId } as ConstrainDOMStringParameters;
    }
  }
  parsedData.video = videoParam;
  parsedData.audio = toBrowserSupportedTrack(unparsedData.audio as MediaTrackConstraints);
  return parsedData;
};
export const toBrowserSupportedTrack = (data: MediaTrackConstraints): MediaTrackConstraints => {
  const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
  const returned = {};
  Object.keys(data)
    .filter(testKey => !!supportedConstraints[testKey])
    .forEach(approvedKey => returned[approvedKey] = data[approvedKey]);
  return returned;
};

export const saveMediaConstraints = (params?: MediaStreamConstraints) => {
  if (params) {
    localStorage.setItem(SESSION_STORAGE_MEDIA_KEY, JSON.stringify(params).toString());
  } else {
    localStorage.removeItem(SESSION_STORAGE_MEDIA_KEY);
  }
};
export const loadMediaConstraints = (): MediaStreamConstraints => {
  return JSON.parse(localStorage.getItem(SESSION_STORAGE_MEDIA_KEY)) as MediaStreamConstraints;
};

export const ensureGlobalObject: (path: string) => any = (path) => {
  const parts = path.split('.');
  let obj: any = window;
  for (const field of parts) {
    if (!obj[field]) {
      obj[field] = {};
    }
    obj = obj[field];
  }
  return obj;
};
