import {
	Component,
	EventEmitter,
	Input,
	Output,
	forwardRef
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

interface Assessment {
	emoji: string;
	title: string;
	value: any;
}

@Component({
	selector: "app-rating",
	templateUrl: "./rating.component.html",
	styleUrls: ["./rating.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RatingComponent),
			multi: true
		}
	]
})
export class RatingComponent implements ControlValueAccessor {
	@Input() assessments: Assessment[] = [];
	@Input() value: any;
	@Input() showLabels = false;
	onChange: any = () => {};
	onTouch: any = () => {};

	constructor() {}

	onAssessmentChange(value) {
		this.value = value;
		this.onChange(value);
		this.onTouch();
	}

	writeValue(value: any): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	isSelected(value: any): boolean {
		return this.value === value;
	}
}
