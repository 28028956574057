<form class="flex flex-wrap" [formGroup]="deviceForm">
	<div class="mr-2">
		<mat-form-field>
			<mat-label for="videoInput">Video Input</mat-label>
			<mat-select id="videoInput" formControlName="videoInput">
				<mat-option *ngFor="let device of videoDevices" [value]="device.deviceId">{{ device.label || 'Video Device' }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div class="mr-2">
		<mat-form-field>
			<mat-label for="audioInput">Audio Input</mat-label>
			<mat-select id="audioInput" formControlName="audioInput">
				<mat-option *ngFor="let device of audioDevices" [value]="device.deviceId">{{ device.label || 'Audio Device' }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>


	<div class="mr-2">
		<mat-form-field>
			<mat-label for="videoSettings">Set Video Quality</mat-label>
			<mat-select id="videoSettings" formControlName="videoSettings">
				<!-- 360p options (4:3 ratio) -->
				<mat-option value="400_5">360p - 400 Kbps, 5 fps</mat-option>
				<mat-option value="400_15">360p - 400 Kbps, 15 fps</mat-option>
				<mat-option value="400_30">360p - 400 Kbps, 30 fps</mat-option>
				<mat-option value="400_60">360p - 400 Kbps, 60 fps</mat-option>

				<!-- 480p options (4:3 ratio) -->
				<mat-option value="800_5">480p - 800 Kbps, 5 fps</mat-option>
				<mat-option value="800_15">480p - 800 Kbps, 15 fps</mat-option>
				<mat-option value="800_30">480p - 800 Kbps, 30 fps</mat-option>
				<mat-option value="800_60">480p - 800 Kbps, 60 fps</mat-option>

				<!-- 720p options (4:3 ratio) -->
				<mat-option value="1500_5">720p - 1.5 Mbps, 5 fps</mat-option>
				<mat-option value="1500_15">720p - 1.5 Mbps, 15 fps</mat-option>
				<mat-option value="1500_30">720p - 1.5 Mbps, 30 fps</mat-option>
				<mat-option value="1500_60">720p - 1.5 Mbps, 60 fps</mat-option>

				<!-- 1080p options (4:3 ratio) -->
				<mat-option value="3000_5">1080p - 3 Mbps, 5 fps</mat-option>
				<mat-option value="3000_15">1080p - 3 Mbps, 15 fps</mat-option>
				<mat-option value="3000_30">1080p - 3 Mbps, 30 fps</mat-option>
				<mat-option value="3000_60">1080p - 3 Mbps, 60 fps</mat-option>

				<!-- 1440p options (4:3 ratio) -->
				<mat-option value="5000_5">1440p - 5 Mbps, 5 fps</mat-option>
				<mat-option value="5000_15">1440p - 5 Mbps, 15 fps</mat-option>
				<mat-option value="5000_30">1440p - 5 Mbps, 30 fps</mat-option>
				<mat-option value="5000_60">1440p - 5 Mbps, 60 fps</mat-option>
			</mat-select>
		</mat-form-field>
	</div>


	<div class="mr-2">
		<mat-form-field>
			<mat-label for="audioBitrate">Sample Rate</mat-label>
			<mat-select id="audioBitrate" formControlName="audioBitrate">
				<mat-option value="128">128</mat-option>
				<mat-option value="256">256</mat-option>
				<mat-option value="512">512</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	<div class="mr-2">
		<mat-checkbox (change)="toggleMicrophone()" formControlName="muted">Mute Audio</mat-checkbox>
	</div>
	
	<form [formGroup]="audioFilterForm">
		<div *ngFor="let filter of audioFilter">
			<mat-checkbox [formControlName]="filter.key">
				{{ filter.name }}
				</mat-checkbox>
				</div>
				</form>
</form>

