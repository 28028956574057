"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var AsyncSubject_1 = require("../AsyncSubject");
var multicast_1 = require("./multicast");
function publishLast() {
  return function (source) {
    return multicast_1.multicast(new AsyncSubject_1.AsyncSubject())(source);
  };
}
exports.publishLast = publishLast;
