<h1 mat-dialog-title>{{ "material.confirm-file-upload" | translate }}</h1>
<mat-list>
	<mat-dialog-content>
		<div mat-subheader>Files</div>
		@for (file of data.files; track file) {
			<mat-list-item>
				<mat-icon matListItemIcon>description</mat-icon>
				<div matListItemTitle>{{ file.name }}</div>
				<!-- <div matListItemLine>{{file.updated | date}}</div> -->
			</mat-list-item>
		}
		<mat-dialog-actions>
			@if (!isUploading && !isUploadComplete) {
				<button class="error-bttn" (click)="onClose()">
					{{ "buttons.cancel" | translate }}
				</button>
				<button class="ok-bttn" (click)="onOkButton()">
					{{ "buttons.ok" | translate }}
				</button>
			}
			@if (isUploading) {
				<mat-progress-bar mode="indeterminate"></mat-progress-bar>
			}
			@if (isUploadComplete) {
				<p>{{ "material.upload-success" | translate }}</p>
			}
		</mat-dialog-actions>
	</mat-dialog-content>
</mat-list>
