import { Component, Inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import {
	INVITE_EXTERNAL_STUDENT,
	MATCHING,
	PACKAGE
} from "../../../../constants/connection-constants";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";

import {
	FormControl,
	FormGroupDirective,
	NgForm,
	Validators,
	FormsModule,
	ReactiveFormsModule
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";

import { MatDialogModule } from "@angular/material/dialog";
import { start } from "tone";

export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: FormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const isSubmitted = form && form.submitted;
		return !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched || isSubmitted)
		);
	}
}

export interface DialogData {
	name: string;
}

@Component({
	selector: "app-invite-external-student",
	templateUrl: "./invite-external-student.component.html",
	styleUrls: ["./invite-external-student.component.scss"],
	standalone: true,
	imports: [
		MatDialogModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		CommonModule,
		TranslateModule
	]
})
export class InviteExternalStudentComponent {
	constructor(
		private http: HttpClient,
		private authenticationService: AuthenticationService,
		public dialog: MatDialogRef<InviteExternalStudentComponent> // @Inject(MAT_DIALOG_DATA) public data: DialogData
	) {}

	emailSent: boolean = false;
	emailFormControl = new FormControl("", [
		Validators.required,
		this.emailValidator
	]);
	studentName = new FormControl("", [Validators.required]);
	matcher = new MyErrorStateMatcher();

	private get authHttpOptions() {
		return {
			withCredentials: true,
			headers: new HttpHeaders({
				"Content-Type": "application/json; charset=utf-8",
				Authorization: this.authenticationService.accessToken
			})
		};
	}

	emailValidator(control) {
		if (control.value) {
			const matches = control.value.match(
				/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
			);
			return matches ? null : { invalidEmail: true };
		} else {
			return null;
		}
	}

	onOkClick() {
		this.inviteExternalStudent()
			.pipe(
				mergeMap(() =>
					this.matchStudent(
						this.authenticationService.profileInfo().email,
						this.emailFormControl.value
					)
				),
				mergeMap(() =>
					this.createLessonPackage(
						this.authenticationService.profileInfo().email,
						this.emailFormControl.value
					)
				)
			)
			.subscribe(() => {
				this.emailSent = true;
			});
	}

	closeDialog() {
		this.dialog.close();
	}

	inviteExternalStudent(): Observable<any> {
		return this.http.post(
			INVITE_EXTERNAL_STUDENT,
			{
				firstName: this.studentName.value,
				email: this.emailFormControl.value,
				subrole: 7,
				language: "cf75bf5b21b7f6103679a1c7fd2a975a"
			},
			this.authHttpOptions
		);
	}

	matchStudent(teacherEmail, studentEmail): Observable<any> {
		return this.http.post(
			MATCHING,
			{
				teacherEmail: teacherEmail,
				studentEmail: studentEmail
			},
			this.authHttpOptions
		);
	}

	createLessonPackage(teacherEmail, studentEmail): Observable<any> {
		return this.http.post(
			PACKAGE,
			{
				teacherEmail: teacherEmail,
				studentEmail: studentEmail,
				startDate: new Date(),
				renewalDate: new Date(
					new Date().setFullYear(new Date().getFullYear() + 1)
				),
				duration: 60,
				lessonsAmount: 4,
				lessonsSubscription: 3,
				takenLessons: 1
			},
			this.authHttpOptions
		);
	}
}
