import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserVO } from '../data/UserVO';

@Injectable({
  providedIn : 'root'
})
export class PlayerStateService {

  playerState = new BehaviorSubject('');
  youtubeActive$ = new BehaviorSubject(false);
  playerUser$ = new BehaviorSubject<UserVO>(null);
  playerEvent$ = new BehaviorSubject<any>([]);
  volume$ = new BehaviorSubject<any>({});

  get playerEvent() {
    return this.playerEvent$.getValue();
  }

  set playerEvent(value) {
    let players = this.playerEvent$.value;
    const hasPlayer = players.find((player) => player.g?.id === value.g.id);
    if (!hasPlayer) {
      players.push(value);
    }
    players = players.filter(player => !!player.g);
    this.playerEvent$.next(players);
  }

  get playerUser(): UserVO {
    return this.playerUser$.getValue();
  }

  set playerUser(value: UserVO) {
    this.playerUser$.next(value);
  }

  get youtubeActive(): boolean {
    return this.youtubeActive$.getValue();
  }

  set youtubeActive(value: boolean) {
    this.youtubeActive$.next(value);
  }

  get isHostPlayer(): boolean {
    return !!localStorage.getItem('hostPlayer');
  }

  get volume(): {volume: number, id: number} {
    return this.volume$.getValue();
  }

  set volume(volume: {volume: number, id: number}) {
    this.volume$.next(volume);
  }
}
