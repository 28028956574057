"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var Subscription_1 = require("../Subscription");
var innerSubscribe_1 = require("../innerSubscribe");
function bufferWhen(closingSelector) {
  return function (source) {
    return source.lift(new BufferWhenOperator(closingSelector));
  };
}
exports.bufferWhen = bufferWhen;
var BufferWhenOperator = function () {
  function BufferWhenOperator(closingSelector) {
    this.closingSelector = closingSelector;
  }
  BufferWhenOperator.prototype.call = function (subscriber, source) {
    return source.subscribe(new BufferWhenSubscriber(subscriber, this.closingSelector));
  };
  return BufferWhenOperator;
}();
var BufferWhenSubscriber = function (_super) {
  __extends(BufferWhenSubscriber, _super);
  function BufferWhenSubscriber(destination, closingSelector) {
    var _this = _super.call(this, destination) || this;
    _this.closingSelector = closingSelector;
    _this.subscribing = false;
    _this.openBuffer();
    return _this;
  }
  BufferWhenSubscriber.prototype._next = function (value) {
    this.buffer.push(value);
  };
  BufferWhenSubscriber.prototype._complete = function () {
    var buffer = this.buffer;
    if (buffer) {
      this.destination.next(buffer);
    }
    _super.prototype._complete.call(this);
  };
  BufferWhenSubscriber.prototype._unsubscribe = function () {
    this.buffer = undefined;
    this.subscribing = false;
  };
  BufferWhenSubscriber.prototype.notifyNext = function () {
    this.openBuffer();
  };
  BufferWhenSubscriber.prototype.notifyComplete = function () {
    if (this.subscribing) {
      this.complete();
    } else {
      this.openBuffer();
    }
  };
  BufferWhenSubscriber.prototype.openBuffer = function () {
    var closingSubscription = this.closingSubscription;
    if (closingSubscription) {
      this.remove(closingSubscription);
      closingSubscription.unsubscribe();
    }
    var buffer = this.buffer;
    if (this.buffer) {
      this.destination.next(buffer);
    }
    this.buffer = [];
    var closingNotifier;
    try {
      var closingSelector = this.closingSelector;
      closingNotifier = closingSelector();
    } catch (err) {
      return this.error(err);
    }
    closingSubscription = new Subscription_1.Subscription();
    this.closingSubscription = closingSubscription;
    this.add(closingSubscription);
    this.subscribing = true;
    closingSubscription.add(innerSubscribe_1.innerSubscribe(closingNotifier, new innerSubscribe_1.SimpleInnerSubscriber(this)));
    this.subscribing = false;
  };
  return BufferWhenSubscriber;
}(innerSubscribe_1.SimpleOuterSubscriber);
