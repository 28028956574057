
// Audio
export const MEDIA_AUDIO_INPUT_DEVICE = 'media_audio_inpDev';
export const MEDIA_AUDIO_OUTPUT_DEVICE = 'media_audio_outDev';
export const MEDIA_AUDIO_SAMPLE_RATE = 'media_audio_sampleRate';
export const MEDIA_AUDIO_ECHO_CANCELLATION = 'media_audio_echoCancellation';
export const MEDIA_AUDIO_NOISE_SUPPRESSION = 'media_audio_noiseSuppression';
export const MEDIA_AUDIO_GAIN_CONTROL = 'media_audio_autoGainControl';
export const MEDIA_AUDIO_CHANNEL_COUNT = 'media_audio_channelCount';
// Video
export const MEDIA_VIDEO_INPUT_DEVICE = 'media_video_inpDev';
export const MEDIA_VIDEO_WIDTH = 'media_video_width';
export const MEDIA_VIDEO_HEIGHT = 'media_video_height';
export const MEDIA_VIDEO_FRAME_RATE = 'media_video_frameRate';
export const MEDIA_VIDEO_IS_REFLECTION = 'media_video_isReflection';
export const MEDIA_VIDEO_RESOLUTION = 'media_video_resolution';
export const MEDIA_VIDEO_SECOND_INPUT_DEVICE = 'media_video_second_inpDev';


export const EMAIL_VALIDATION_REG = '^[a-zA-Z0-9.!#$%&’*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)+$';


const PROTOCOL = 'https';
const WS_PROTOCOL = 'wss';

const SERVER_HOST = window.location.hostname.replace('www.', ''); // Workaround requested by backend

const SERVER_PORT = window.location.hostname === 'localhost' || location.hostname.indexOf('192.') === 0
  ? 3000
  : window.location.port;

export const RTC_WS_URL = PROTOCOL + '://' + SERVER_HOST + ':' + SERVER_PORT;
export const WS_URL = WS_PROTOCOL + '://' + SERVER_HOST + ':' + SERVER_PORT;
export const API_URL = PROTOCOL + '://' + SERVER_HOST + ':' + SERVER_PORT + '/api';
