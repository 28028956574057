"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var merge_1 = require("../observable/merge");
function merge() {
  var observables = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    observables[_i] = arguments[_i];
  }
  return function (source) {
    return source.lift.call(merge_1.merge.apply(void 0, [source].concat(observables)));
  };
}
exports.merge = merge;
