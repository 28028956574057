import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class InitialAuthorizationService {

  public hasInitialAuthorization$: Subject<boolean> = new Subject();

  private innHasInitialAuthorization: boolean | undefined = undefined;

  constructor(
  ) {
  }

  public getHasInitialAuthorization(): boolean {
    return this.innHasInitialAuthorization;
  }

  public setHasInitialAuthorization(value: boolean): void {
    if (this.innHasInitialAuthorization !== value) {
      this.innHasInitialAuthorization = value;
      this.hasInitialAuthorization$.next(value);
    }
  }

}
