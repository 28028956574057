<mat-dialog-content>
  <div class="content-outer">
    <div class="spinner">
		<mat-spinner diameter="50" color="accent"></mat-spinner>
	</div>
    <div class="caption">
      {{ 'popUp.let-in-waiting' | translate }}
    </div>
    <button class="cancel" (click)="cancel()">
      {{ 'buttons.cancel' | translate }}
    </button>
  </div>
</mat-dialog-content>
