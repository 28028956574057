"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var Subject_1 = require("../Subject");
var innerSubscribe_1 = require("../innerSubscribe");
function window(windowBoundaries) {
  return function windowOperatorFunction(source) {
    return source.lift(new WindowOperator(windowBoundaries));
  };
}
exports.window = window;
var WindowOperator = function () {
  function WindowOperator(windowBoundaries) {
    this.windowBoundaries = windowBoundaries;
  }
  WindowOperator.prototype.call = function (subscriber, source) {
    var windowSubscriber = new WindowSubscriber(subscriber);
    var sourceSubscription = source.subscribe(windowSubscriber);
    if (!sourceSubscription.closed) {
      windowSubscriber.add(innerSubscribe_1.innerSubscribe(this.windowBoundaries, new innerSubscribe_1.SimpleInnerSubscriber(windowSubscriber)));
    }
    return sourceSubscription;
  };
  return WindowOperator;
}();
var WindowSubscriber = function (_super) {
  __extends(WindowSubscriber, _super);
  function WindowSubscriber(destination) {
    var _this = _super.call(this, destination) || this;
    _this.window = new Subject_1.Subject();
    destination.next(_this.window);
    return _this;
  }
  WindowSubscriber.prototype.notifyNext = function () {
    this.openWindow();
  };
  WindowSubscriber.prototype.notifyError = function (error) {
    this._error(error);
  };
  WindowSubscriber.prototype.notifyComplete = function () {
    this._complete();
  };
  WindowSubscriber.prototype._next = function (value) {
    this.window.next(value);
  };
  WindowSubscriber.prototype._error = function (err) {
    this.window.error(err);
    this.destination.error(err);
  };
  WindowSubscriber.prototype._complete = function () {
    this.window.complete();
    this.destination.complete();
  };
  WindowSubscriber.prototype._unsubscribe = function () {
    this.window = null;
  };
  WindowSubscriber.prototype.openWindow = function () {
    var prevWindow = this.window;
    if (prevWindow) {
      prevWindow.complete();
    }
    var destination = this.destination;
    var newWindow = this.window = new Subject_1.Subject();
    destination.next(newWindow);
  };
  return WindowSubscriber;
}(innerSubscribe_1.SimpleOuterSubscriber);
