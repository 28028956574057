import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { logger } from "src/app/lib-core/logger";
import { POST_MIXPANEL_EVENT } from "src/app/constants/connection-constants";
import { environment } from "../../../environments/environment";
import { MASTER } from "src/app/constants/environment-constants";

enum MIXPANEL_EVENTS {
	TRIAL_FB_GO_TO_CHECKOUT = "Trial Feedback Student: Go Straight To Checkout",
	TRIAL_FB_DECLINE = "Trial Feedback Student: Decline",
	TRIAL_FB_GIVE_FEEDBACK = "Trial Feedback Student: Give Feedback",
	TRIAL_FB_CHANGE_MIND = "Trial Feedback Student: Change Mind, go To Checkout",
	TRIAL_LESSON_FINISH = "Trial Lesson Student: Finish",
	TRIAL_CHECKOUT_LITE = "Trial Checkout Lite",
	TRIAL_CHECKOUT_STANDARD = "Trial Checkout Standard",
	TRIAL_CHECKOUT_FLEX = "Trial Checkout Flex"
}

interface MixpanelData {
	eventName: MIXPANEL_EVENTS;
	userId: string;
	data: {
		[key: string]: any;
	};
}

@Injectable({
	providedIn: "root"
})
export class MixpanelService {
	constructor(private http: HttpClient) {}

	trackEvent(data: MixpanelData): void {

		// if (environment.ENV === MASTER) {
			this.http.post(POST_MIXPANEL_EVENT, data).subscribe(
				(res) => {
					logger.log("Mixpanel event response", res);
				},
				(err) => {
					logger.log("Mixpanel event error", err);
				}
			);
		// }
	}

	sendTrialLessonFeedback(feedback: string, userId: string): void {
		const data: MixpanelData = {
			eventName: MIXPANEL_EVENTS.TRIAL_FB_GIVE_FEEDBACK,
			userId: userId,
			data: {
				feedback
			}
		};

		this.trackEvent(data);
	}

	declineTrialOffer(userId: string): void {
		console.log("Decline trial offer");

		const data: MixpanelData = {
			eventName: MIXPANEL_EVENTS.TRIAL_FB_DECLINE,
			userId: userId,
			data: {}
		};

		this.trackEvent(data);
	}

	changeMindGoToCheckout(userId: string): void {
		const data: MixpanelData = {
			eventName: MIXPANEL_EVENTS.TRIAL_FB_CHANGE_MIND,
			userId: userId,
			data: {}
		};

		this.trackEvent(data);
	}

	goStraightToCheckout(userId: string): void {
		const data: MixpanelData = {
			eventName: MIXPANEL_EVENTS.TRIAL_FB_GO_TO_CHECKOUT,
			userId: userId,
			data: {}
		};

		this.trackEvent(data);
	}

	finishTrialStudent(userId: string): void {
		const data: MixpanelData = {
			eventName: MIXPANEL_EVENTS.TRIAL_LESSON_FINISH,
			userId: userId,
			data: {}
		};

		this.trackEvent(data);
	}
}
