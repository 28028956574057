import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';

@Component({
  templateUrl: './room-full.component.html',
  styleUrls: ['./room-full.component.scss']
})
export class RoomFullComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<RoomFullComponent>,
    private authenticationService: AuthenticationService,
  ) { }

  public close(): void {
    this.dialogRef.close();
    this.authenticationService.checkLoggedIn$().toPromise().then((loggedIn) => {
      this.router.navigate(!!loggedIn ? ['/platform/start'] : ['/']);
    });
  }
}
