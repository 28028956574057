import {Injectable} from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { USER_KEY } from '../constants/localstorage-constants';
import { UserVOInfo, UserVOInfoUtil } from './data/UserInfoVO';
import { UserVO } from './data/UserVO';
import { PlayerStateService } from './youtube/player-state.service';


@Injectable({
  providedIn : 'root'
})

export class UsersService {
  public changeUserList$: Observable<void>;
  public userVOInfoList: UserVOInfo[] = [];
  public prevUserVOInfoList: UserVOInfo[] = [];
  public onStreamAccepted: Subject<void> = new Subject();

  private changeUserList: Subject<void> = new Subject();
  private innUserList: UserVO[] = [];
  private innSelfUser: UserVO = null;
  private innUserOnPanel: UserVO;

  constructor(private playerStateService: PlayerStateService) {
    this.changeUserList$ = this.changeUserList.asObservable();
  }

  get userList(): UserVO[] { return this.innUserList; }
  get selfUser(): UserVO { return this.innSelfUser; }
  get userOnPanel(): UserVO { return this.innUserOnPanel; }

  public getConnectedUsers(): UserVO[] {
    return this.innUserList.filter((userVO: UserVO) => userVO?.connected && !userVO.isRecordingBot && userVO.name !== 'Jibri');
  }

  public getConnectedUsersWithRecordingBot(): UserVO[] {
    return this.innUserList.filter((userVO: UserVO) => userVO?.connected);
}
  
  public findUserById(id: string): UserVO {
    return this.innUserList.find((item) => item.id === id);
  }

  public findUserByRtcId(id: string): UserVO {
    return this.innUserList.find((item) => item.rtcId === id);
  }

  public setUserOnPanel(userVO: UserVO): void {
    if (!!userVO && this.innUserList.includes(userVO)) {
      this.innUserOnPanel = userVO;
    }
  }

  public isUserOnPanel(user: UserVO): boolean {
    return this.innUserOnPanel === user;
  }

  public updateUserVOInfoList(userList: UserVO[]): void {
    if (Array.isArray(userList)) {
      this.prevUserVOInfoList = this.userVOInfoList;
      this.userVOInfoList = UserVOInfoUtil.createList(userList);
    }
  }

  public updateUserList(newUsers: UserVO[]): UserVO[] {
    const result: UserVO[] = [];
    if (!!newUsers && newUsers.length > 0) {
      let selfUser: UserVO = null;
      for (const newUser of newUsers) {
        let userItem = this.innUserList.find((item) => item.id === newUser.id);
        if (!!userItem) {
          userItem.update(newUser);
        } else {
          userItem = newUser;
        }
        if (userItem.self) {
          selfUser = userItem;
        } else {
          result.push(userItem);
        }
      }
      this.innUserList = result.concat([selfUser]);
      
      const hasYoutubeUser = this.innUserList.find(user => user?.hasYoutube);

      if (this.playerStateService.playerUser && !hasYoutubeUser) {
        this.innUserList = this.innUserList.concat([this.playerStateService.playerUser]);
        this.innUserList.forEach(user => user.youTubeVideoId = this.playerStateService.playerUser.id);
      }

      if (this.innSelfUser !== selfUser) {
        this.innSelfUser = selfUser;
        this.innSelfUser.name = sessionStorage.getItem(USER_KEY);
      }

      this.innUserOnPanel = (!!this.innUserOnPanel ? this.findUserById(this.innUserOnPanel.id) : null);
      
      if (!this.innUserOnPanel && this.innUserList.length > 0) {
        this.innUserOnPanel = this.innUserList[0];
      }

    }
    return this.innUserList;
  }

  public changeUsers = () => {
    this.changeUserList.next(null);
  }

  public updateUserOnPanelByGone(): void {
    if (!!this.innUserOnPanel) {
      const connectedUsers: UserVO[] = this.getConnectedUsers();
      this.innUserOnPanel = connectedUsers.find((item) => item.id === this.innUserOnPanel.id);
      if (!this.innUserOnPanel && connectedUsers.length > 0) {
        this.innUserOnPanel = connectedUsers[0];
      }
    }
  }
}
