import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class LessonsCommunicationService {
	private dataUpdatedSource = new Subject<void>();
	private studentDataSubject = new BehaviorSubject<any>(null);

	dataUpdated$ = this.dataUpdatedSource.asObservable();
	studentData$ = this.studentDataSubject.asObservable();

	notifyDataUpdated(): void {
		this.dataUpdatedSource.next();
	}

	updateStudentData(student: any): void {
		this.studentDataSubject.next(student);
	}
	getCurrentStudentData(): any {
		return this.studentDataSubject.getValue();
	}
}
