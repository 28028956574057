export enum StudentSubscriptionStatus {
    INCOMPLETE = 'incomplete',
    INCOMPLETE_EXPIRED = 'incomplete_expired',
    TRIALING = 'trialing',
    ACTIVE = 'active',
    PAST_DUE = 'past_due',
    CANCELED = 'canceled',
    UNPAID = 'unpaid',
    PAUSED = 'paused',
}

export type StudentPackageLesson = {
    id: string;
    teacherId: string;
    studentId: string;
    duration: string;
    lessonsAmount: string;
    lessonsSubscription: string;
    modified: string; // ISO date string
    created: string; // ISO date string
    startDate: string; // ISO date string
    renewalDate: string | null; // ISO date string or null
    automaticTakenLessons: number | null;
    automaticAvailableLessons: number | null;
    manualTakenLessons: number | null;
    manualAvailableLessons: number | null;
    status: StudentSubscriptionStatus // Assuming status has specific string values
    uuid: string | null;
    paymentSubscription: string | null;
    teacherName: string;
};