<!-- <div class="min-w-[100vw] min-h-[100vh] z-50 flex flex-col items-center bg-sirius-bg flex-grow"> -->
<div class="popup-outer-container">
	<div class="inner-container">
		<div class="flex justify-between w-full header">
			<div class="bg-transparent logo-outer">
				<a
					><img
						alt="Sirius"
						class="logo"
						src="assets/icons/sirius/Sirius.png"
				/></a>
			</div>
			<app-language-selector />
		</div>

		<div class="max-w-[100vw] p-4 bg-white rounded-md w-96 text-black box">
			<div class="mb-1 text-xl font-bold">
				{{ "music-modal.title-" + subrole | translate }}
			</div>
			<div class="mb-4 text-xs max-lg:text-sm">
				{{ "music-modal.subtitle" | translate }}
			</div>
			<form [formGroup]="form">
				<div class="mb-4">
					<label class="mb-1">{{
						"profile.instruments-label" | translate
					}}</label>
					<mat-select
						formControlName="instruments"
						class="s-mat-select app-select"
						panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
						multiple
						[placeholder]="
							'teacher-onboarding.instrument.placeholder'
								| translate
						"
					>
						<mat-option
							*ngFor="let i of instrumentsOptions"
							[value]="i.value"
						>
							{{ i.title | translate }}
						</mat-option>
					</mat-select>
					<div
						class="text-xs text-[#ff1818] mt-1"
						*ngIf="
							form.get('instruments').dirty &&
							form.get('instruments').invalid
						"
					>
						{{ "profile.instruments-error" | translate }}
					</div>
				</div>
				<div class="mb-4">
					<label class="mb-1">{{
						"profile.musicGenres-label" | translate
					}}</label>
					<mat-select
						formControlName="musicGenres"
						class="s-mat-select app-select"
						panelClass="s-mat-checkbox app-select-panel s-mat-select-panel border"
						multiple
						[placeholder]="
							'teacher-onboarding.genre.placeholder' | translate
						"
					>
						<mat-option
							*ngFor="let i of genresOptions"
							[value]="i.value"
						>
							{{ i.title | translate }}
						</mat-option>
					</mat-select>
					<div
						class="text-xs text-[#ff1818] mt-1"
						*ngIf="
							form.get('musicGenres').dirty &&
							form.get('musicGenres').invalid
						"
					>
						{{ "profile.musicGenres-error" | translate }}
					</div>
				</div>

				<div class="flex justify-end">
					<button class="default-ui-button" (click)="onClickSkip()">
						{{ "buttons.skip" | translate }}
					</button>
					<button
						[disabled]="!form.valid"
						class="ml-2 default-ui-button blue-ui-button"
						(click)="onClickSave()"
						type="submit"
					>
						{{ "buttons.save" | translate }}
					</button>
				</div>
			</form>
		</div>
	</div>
</div>
