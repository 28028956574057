import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { loadMediaConstraints, saveMediaConstraints } from 'src/app/services/utils';
import { MediaDevicesService } from '../../../../lib-media-devices/services/media-devices.service';


@Injectable({
  providedIn: 'root'
})
export class MediaSettingsStorageService {
  public get vDeviceList(): Array<MediaDeviceInfo> {
    return this._vDeviceList;
  }

  public set vDeviceList(value: Array<MediaDeviceInfo>) {
    value.map((device) => {
      if (device.deviceId && device.deviceId.length > 5 && device.label && device.label.length > 5) {
        if (!this.vDeviceList.includes(device)) {
          this.vDeviceList.push(device);
        }
      }
    });
  }

  public get aDeviceList(): Array<MediaDeviceInfo> {
    return this._aDeviceList;
  }

  public set aDeviceList(value: Array<MediaDeviceInfo>) {
    value.map((device) => {
      if (device.deviceId && device.deviceId.length > 5 && device.label && device.label.length > 5) {
        if (!this.aDeviceList.includes(device)) {
          this.aDeviceList.push(device);
        }
      }
    });
  }

  get outputDevicesList() {
    return this._outputDevicesList;
  }


  set outputConfig(config: { deviceId: string }) {
    localStorage.setItem('outputDeviceId', config.deviceId);
    this.outputConfig$.next(config);
  }

  get outputConfig(): { deviceId: string } {
    return this.outputConfig$.value;
  }
  // tslint:disable-next-line:variable-name
  private _vDeviceList: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
  // tslint:disable-next-line:variable-name
  private _aDeviceList: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
  // tslint:disable-next-line:variable-name
  private _outputDevicesList: { kind: string; groupId: string; label: string; deviceId: string }[] = new Array<MediaDeviceInfo>();
  public vConfig = { width: { min: 384, ideal: 640 }, height: { min: 216, ideal: 360 }, frameRate: 18, deviceId: 'any' };
  public aConfig = { channelCount: 1, echoCancellation: true, noiseSuppression: true, autoGainControl: true, deviceId: 'any',
    sampleRate: 128000 };
  outputConfig$ = new BehaviorSubject({ deviceId: 'any' });
  constructor(private mediaDevicesService: MediaDevicesService) {
    this.initDeviceList(this.loadSettings);
  }
  public saveMediaConstraints(params?: MediaStreamConstraints) { // todo rework
    if (this.vConfig.deviceId && this.vConfig.deviceId !== 'any') {
      localStorage.setItem('vDeviceId', this.vConfig.deviceId);
    }
    if (this.aConfig.deviceId && this.aConfig.deviceId !== 'any') {
      localStorage.setItem('aDeviceId', this.aConfig.deviceId);
    }
    if (this.outputConfig.deviceId && this.outputConfig.deviceId !== 'any') {
      localStorage.setItem('outputDeviceId', this.outputConfig.deviceId);
    }
    saveMediaConstraints(params);
  }

  public initDeviceList = (next: () => void) => {
    this.mediaDevicesService.getDevices().then((devices) => {
      devices = devices.filter((device) => device.deviceId.length > 14);
      this.vDeviceList = devices.filter((device) => device.kind === 'videoinput');
      this.aDeviceList = devices.filter((device) => device.kind === 'audioinput');
      this._outputDevicesList = devices.filter((device) => device.kind === 'audiooutput');
      if (this._outputDevicesList.length === 0) { // bullshit. should be handled at settings component
        this._outputDevicesList = [{
          deviceId: 'default',
          label: 'Default Audio Output Device',
          groupId: 'default',
          kind: 'audiooutput'
        }];
      }
      next();
    });
  }
  public settingDevices(deviceList: MediaDeviceInfo[]): void {
    const devices: MediaDeviceInfo[] = (deviceList || []);
    this.vDeviceList = devices.filter((device) => device.kind === 'videoinput');
    this.aDeviceList = devices.filter((device) => device.kind === 'audioinput');
    this._outputDevicesList = devices.filter((device) => device.kind === 'audiooutput');
  }
  private loadSettings = () => {
    const localConstraint = loadMediaConstraints();
    if (localConstraint) {
      if (localConstraint.video as MediaTrackConstraints) {
        this.vConfig = Object.assign(this.vConfig, localConstraint.video);
      }
      if (localConstraint.audio as MediaTrackConstraints) {
        this.aConfig = Object.assign(this.aConfig, localConstraint.audio);
      }
    }
    // todo remove
    const vDeviceIdLocal = localStorage.getItem('vDeviceId');
    const outputDeviceLocal = localStorage.getItem('outputDeviceId');
    if (vDeviceIdLocal) {
      const selectedDevice = this.vDeviceList.find(d => d.deviceId === vDeviceIdLocal || d.label === vDeviceIdLocal);
      if (selectedDevice) {
        this.vConfig.deviceId = selectedDevice.deviceId;
      }
    }
    if (outputDeviceLocal) {
      const selectedOutput = this.outputDevicesList.find(d => d.deviceId === outputDeviceLocal);
      if (selectedOutput) {
        this.outputConfig = selectedOutput;
      }
    }
  }
}
