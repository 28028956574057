import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-removal-popup',
  templateUrl: './removal-popup.component.html',
  styleUrls: ['./removal-popup.component.scss']
})
export class RemovalPopupComponent implements OnInit {

  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) public request: string,
              public translate: TranslateService) {
    this.message = request;
  }

  ngOnInit() {
  }
}
