<h1 mat-dialog-title>{{ "material.confirm-file-delete" | translate }}</h1>
<mat-list>
	<mat-dialog-content>
		<div mat-subheader>File</div>
		<mat-list-item>
			<mat-icon matListItemIcon>description</mat-icon>
			<div matListItemTitle>{{ data.file.name }}</div>
			<!-- <div matListItemLine>{{file.updated | date}}</div> -->
		</mat-list-item>

		<mat-dialog-actions>
			<button class="error-bttn" (click)="onClose()">
				{{ "buttons.cancel" | translate }}
			</button>
			<button class="ok-bttn" (click)="onOkButton()">
				{{ "buttons.ok" | translate }}
			</button>
		</mat-dialog-actions>
	</mat-dialog-content>
</mat-list>
