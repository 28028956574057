import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ProfileApiService } from './profile-api.service';
import { ProfileDto } from './profile-api.interface';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public get profileDto(): ProfileDto { return this.innProfileDto; }
  public set profileDto(value: ProfileDto) { }

  private innProfileDto: ProfileDto | null = null;

  constructor(
    private profileApiService: ProfileApiService
  ) {
  }

  // ** Public API **

  public get(): Promise<ProfileDto | HttpErrorResponse> {
    return this.profileApiService.get()
      .then((value: ProfileDto) =>
        (this.innProfileDto = value));
  }

}
