import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Language, LanguagesList } from "../models/language";
import { Profile } from "../types/profile.types";
import { ProfileService } from "../profile/profile.service";
import { LanguageService } from "../services/languages/language.service";

@Component({
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.scss"],
})
export class LanguageSelectorComponent implements OnInit {
  constructor(
    private profileService: ProfileService,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {}

  public selectedLanguage: Language;
  public languages: Language[];
  public showLanguageList = false;

  ngOnInit(): void {
    this.loadLanguages()
      .then(() => {
        // this.loadProfile();
        this.selectedLanguage = this.languageService.languages.find(lang => lang.iso === localStorage.language);
        const selectedLanguageIso = this.selectedLanguage.iso;
        this.translateService.use(selectedLanguageIso);

      })
      .catch((error) => {
        console.error("error loading data", error);
      });
  }

  private loadProfile(): void {
    this.profileService.get().subscribe((profile: Profile) => {
      if (!profile) {
        return;
      }
      const selectedLanguage = this.languageService.languages.find(
        (lang) => lang._id === profile.languageId
      );
      this.selectedLanguage = selectedLanguage;
    });
  }

  private loadLanguages(): Promise<any> {
    return new Promise<any>(
      (resolve: (value: any) => void, reject: (reason?: any) => void) => {
        if (
          !this.languageService.languages ||
          !this.languageService.languages.length ||
          this.languageService.languages.length === 0
        ) {
          this.profileService.getLanguages().subscribe(
            (languages: LanguagesList) => {
              this.languageService.languages = this.languages = languages.list;
              resolve(null);
            },
            (error) => {
              reject();
            }
          );
        } else {
          this.languages = this.languageService.languages;
          resolve(null);
        }
      }
    );
  }
  

  public onSelect(selectedLanguageId) {
    const selectedLanguage = this.languages.find(
      (language: Language) => language._id === selectedLanguageId
    );
    const selectedLanguageIso = selectedLanguage.iso;
    this.showLanguageList = false;
    this.translateService.use(selectedLanguageIso);
    this.selectedLanguage = selectedLanguage;
    this.languageService.change(selectedLanguageIso);
    this.profileService
      .save({ language: selectedLanguageId })
      .subscribe((profile: Profile | null) => {
        localStorage.setItem("language", selectedLanguageIso);
      });
  }
}
