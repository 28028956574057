import { DefaultUrlSerializer, UrlTree } from "@angular/router";

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
	parse(url: string): UrlTree {
		return super.parse(url.toLowerCase());
	}
}

export const roomKeyFromUrl = (): string => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const roomKey = urlParams.get("roomKey");
	return roomKey;
};
