<mat-dialog-content>
  <div class="popup-text-title">{{ title | translate }}</div>
  <div class="popup-text">{{ text | translate }}</div>
  <mat-dialog-actions>
    <span class='error-bttn'
          [mat-dialog-close]="true"
          (click)="onCancelClick()">
      {{ cancelButtonData.text | translate }}
    </span>
    <button class='ok-bttn'
            (click)="onOkClick()">
      {{ okButtonData.text | translate }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
