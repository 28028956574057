export class OrganizationVO {
  constructor() {}
  id: string;
  subdomain: string;
  logo: string;
  name: string;
  watermark: string;
  backgroundImage: string;
  attachments: any;
  apiKey: string;
}
