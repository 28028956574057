import {Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { logger } from '../lib-core/logger';
import { MediaDevicesService } from '../lib-media-devices/services/media-devices.service';

@Injectable({ providedIn : 'root' })
export class ClipboardService {
  public copySuccess = false;
  public copyTimeout: ReturnType<typeof setTimeout>;

  constructor(private translateService: TranslateService, private mediaDevicesService: MediaDevicesService) {}

  copyLink(link: string, password: string) {
    this.translateService.get(['call-room.link', 'call-room.password'])
      .subscribe((translation) => {
        const linkText: string = translation['call-room.link'];
        const passwordText: string = translation['call-room.password'];
        const invitationText = `https://${link.split('?')[0]}?password=${password}`;
        // const invitationText = `${linkText} https://${link.split('?')[0]}\n${passwordText} ${password}`;
        this.mediaDevicesService.writeText(invitationText).then((result) => {
          this.copySuccess = true;
          clearTimeout(this.copyTimeout);
          this.copyTimeout = setTimeout(() => { this.copySuccess = false; }, 3000);
        }, (error) => {
          logger.log('[ClipboardService] Error copying the link. Please try again.', error);
          this.copySuccess = false;
        });
      });
  }

  copyUrl(invitationText: string) {
	  this.mediaDevicesService.writeText(invitationText).then((result) => {
		  this.copySuccess = true;
		  clearTimeout(this.copyTimeout);
		  this.copyTimeout = setTimeout(() => { this.copySuccess = false; }, 3000);
	  }, (error) => {
		  logger.log('[ClipboardService] Error copying the link. Please try again.', error);
		  this.copySuccess = false;
	  });
  }


}
