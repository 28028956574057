<mat-dialog-content>
	<div class="popup-text">{{ text | translate }}</div>
	<!--  todo add dynamic adding buttons-->
	<mat-dialog-actions>
		<button *ngIf="!singleBtn" class="ok-bttn" [mat-dialog-close]="true" (click)="onOkClick()">
			{{ okButtonData.text | translate }}
		</button>
		<button class="error-bttn" [mat-dialog-close]="true" (click)="onCancelClick()">
			{{ cancelButtonData.text | translate }}
		</button>
	</mat-dialog-actions>
</mat-dialog-content>
