import { Injectable } from '@angular/core';
import { PlatformDetectorService } from '../platform-detector/platform-detector.service';

@Injectable()

export class LoggingService {

  private deviceInfo = null;
  private agent: string;
  private device: string;
  private camera: string;
  private microphone: string;

  constructor(private platformDetectorService: PlatformDetectorService) {
    this.deviceInfo = this.platformDetectorService.deviceInfo;
    const isMobile = this.platformDetectorService.isMobile();
    const isTablet = this.platformDetectorService.isTablet();
    const isDesktopDevice = this.platformDetectorService.isDesktop();
    if (isMobile) {
      this.device = 'Mobile ';
    } else if (isTablet) {
      this.device = 'Tablet ';
    } else if (isDesktopDevice) {
      this.device = 'Desktop ';
    }
    this.agent = this.deviceInfo.userAgent + ' ' + this.deviceInfo.browser + ' ' + this.deviceInfo.browser_version;
    this.device += this.deviceInfo.device + ' ' + this.deviceInfo.os + ' ' + this.deviceInfo.os_version;
  }

  getAgent(): string {
    return this.agent;
  }

  getDevice(): string {
    if (!this.device) {
      this.device = this.deviceInfo.device + ' ' + this.deviceInfo.os + ' ' + this.deviceInfo.os_version;
    }
    return this.device;
  }

  getCamera(): string {
    return this.camera;
  }

  setCamera(cameraInfo: string): void {
    this.camera = cameraInfo;
  }

  getMicrophone(): string {
    return this.microphone;
  }

  setMicrophone(microphoneInfo: string): void {
    this.microphone = microphoneInfo;
  }
}
