import { Component, Inject, OnInit } from '@angular/core';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MediaDevicesService } from '../../../../lib-media-devices/services/media-devices.service';

@Component({
  selector: 'app-room-invite-info',
  templateUrl: './room-invite-info.component.html',
  styleUrls: ['./room-invite-info.component.scss']
})
export class RoomInviteInfoComponent implements OnInit {
  link: string;
  password: string;
  isCopied = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InviteRoomData,
    private dialogRef: MatDialogRef<RoomInviteInfoComponent>,
    private translate: TranslateService,
    private mediaDevicesService: MediaDevicesService
    ) {}

  ngOnInit() {
    this.link = this.data.link.replace(/^(https?|ftp):\/\//, '');
    this.password = this.data.password;
  }
  copy() {
    this.translate.get(['call-room.link', 'call-room.password'])
      .subscribe((translation) => {
        const linkText: string = translation['call-room.link'];
        const passwordText: string = translation['call-room.password'];
        const text = `${linkText} https://${this.link}\n${passwordText} ${this.password}`;
        this.mediaDevicesService.writeText(text)
          .then(() => {
            this.isCopied = true;
          });
      });
  }
  close() {
    this.dialogRef.close();
  }

}
export interface InviteRoomData {
  link: string;
  password: string;
}
