import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { CropperSettings, ImageCroppedEvent, ImageCropperComponent } from "ngx-image-cropper";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { CommonModule } from "@angular/common";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProfileService } from "../profile/profile.service";

const FILE_SIZE_LIMIT = 5 * 1024 * 1024;

@Component({
	selector: "app-picture-edit",
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		MatSnackBarModule,
		MatToolbarModule,
		MatIconModule,
		ImageCropperComponent,
		MatFormFieldModule,
		MatButtonModule
	],
	templateUrl: "./picture-edit.component.html",
	styleUrl: "./picture-edit.component.scss"
})
export class PictureEditComponent {
	errorMessage: null | string;
	imageUrl: string | ArrayBuffer | null;
	ondrop = false;
	@ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement>;
	imageChangedEvent: Event | DragEvent;
	croppedImage: SafeUrl = "";
	fileName: string;
	selectedImage: Blob | null | undefined;
	loading = false;
	cropperSettings: CropperSettings;
	imageFile

	constructor(
		private sanitizer: DomSanitizer,
		private translationService: TranslateService,
		private snackBar: MatSnackBar,
		private profileService: ProfileService,
		@Inject(MAT_DIALOG_DATA) public data,
		private dialogRef: MatDialogRef<PictureEditComponent>
	) {
		this.cropperSettings = new CropperSettings();
	}

	async onSubmit() {
		if (this.selectedImage) {
			// this.loading = true;

			const finalImage = this.selectedImage;

			const file: File = new File([finalImage], this.fileName, {
				type: finalImage.type
			});

			// this.profileService.savePhoto(file).subscribe(
			// 	(res) => {
			// 		if (res?.photo) {
			// 			this.dialogRef.close(res?.photo);
			// 			this.loading = false;
			// 		}
			// 	},
			// 	() => {
			// 		this.loading = false;
			// 	}
			// );

			this.dialogRef.close(file);
		}
	}

	closeDialog() {
		this.dialogRef.close();
	}

	onDragOver(event: DragEvent | Event) {
		this.ondrop = true;
		event.preventDefault();
		event.stopPropagation();
	}

	onDragLeave(event: DragEvent) {
		this.ondrop = false;
		event.preventDefault();
		event.stopPropagation();
	}

	onDrop(event: DragEvent) {
		event.preventDefault();
		event.stopPropagation();
		this.ondrop = true;
		const file = event?.dataTransfer?.files[0];
		if (file) {
			this.onFileSelected(file, true);
		}
	}

	onFileSelected(event: Event | any, fromDrop = false) {
		let file;
		if (fromDrop) {
			file = event;
		} else {
			file = event?.target?.files[0];
		}
		if (file) {
			this.fileName = file.name.split("\\").pop().split("/").pop();

			if (!["image/png", "image/jpeg"].includes(file.type)) {
				this.ondrop = false;
				this.errorMessage = this.translationService.instant(
					"select_upload_image.invalid_image_type"
				);
				this.errorMessage && this.openSnackBar(this.errorMessage);
				return;
			}
			if (file.size > FILE_SIZE_LIMIT) {
				this.ondrop = false;
				this.errorMessage = this.translationService.instant(
					"select_upload_image.image_size_exceeds_limit"
				);
				this.errorMessage && this.openSnackBar(this.errorMessage);
				return;
			}

			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = () => {
				this.errorMessage = null;
				this.imageUrl = reader.result;
			};

			let selectedEvent = event;
			if (fromDrop) {
				selectedEvent = {
					isTrusted: true,
					target: {
						files: [event]
					}
				};
				this.imageFile = file;
			}
			this.imageChangedEvent = selectedEvent;

			reader.onerror = () => {
				this.ondrop = false;
				this.errorMessage = this.translationService.instant(
					"select_upload_image.error_occurred"
				);
				this.errorMessage && this.openSnackBar(this.errorMessage);
				this.imageUrl = null;
			};
		}
	}

	imageCropped(event: ImageCroppedEvent) {
		if (event.objectUrl != null) {
			this.selectedImage = event.blob;
			this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(
				event.objectUrl
			);
		}
	}

	change() {
		this.fileInput.nativeElement.click();
	}

	openSnackBar(error: string) {
		this.snackBar.open(error, "", {
			duration: 4000,
			verticalPosition: "top",
			horizontalPosition: "center",
			panelClass: ["custom-snackBar-position"]
		});
	}
}
