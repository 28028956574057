"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var EmptyError_1 = require("../util/EmptyError");
var Subscriber_1 = require("../Subscriber");
function throwIfEmpty(errorFactory) {
  if (errorFactory === void 0) {
    errorFactory = defaultErrorFactory;
  }
  return function (source) {
    return source.lift(new ThrowIfEmptyOperator(errorFactory));
  };
}
exports.throwIfEmpty = throwIfEmpty;
var ThrowIfEmptyOperator = function () {
  function ThrowIfEmptyOperator(errorFactory) {
    this.errorFactory = errorFactory;
  }
  ThrowIfEmptyOperator.prototype.call = function (subscriber, source) {
    return source.subscribe(new ThrowIfEmptySubscriber(subscriber, this.errorFactory));
  };
  return ThrowIfEmptyOperator;
}();
var ThrowIfEmptySubscriber = function (_super) {
  __extends(ThrowIfEmptySubscriber, _super);
  function ThrowIfEmptySubscriber(destination, errorFactory) {
    var _this = _super.call(this, destination) || this;
    _this.errorFactory = errorFactory;
    _this.hasValue = false;
    return _this;
  }
  ThrowIfEmptySubscriber.prototype._next = function (value) {
    this.hasValue = true;
    this.destination.next(value);
  };
  ThrowIfEmptySubscriber.prototype._complete = function () {
    if (!this.hasValue) {
      var err = void 0;
      try {
        err = this.errorFactory();
      } catch (e) {
        err = e;
      }
      this.destination.error(err);
    } else {
      return this.destination.complete();
    }
  };
  return ThrowIfEmptySubscriber;
}(Subscriber_1.Subscriber);
function defaultErrorFactory() {
  return new EmptyError_1.EmptyError();
}
