<div class="main-container bg-white text-offblack">
    <button (click)="close()"
        class="text-offblack text-text-1 ml-auto mb-[4px] rounded-full bg-cold-offwhite hover:bg-cold-offwhite-2 w-[32px] h-[32px] flex justify-center items-center hover:bg-cold-offwhite-hover transition-all">
        <mat-icon class="close-icon text-black" svgIcon="close"></mat-icon>
    </button>

    <div class="text-headline-2 font-bold leading-[28px]">
        @if (status === "pending") {
        {{'lessons.requested'|
        translate}}
        }
        @else {
        {{"popUp.music-lesson-with" | translate }}
        }
        {{ name }}
    </div>

    <div class="flex text-text-1 leading-[28px]">
        <span class="flex items-center mr-[4px]" [style.color]="lessonColor">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="10" height="10" fill="currentColor" />
            </svg>
        </span>
        <span> {{ typeString }}, {{ duration }} {{ "popUp.minutes" | translate }} </span>
    </div>

    <div class="text-text-1 leading-[28px]">
        {{ detailsString }}
    </div>
    @if(type === "trial"){
    <hr class="my-[16px]" />
    <div class="text-text-2 leading-[26px]">
        <div *ngIf="name" class="font-bold flex items-center">
            <mat-icon class="person-icon mr-[4px]" svgIcon="person"></mat-icon>
            {{ "popUp.about" | translate }} {{ name }}
        </div>
        <ng-container *ngIf="instruments">
            <div>
                {{ "student-info.instrument" | translate }}
                : {{ instruments }}
            </div>
        </ng-container>
        <ng-container *ngIf="genre">
            <div>{{ "student-info.genre" | translate }}: {{ genre }}</div>
        </ng-container>
        <ng-container *ngIf="ageGroup">
            <div>
                {{ "student-info.age-group" | translate }}
                : {{ ageGroup }}
            </div>
        </ng-container>
        <ng-container *ngIf="skillLevel">
            <div>
                {{ "student-info.skill-level" | translate }}
                : {{ "skill-level." + skillLevel | translate }}
            </div>
        </ng-container>
        <ng-container *ngIf="favouriteSong">
            <div>
                {{ "student-info.favourite-song" | translate }}
                : {{ favouriteSong }}
            </div>
        </ng-container>
        <ng-container *ngIf="notesForTeacher">
            <div>
                {{ "student-info.notes-for-you" | translate }}
                :
                {{ notesForTeacher }}
            </div>
        </ng-container>
    </div>
    }

    @if (status === "pending") {
    <div class="button-box flex gap-1 flex-wrap justify-end">
        <button
            class="bg-white text-indigo rounded-full text-text-1 border border-solid border-indigo font-bold hover:bg-cold-offwhite"
            (click)="declineRequest()">{{'lessons.decline' | translate}}</button>
        <button class="bg-indigo text-white rounded-full text-text-1 font-bold hover:bg-indigo-2 transition-all"
            (click)="
        acceptRequest()">{{'lessons.accept' | translate}}</button>
    </div>
    }

    @if (status === "declined") {
    <div class="text-text-1 leading-[28px] text-alert">
        {{ "lessons.declined-lesson" | translate }}
    </div>
    }

    @if(!readonly && status !== "pending" && status !== "declined" ){<div
        class="button-box flex gap-2 flex-wrap justify-end">
        <button *ngIf="type !== 'trial'" (click)="reschedule()"
            class="bg-white text-indigo rounded-full text-text-1 border border-solid border-indigo font-bold hover:bg-cold-offwhite">
            {{ "lessons.reschedule" | translate }}
        </button>
        <button (click)="startLesson()"
            class="bg-indigo text-white rounded-full text-text-1 font-bold hover:bg-indigo-2 transition-all">
            {{ "lessons.start-btn" | translate }}
        </button>
    </div>}
</div>