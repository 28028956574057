import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Api} from './api';
import {Injectable} from '@angular/core';
import {OrganizationParser} from '../data/organization-parser';
import {OrganizationVO} from '../data/OrganizationVO';
import {AuthenticationService} from '../../../services/authentication/authentication.service';

@Injectable()
export class OrganizationDataService {
  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService) {}

  get(page?: number): Observable<{page: number, length: number, items: OrganizationVO[]}> { // add type
    return new Observable<any>(obs => {
      let url = '/organizations';
      if (page) {
        url += '?page=' + page;
      }
      this.http.get(Api.url(url), {headers: this.authenticationService.headers, withCredentials: true})
        .subscribe((data: {page: number, pages: number, list: object[]}) => {
          // @ts-ignore
          const organizations = OrganizationParser.parse(data.list.filter(item => item.status));
          obs.next({items: organizations, page: data.page, length: data.pages });
          obs.complete();
          }, error => {
          obs.error(error);
        });
    });
  }

  getBySubdomain(subDomain: string): Promise<OrganizationVO> {
    const url = '/organizations/subdomain/' + subDomain;
    const options = { headers: this.authenticationService.headers, withCredentials: true };
    return this.http.get(Api.url(url), options)
      .toPromise()
      .then((data) =>
        OrganizationParser.parse([data])[0]);
  }

  update(org: OrganizationVO): Observable<OrganizationVO> {
    const url = `/organizations/${org.id}`;
    const dataForUpdate = OrganizationParser.toServerUpdate(org);
    return new Observable<OrganizationVO>(obs => {
      this.http.put(Api.url(url), dataForUpdate, {headers: this.authenticationService.formDataHeaders, withCredentials: true})
        .subscribe(data => {
          const updatedOrg = OrganizationParser.parse([data])[0];
          if (updatedOrg) {
            obs.next(updatedOrg);
            obs.complete();
          } else {
            obs.error(new Error('Can\'t update organization'));
          }
        }, error => {
          obs.error(error);
        });
    });
  }
  add (org: OrganizationVO): Observable<OrganizationVO> {
    const url = '/organizations';
    const dataForUpdate = OrganizationParser.toServerUpdate(org);
    return new Observable<OrganizationVO>(obs => {
      this.http.post(Api.url(url), dataForUpdate, {headers: this.authenticationService.formDataHeaders, withCredentials: true})
        .subscribe(res => {
          const newOrg = OrganizationParser.parse([res])[0];
          if (newOrg) {
            obs.next(newOrg);
            obs.complete();
          } else {
            obs.error(new Error('Can\'t add organization'));
          }
        }, error => {
          obs.error(error);
        });
    });
  }
  delete(id: string): Observable<OrganizationVO> {
    const url = `/organizations/${id}`;
    return new Observable<OrganizationVO>(obs => {
      this.http.delete(Api.url(url), {headers: this.authenticationService.headers, withCredentials: true})
        .subscribe(res => {
          const deletedOrg = OrganizationParser.parse([res])[0];
          obs.next(deletedOrg);
          obs.complete();
        }, error => {
          obs.error(error);
        });
    });
  }
}
