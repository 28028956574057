export function placeNameOnVideos(isOldVariant: boolean): void {
  if (!isOldVariant) { return; }
// Place user name over video
  const otherUsers = document.querySelectorAll('user-call') as NodeListOf<HTMLElement>;
  const participant = document.querySelector('div.participant') as HTMLElement;
  const allUsers = [...otherUsers, participant];

  allUsers.forEach((videoBlock) => {
  const videoParentWidth = videoBlock.clientWidth;
  const videoParentHeight = videoBlock.clientHeight;
  const videoParentRatio = videoParentWidth / videoParentHeight;
  const video = videoBlock.querySelector('video');
  const videoWidth = video.videoWidth;
  const videoHeight = video.videoHeight;
  const videoRatio = videoWidth / videoHeight;

  if (videoWidth && videoHeight) {
    const nameBlock = videoBlock.querySelector('p');
    const userActions = videoBlock.querySelector<HTMLElement>('app-user-actions');
    // No need to reset inline css styles, because p has position absolute only in split view
    if (videoParentRatio > videoRatio) { // Gorizontal
      const leftPosition: number = (videoParentWidth - videoParentHeight / videoHeight * videoWidth) / 2;
      nameBlock.style.left = `${leftPosition}px`;
      nameBlock.style.bottom = '0';
      if (userActions) {
        userActions.style.right = `${leftPosition + 20}px`;
        userActions.style.bottom = '20px';
      }
    } else { // Vertical
      const bottomPosition: number = (videoParentHeight - videoParentWidth / videoWidth * videoHeight) / 2;
      nameBlock.style.bottom = `${bottomPosition}px`;
      nameBlock.style.left = '0';
      if (userActions) {
        userActions.style.bottom = `${bottomPosition + 20}px`;
        userActions.style.right = '20px';
      }
    }
  } else {
    video.addEventListener('loadeddata', () => {
      placeNameOnVideos(isOldVariant);
    }, false);
  }
});
}

export function getTeacherName(teacherName: string): string {
  // SIRIUS-755_If_teacher_has_too_long_name_the_unmute_request_looks_wrong
  const wordLength = 35;
  let result = '';
  const len = teacherName.length;
  let idx = 0;
  while (idx < len) {
    const delta = ((idx + wordLength) > len ? len - idx : wordLength);
    result += teacherName.substr(idx, delta) + ' ';
    idx += delta;
  }
  return result;
}
