"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var Subscriber_1 = require("../Subscriber");
function ignoreElements() {
  return function ignoreElementsOperatorFunction(source) {
    return source.lift(new IgnoreElementsOperator());
  };
}
exports.ignoreElements = ignoreElements;
var IgnoreElementsOperator = function () {
  function IgnoreElementsOperator() {}
  IgnoreElementsOperator.prototype.call = function (subscriber, source) {
    return source.subscribe(new IgnoreElementsSubscriber(subscriber));
  };
  return IgnoreElementsOperator;
}();
var IgnoreElementsSubscriber = function (_super) {
  __extends(IgnoreElementsSubscriber, _super);
  function IgnoreElementsSubscriber() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  IgnoreElementsSubscriber.prototype._next = function (unused) {};
  return IgnoreElementsSubscriber;
}(Subscriber_1.Subscriber);
