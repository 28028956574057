<mat-dialog-content>
	<div class="content" [ngStyle]="{ opacity: shouldHideSelectContact ? 0 : 1 }">
		<div class="calendar-content"></div>
		<form [formGroup]="selectStudentForm" (submit)="open()">
			<button [mat-dialog-close]="true" type="button" title="close" class="closeBtn">×</button>
			<header class="title">
				<span style="color: #151515">{{ "lessons.student-select-placeholder" | translate }}</span>
			</header>
			<div class="settings-container">
				<div class="row-media mb-6">
					<div class="media-prm">
						<mat-select
							formControlName="student"
							placeholder="{{ 'lessons.student-select-title' | translate }}"
							panelClass="app-select-panel"
							disableOptionCentering="true"
							class="app-select"
						>
							<mat-option *ngFor="let student of studentList" [value]="student.id">{{
								student.name
							}}</mat-option>
						</mat-select>
					</div>
				</div>
			</div>
			<div class="btn-wrapper">
				<button
					type="submit"
					[ngClass]="{ 'Button--disabled': selectStudentForm.invalid }"
					class="Button Button--primary Button--small Button--full Button--filled"
					[disabled]="!selectStudentForm.valid"
				>
					{{ continueBtnText | translate }}
				</button>
			</div>
		</form>
	</div>
</mat-dialog-content>
