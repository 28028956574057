import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { ButtonType } from '../../../call-room/data/ButtonType';
import { IconService } from '@shared/services/icon.service';
import { TranslateService } from '@ngx-translate/core';
import { Validators,FormBuilder,FormGroup } from '@angular/forms'
import { from } from 'rxjs';

@Component({
  selector: 'app-reschedule-lesson',
  templateUrl: './reschedule-lesson.component.html',
  styleUrls: [
    './reschedule-lesson.component.scss',
    './reschedule-lesson.component.tablet.scss',
    './reschedule-lesson.component.mobile.scss',
  ],
})
export class RescheduleLessonComponent implements OnInit {
	cancelButtonData: {text: string, callBack?: () => {} } = {text: ''};
	okButtonData: {text: string, callBack?: (dialog?: MatDialog,textareaValue?:string) => {} } = {text: ''};
	lessonTimeInterval: string;
	duration: string;
	target: string;
	title: string;
	timeZone: string;
    textareaValue: string = '';
    textareaForm: FormGroup;
	constructor(@Inject(MAT_DIALOG_DATA) private selectedData: any,
    private fb: FormBuilder,
				private dialog: MatDialog,
				private iconService: IconService,
				private translateService: TranslateService) {
                    this.textareaForm = this.fb.group({
                        textarea: ["", Validators.required], // Set an empty initial value
                      });
	}

	get studentName():string {
		return this.selectedData?.data?.studentName;
	}

	ngOnInit() {
		const startDate = new Date(this.selectedData?.data?.date);
		this.timeZone = this.selectedData?.data.timeZone;
		this.lessonTimeInterval = this.getLessonTimeInterval(startDate);
		this.duration = this.selectedData?.data.duration;
		this.target = this.selectedData?.data.target;
		this.title = this.selectedData?.data.title;
		const okButtons = this.selectedData.buttons.filter(i => i.type === ButtonType.OK);
		if (okButtons.length > 0) {
			this.okButtonData = { text: okButtons[0].text, callBack: okButtons[0].callBack};
		}
		const cancelButtons = this.selectedData.buttons.filter(i => i.type === ButtonType.CANCEL);
		if (cancelButtons.length > 0) {
			this.cancelButtonData = { text: cancelButtons[0].text, callBack: cancelButtons[0].callBack};
		}

	}
	onCancelClick() {
		if (this.cancelButtonData.callBack) {
			this.cancelButtonData.callBack();
		}
	}
	onOkClick() {
		if (this.okButtonData.callBack) {
            const textareaValue = this.textareaForm.get('textarea').value;
			this.okButtonData.callBack(this.dialog,textareaValue);
		}
	}

	getLessonTimeInterval(date: Date): string {
		const selectedLanguage = this.translateService.currentLang;
		const dateLocal = selectedLanguage === 'eng' ? 'en-US' : 'de-DE';
		const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
		const fullDate = date.toLocaleDateString(dateLocal, options);
		const startTime = new Date(date.getTime()).toLocaleString(dateLocal, {hour: '2-digit', minute: '2-digit', hour12: false});
		let endDate = date;
		endDate.setMinutes(endDate.getMinutes() + 30);
		const endTime = new Date(endDate.getTime()).toLocaleString(dateLocal, {hour: '2-digit', minute: '2-digit', hour12: false});
		return `${startTime} – ${endTime} <br>${fullDate}`;
	}


}
