"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var switchMap_1 = require("./switchMap");
function switchMapTo(innerObservable, resultSelector) {
  return resultSelector ? switchMap_1.switchMap(function () {
    return innerObservable;
  }, resultSelector) : switchMap_1.switchMap(function () {
    return innerObservable;
  });
}
exports.switchMapTo = switchMapTo;
