// import { Rating } from '@testrtc/watchrtc-sdk/lib/interfaces';

export enum QualityRating {
  Terrible = 'Terrible',
  Bad = 'Bad',
  Okay = 'Okay',
  Good = 'Good',
  Excellent = 'Excellent'
}

export class QualityRatingUtil {
  public static create(value: string): QualityRating | null {
    let result: QualityRating | null = null;
    switch (value) {
      case QualityRating.Terrible.valueOf(): result = QualityRating.Terrible; break;
      case QualityRating.Bad.valueOf(): result = QualityRating.Bad; break;
      case QualityRating.Okay.valueOf(): result = QualityRating.Okay; break;
      case QualityRating.Good.valueOf(): result = QualityRating.Good; break;
      case QualityRating.Excellent.valueOf(): result = QualityRating.Excellent; break;
    }
    return result;
  }

//   static getRatingStar(qualityRating: QualityRating): Rating {
//     const index = ['Terrible', 'Bad', 'Okay', 'Good', 'Excellent'].indexOf(qualityRating);
//     return index + 1 as Rating;
//   }
}


export enum MiddlePanelMode {
  rating = 'rating',
  problems = 'problems',
  notes = 'notes',
  gratitude = 'gratitude'
}

export class MiddlePanelModeUtil {
  public static create(value: string): MiddlePanelMode | null {
    let result: MiddlePanelMode | null = null;
    switch (value) {
      case MiddlePanelMode.rating.valueOf(): result = MiddlePanelMode.rating; break;
      case MiddlePanelMode.problems.valueOf(): result = MiddlePanelMode.problems; break;
      case MiddlePanelMode.notes.valueOf(): result = MiddlePanelMode.notes; break;
      case MiddlePanelMode.gratitude.valueOf(): result = MiddlePanelMode.gratitude; break;
    }
    return result;
  }
}

export type SubmitFeedbackProblems = {
  optionsAudioIssues: string[];
  optionsVideoIssues: string[];
  isOtherAudioIssue: boolean;
  isOtherVideoIssue: boolean;
}

export interface SubmitFeedbackNotes {
  notes: string;
}

export const RATING_ASSESSMENTS = [
	{
		emoji: "😠",
		title: "rating-component.not-at-all",
		value: 1,
	},
	{
		emoji: "🙁",
		title: "rating-component.less-good",
		value: 2,
	},
	{
		emoji: "🙂",
		title: "rating-component.neutral",
		value: 3,
	},
	{
		emoji: "😀",
		title: "rating-component.good",
		value: 4,
	},
	{
		emoji: "😍",
		title: "rating-component.very-good",
		value: 5,
	},
];

export const STUDENT_LEVELS = [
	{
		title: "trial-feedback-teacher.q2.option1",
		value: "No prior experience",
	},
	{
		title: "trial-feedback-teacher.q2.option2",
		value: "Self-acquired skills",
	},
	{
		title: "trial-feedback-teacher.q2.option3",
		value: "Less than 3 years of teaching",
	},
	{
		title: "trial-feedback-teacher.q2.option4",
		value: "Professional",
	},
];

export const STUDENT_AGES = [
	{
		title: "trial-feedback-teacher.q3.option1",
		value: "Child (under 12 years)",
	},
	{
		title: "trial-feedback-teacher.q3.option2",
		value: "Teenager (13 to 17 years)",
	},
	{
		title: "trial-feedback-teacher.q3.option3",
		value: "Adult (18 to 39 years)",
	},
	{
		title: "trial-feedback-teacher.q3.option4",
		value: "Adult (40 to 64 years)",
	},
	{
		title: "trial-feedback-teacher.q3.option5",
		value: "Senior (65 years and older)",
	},
];

export const STUDENT_ATTENDANCES = [
	{
		title: "trial-feedback-teacher.attendance.option1",
		value: "Yes, everything took place as planned",
	},
	{
		title: "trial-feedback-teacher.attendance.option2",
		value: "I was late",
	},
	{
		title: "trial-feedback-teacher.attendance.option3",
		value: "The student was late",
	},
	{
		title: "trial-feedback-teacher.attendance.option4",
		value: "The student didn’t show up",
	},
	{
		title: "trial-feedback-teacher.attendance.other",
		value: "Something else",
	},
];