import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingComponent } from './loading.component';
import { LoadingDirective } from './loading.directive';
import { SpinnerModule } from '../spinner/spinner.module';


@NgModule({
    declarations: [
        LoadingComponent,
        LoadingDirective,
    ],
    imports: [
        CommonModule,
		SpinnerModule
    ],
    exports: [
        LoadingComponent,
        LoadingDirective,
    ]
})
export class LoadingModule { }
