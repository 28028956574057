import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { GONE, WebSocketService } from '../../../../services/ws/web-socket.service';

@Component({
  templateUrl: './let-in-waiting.component.html',
  styleUrls: ['./let-in-waiting.component.scss']
})
export class LetInWaitingComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<LetInWaitingComponent>,
    private authenticationService: AuthenticationService,
    private webSocketService: WebSocketService,
  ) { }

  cancel(): void {
    this.dialogRef.close();
    this.webSocketService.dispatch(GONE);
    this.authenticationService.checkLoggedIn$().toPromise().then((loggedIn) => {
      if (!!loggedIn) {
        this.router.navigate(['/platform/start']);
      } else {
        window.location.href = '/';
      }
    });
  }
}
