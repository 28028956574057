"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var Subscriber_1 = require("../Subscriber");
function dematerialize() {
  return function dematerializeOperatorFunction(source) {
    return source.lift(new DeMaterializeOperator());
  };
}
exports.dematerialize = dematerialize;
var DeMaterializeOperator = function () {
  function DeMaterializeOperator() {}
  DeMaterializeOperator.prototype.call = function (subscriber, source) {
    return source.subscribe(new DeMaterializeSubscriber(subscriber));
  };
  return DeMaterializeOperator;
}();
var DeMaterializeSubscriber = function (_super) {
  __extends(DeMaterializeSubscriber, _super);
  function DeMaterializeSubscriber(destination) {
    return _super.call(this, destination) || this;
  }
  DeMaterializeSubscriber.prototype._next = function (value) {
    value.observe(this.destination);
  };
  return DeMaterializeSubscriber;
}(Subscriber_1.Subscriber);
