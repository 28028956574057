"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var mergeMap_1 = require("./mergeMap");
function concatMap(project, resultSelector) {
  return mergeMap_1.mergeMap(project, resultSelector, 1);
}
exports.concatMap = concatMap;
