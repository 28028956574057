import { Injectable } from '@angular/core';
import { UsersService } from './users.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { UserVO } from './data/UserVO';
import { RoomStateStorage } from '../components/call-room/service/storage/RoomStateStorage';
import { EasyRtcService } from '../lib-rtc/services/easy-rtc.service';
import { BotService } from './bot/bot.service';

export interface SpeakerTime {
  [key: string]: {
    time: string;
    timer: number;
    userName: string;
    local?: boolean;
  };
}

@Injectable()
export class SpeakerTimeDetectorService {
  speakerTimeData$: BehaviorSubject<SpeakerTime> = new BehaviorSubject({});
  speakerTimeData: SpeakerTime = {};
  localMicTimeData: SpeakerTime = {};
  localMicTimeData$: BehaviorSubject<SpeakerTime> = new BehaviorSubject({});
  private timer = 0;
  audioContext: AudioContext;
  localAudioLevel: number;
  localUser: UserVO;
  onStats$: Subject<any> = new Subject();
  tempMediaStream: MediaStream;
  analyserNode: AnalyserNode;
  pcmData: Float32Array;

  get hours(): number {
    return Math.floor(this.timer / 3600);
  }

  get minutes(): number {
    return Math.floor(this.timer / 60) % 60;
  }

  get seconds(): number {
    return this.timer % 60;
  }
  roomSpeakers: UserVO[] = [];

  get userList(): UserVO[] {
    if (this.roomSpeakers.length <= this.usersService.userList.length) {
      this.roomSpeakers = this.usersService.userList.filter((user) => !user.isRecordingBot);
    }
    return this.roomSpeakers;
  }
  constructor(private usersService: UsersService, private roomState: RoomStateStorage) {
    const speakerData = JSON.parse(localStorage.getItem('speakerData'));
    if (speakerData) {
      this.speakerTimeData = speakerData;
      this.speakerTimeData$.next(this.speakerTimeData);
    }
    const localMicData = JSON.parse(localStorage.getItem('localMicData'));
    if (localMicData) {
      this.localMicTimeData = localMicData;
      this.localMicTimeData$.next(this.localMicTimeData);
    }
    this.onStats$.subscribe(() => {
      this.calculateSumSquares();
      if (this.localAudioLevel > 0.002 && this.localUser && this.roomState.isMicroOn) {
        this.calculateLocalMicTime(this.localAudioLevel, this.localUser.id);
      }
    });
  }

  calculateSpeakerTime(audioLevel: number, userId: string) {
    const name = this.userList.find(user => user.id === userId).name;
    if (audioLevel > 0.015) {
      const speakerData = JSON.parse(localStorage.getItem('speakerData'));
      if (speakerData && speakerData[name]) {
        this.timer = Number(speakerData[name].timer);
      } else {
        this.timer = 0;
      }
      const userName = this.userList.find(user => user.id === userId).name;
      this.speakerTimeData[userName] = {
        time: this.getSpeakerTime(userName),
        timer: this.timer,
        userName,
      };
      this.speakerTimeData$.next(this.speakerTimeData);
    }
  }

  calculateLocalMicTime(audioLevel: number, userId: string) {
    const name = this.userList.find(user => user.id === userId).name;
    if (audioLevel > 0.002) {
      const speakerData = JSON.parse(localStorage.getItem('localMicData'));
      if (speakerData && speakerData[name]) {
        this.timer = Number(speakerData[name].timer);
      } else {
        this.timer = 0;
      }
      const userName = this.localUser.name;
      this.localMicTimeData[userName] = {
        time: this.getSpeakerTime(userName),
        timer: this.timer,
        userName,
        local: true
      };
      this.localMicTimeData$.next(this.localMicTimeData);
    }
  }

  async calculateLocalSpeakTime(localUser?: UserVO) {
    let mediaStream = EasyRtcService.getLocalStream();
    if (!mediaStream) {
      const mediaIds: any = EasyRtcService.getLocalMediaIds();
      mediaStream = EasyRtcService.getLocalStream(mediaIds[0]);
    }
    this.localUser = localUser || this.localUser;
    if (mediaStream) {
      this.connectAnalyserNode(mediaStream);
    }
  }

  private connectAnalyserNode(mediaStream) {
    this.audioContext = new AudioContext();
    const mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(mediaStream);
    this.analyserNode = this.audioContext.createAnalyser();
    mediaStreamAudioSourceNode.connect(this.analyserNode);
    this.pcmData = new Float32Array(this.analyserNode.fftSize);
  }

  private calculateSumSquares() {
    if (BotService.isBot) {
      return;
    }
    if (!this.analyserNode) {
      return;
    }
    this.analyserNode.getFloatTimeDomainData(this.pcmData);
    let sumSquares = 0.0;
    for (const amplitude of this.pcmData) {
      sumSquares += amplitude * amplitude;
    }
    this.localAudioLevel = Math.sqrt(sumSquares / this.pcmData.length);
  }

  private getSpeakerTime(userName: string) {
    this.timer++;
    if (this.speakerTimeData[userName]) {
      this.speakerTimeData[userName].timer = this.timer;
      this.speakerTimeData$.next(this.speakerTimeData);
      const speakerData = JSON.stringify(this.speakerTimeData);
      localStorage.setItem('speakerData', speakerData);
    }
    if (this.localMicTimeData[userName]) {
      this.localMicTimeData[userName].timer = this.timer;
      this.localMicTimeData$.next(this.localMicTimeData);
      const localMicData = JSON.stringify(this.localMicTimeData);
      localStorage.setItem('localMicData', localMicData);
    }
    if (this.hours > 0) {
      return `${this.hours}h ${this.minutes}m ${this.seconds}s`;
    }
    return `${this.minutes}m ${this.seconds}s`;
  }

  resetSpeaker() {
    this.timer = 0;
    this.speakerTimeData$.next({});
    this.speakerTimeData = {};
    this.localMicTimeData$.next({});
    this.localMicTimeData = {};
    if (this.audioContext && this.audioContext.state !== "closed") {
    this.audioContext?.close();
    }
    this.localAudioLevel = 0;
    localStorage.removeItem('speakerData');
    localStorage.removeItem('localMicData');
  }

}
