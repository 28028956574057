
export class LocalStorageUtil {

  public static setItem(name: string, value: string | null): void {
    if (!!name) {
      if (!!value) {
        window.localStorage.setItem(name, value);
      } else {
        window.localStorage.removeItem(name);
      }
    }
  }

  public static getItem(name: string): string | null {
    return (!!name ? window.localStorage.getItem(name) : null);
  }

}