import { Injectable } from '@angular/core';
import watchRTC from '@testrtc/watchrtc-sdk';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WatchrtcService {

  constructor() { }

 // initialize watchRTC

init(): void{
    if (environment.ENV !== 'local') {
        watchRTC.init();
      }
    else {
        console.log('watchRTC not initialized in local environment');
    }
}

trackEvent(eventName: string, parameters):void {
    watchRTC.addEvent({
        name: eventName,
        type: "local",
        parameters: { parameters},
      });
    }

}