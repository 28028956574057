import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class AudioContextService {
	private audioContext: AudioContext;
	static count = 0;
	constructor() {}

	public getAudioContext(): AudioContext {
		if (!this.audioContext || this.audioContext?.state === "closed") {
			(window as any).AudioContext =
				window.AudioContext || (window as any).webkitAudioContext;
			this.audioContext = new (window as any).AudioContext();
		}
		if (this.audioContext?.state === "suspended") {
			this.audioContext.resume();
		}
		return this.audioContext;
	}

	public closeAudioContext(): void {
		if (this.audioContext) {
			this.audioContext.close();
			this.audioContext = null;
		}
	}
}
