<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
    class="flex w-full justify-end h-[32px] items-center bg-[#F0F0F0] hover:cursor-move">
    <mat-icon class="!text-md text-offblack mr-1 hover:cursor-pointer hover:text-offblack-3"
        (click)="close()">close</mat-icon>
</div>
<mat-dialog-content>
    <div class="content text-text-1">
        <form [formGroup]="eventForm" class="text-offblack">
            <div class="flex gap-4">
                <!-- <button class="tab-button transition-colors" [class.selected]="eventType === 'lesson'" (click)="setEventType('lesson')">
					{{ "lessons.regular-lesson" | translate }}
				</button> -->
                <!-- <button class="tab-button transition-colors" [class.selected]="eventType === 'other'" (click)="setEventType('other')">
					{{ "calendar.other-event" | translate }}
				</button> -->
                <h2 class="temp-title">
                    {{ "lessons.regular-lesson" | translate }}
                </h2>
            </div>
            @if( eventType === 'lesson' ){
            <div class="mt-[20px] row">
                <span class="mr-[8px] mt-[6px] text-text-2">
                    <mat-icon svgIcon="calendar-person"></mat-icon>
                </span>
                <mat-form-field class="wide">
                    <mat-select formControlName="student" placeholder="{{ 'lessons.student-select-title' | translate }}"
                        disableOptionCentering="true">
                        <mat-option *ngFor="let student of studentList" [value]="student.id">{{ student.name
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="lessonLength" class="row">
                <span class="mr-[8px] text-text-2">
                    <mat-icon svgIcon="time"></mat-icon>
                </span>
                <span class="pt-[3px] pl-[16px]"> {{ lessonLength }} {{ "lessons.minute-music-lesson" | translate }}
                </span>
            </div>
            <hr class="mt-2 mb-4" />
            }
            <!-- @if( eventType === 'other' ){
			<div class="mt-[20px]">
				<mat-form-field>
					<input matInput placeholder="Title" formControlName="title" />
				</mat-form-field>
			</div>
			} -->
            <div class="flex time-container">
                <span class="mr-[8px] mt-[6px] text-text-2">
                    <mat-icon svgIcon="clock"></mat-icon>
                </span>
                <mat-form-field class="hover:cursor-pointer date-picker-field" (click)="openCalendar()">
                    <input matInput [min]="minDate" [matDatepicker]="picker" placeholder="Choose a date"
                        formControlName="date" />
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <!-- <span class="pt-[2px]">·</span> -->
                <mat-form-field class="time-picker-field">
                    <mat-select formControlName="startTime" disableOptionCentering="true" panelWidth="">
                        <mat-option *ngFor="let s of defaultSlots" [value]="s.slot">{{ s.slot }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <span class="pt-[8px]">-</span>
                <mat-form-field class="time-picker-field">
                    <mat-select formControlName="endTime" disableOptionCentering="true" panelWidth="">
                        <mat-option *ngFor="let s of defaultSlots" [value]="s.slot">{{ s.slot }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row">
                <span class="mr-[8px] mt-[6px] text-text-2">
                    <mat-icon svgIcon="repeat"></mat-icon>
                </span>
                <mat-form-field class="wide">
                    <mat-select formControlName="repeat" disableOptionCentering="true">
                        <mat-option *ngFor="let r of repeatTypeList" [value]="r.value">{{ r.title | translate
                            }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- @if( eventType === 'other' ){
			<div class="row">
				<section>
					<mat-checkbox color="primary" disableRipple="true" class="s-mat-checkbox new" formControlName="override">
						{{ "lessons.add-override" | translate }}?
					</mat-checkbox>
				</section>
			</div>
			} -->
        </form>
    </div>
    <div class="w-full flex justify-end text-text-1">
        <button [disabled]="!isFormValid" class="save-button transition-colors" (click)="save()">
            {{ "buttons.save" | translate }}
        </button>
    </div>
</mat-dialog-content>