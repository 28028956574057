import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/languages/language.service";

// to do - move to helpers and use elsewhrere
// export function isStudentName(name) {
// 	return name.trim() && name !== "null null" && name.trim() !== "null";
// }
@Component({
	selector: "app-student-info",
	templateUrl: "./student-info.component.html",
	styleUrls: ["./student-info.component.scss"],
	standalone: true,
	imports: [CommonModule, TranslateModule]
})
export class StudentInfoComponent {
	name: string = "";
	email: string = "";
	date: string = "";
	time: string = "";
	instruments = "";
	genre = "";
	ageGroup = "";
	favouriteSong = "";
	notesForTeacher = "";
	skillLevel = "";

	constructor(
		@Inject(MAT_DIALOG_DATA)
		private data: {
			date: string;
			time: any;
			student: any;
		},
		private dialogRef: MatDialogRef<StudentInfoComponent>,
		private translateService: TranslateService,
		private languageService: LanguageService
	) {
		this.date = data.date;
		this.time = data.time;
		this.name = data.student.name;
		this.instruments = this.languageService.translateArray(
			"instruments",
			data.student.studentProfile?.instruments?.map((i) => i.instrument)
		);

		const genres = data.student.studentProfile?.instruments
			?.map((i) => i.genre)
			?.flat();

		const genresSetString = [...new Set(genres)] as string[];

		this.genre = this.languageService.translateArray(
			"musicGenres",
			genresSetString
		);
		this.ageGroup = data.student.studentProfile?.ageGroup;
		this.favouriteSong = data.student.studentProfile?.favoriteSong;
		this.notesForTeacher = data.student.studentProfile?.notes;
		this.skillLevel = data.student.studentProfile?.skillLevel;
	}

	close() {
		this.dialogRef.close();
	}
}
