"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var async_1 = require("../scheduler/async");
var map_1 = require("./map");
function timestamp(scheduler) {
  if (scheduler === void 0) {
    scheduler = async_1.async;
  }
  return map_1.map(function (value) {
    return new Timestamp(value, scheduler.now());
  });
}
exports.timestamp = timestamp;
var Timestamp = function () {
  function Timestamp(value, timestamp) {
    this.value = value;
    this.timestamp = timestamp;
  }
  return Timestamp;
}();
exports.Timestamp = Timestamp;
