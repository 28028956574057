import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { RTC_ICE_CANDIDATE } from '../../../../constants/connection-constants';
import { RtcScreenShareCtrl } from './rtc-screen-share-ctrl.service';
import { StreamViewModel } from './stream-view-model';
import { CAM_1, CAM_2, SCREEN_SHARE } from '../../data/rtc-constants';
import { SELF_VIDEO } from '../../data/local-constants';
import { StreamSettingsStorage } from '../storage/rtc-storage.service';
import { StateVO } from '../../data/StateVO';
import { UserVO } from '../../../../services/data/UserVO';
import { CallStorage } from '../storage/call-storage.service';
import { UsersService } from '../../../../services/users.service';
import { Room } from '../../component/call-room';
import { SNACKBAR_DURATION } from '../../../../constants/generic.constants';
import { RoomStateStorage } from '../storage/RoomStateStorage';
import { DisplayStatus } from '../storage/DisplayStatusVO';
import { logger } from 'src/app/lib-core/logger';
import { first } from 'rxjs/internal/operators/first';
import { RTC_WS_URL } from 'src/app/lib-core/constants/constants';
import { PlatformDetectorService } from '../../../../services/platform-detector/platform-detector.service';
import { EasyRtcService } from '../../../../lib-rtc/services/easy-rtc.service';
import { VideoSettingsService } from '../../../../video-settings/service/video-settings.service';
import { StorageUtil } from '../../../../utils/storage.util';
import { EventSocketService } from '../../../../services/ws/event-socket.service';
import { PlayerStateService } from '../../../../services/youtube/player-state.service';


@Injectable({
  providedIn: 'root'
})
export class RtcStreamViewModel implements StreamViewModel {
  private room: Room; // todo remove
  private subscribes = new Array<Subscription>();
  // todo remove;
  private usersInCall = new Array<string>();
  private isIosInit = new Array<string>();
  roomName: string;
  public get screenShareStream(): MediaStream { return this.screenShareCtrl.currentVideo; }
  private callIsMade = false;

  constructor(
    private screenShareCtrl: RtcScreenShareCtrl, // just init screen share functional, now it autonomic
    private callStorage: CallStorage,
    private usersService: UsersService,
    private roomState: RoomStateStorage,
    private settingsStorage: StreamSettingsStorage,
    private platformDetectorService: PlatformDetectorService,
    private videoSettingsService: VideoSettingsService,
    private eventSocketService: EventSocketService,
    private playerStateService: PlayerStateService
  ) {
    EasyRtcService.setSocketUrl(RTC_WS_URL);
    EasyRtcService.setIceUsedInCalls(RTC_ICE_CANDIDATE);
    EasyRtcService.setSdpFilters();
    EasyRtcService.setUseFreshIceEachPeerConnection(true);
  }
  async connect(roomName: string, userName: string, room: Room): Promise<boolean> {
    if (EasyRtcService.supportsRecording()) {
      EasyRtcService.setRecordingVideoCodec('vp8');
    }

    this.roomName = roomName;
    this.room = room;
    const browser = this.platformDetectorService.deviceInfo.browser;
    if (browser && browser !== 'Firefox') {
      let permissions = await this.videoSettingsService.checkPermissions().toPromise();
      if (!permissions.cam) {
        if (this.platformDetectorService.isBrowserSafari() && this.platformDetectorService.getSafariVersion() >= 16) {
          await this.videoSettingsService.getDevPerm();
          await this.videoSettingsService.checkPermissions().toPromise();
        }
        this.usersService.selfUser.isCamera = false;
        this.roomState.isCamOn = false;
        StorageUtil.writeMediaPrmsToSessionStorage({ isCam: false });
        this.eventSocketService.turn('camera', false);
      }
    }
    const localStream = await this.settingsStorage.refreshMedia();
    logger.log(`easyrtc.connect(user.id="${this.usersService.selfUser.id}")`);

    const isRtcConnect: boolean = await EasyRtcService.connect(roomName, userName);
    if (isRtcConnect) { // todo refactor code down
      this.usersInCall = [];
      this.usersService.changeUsers();
      this.settingsStorage.currentStream = null;
      this.initObservers();
      this.initERtcObservers();
      this.room.updateById(SELF_VIDEO, localStream);
      this.usersService.selfUser.mediaStream = localStream;
      if (!this.usersService.selfUser.isMicro) {
        this.enableMicrophone(false);
      }
      if (!this.usersService.selfUser.isCamera) {
        this.enableCamera(false);
      }
      return true;
    } else {
      return false;
    }
  }
  enableMicrophone(value: boolean): void {
    EasyRtcService.enableMicrophone(value, EasyRtcService.getLocalMediaIds()[0]);
  }
  enableCamera(value: boolean): void {
    const iosInfo = this.platformDetectorService.getIOS_version();
    if (!iosInfo.User_Agent || !iosInfo.User_Agent.toLowerCase().search('safari')) {
      EasyRtcService.enableCamera(value, EasyRtcService.getLocalMediaIds()[0]);
    }
  }
  disconnect() {
    EasyRtcService.hangupAll();
    EasyRtcService.disconnect();
    const mediaIds: any = EasyRtcService.getLocalMediaIds();
    if (mediaIds && mediaIds.length > 0) {
      Object.entries(mediaIds).map((streamName) => {
        if (typeof streamName === 'string') {
          EasyRtcService.closeLocalStream(streamName[1]);
        }
      });
    }
    this.screenShareCtrl.disconnect();
    this.unsubscribe();
  }

  private initERtcObservers() {
    const acceptCheckResult: any = EasyRtcService.setAcceptChecker();
    acceptCheckResult((easyrtcId, onAskAccess) => {
      this.onAskAccess(easyrtcId, onAskAccess);
    });
    EasyRtcService.setRoomOccupantListener(this.onRoomOccupantCome);

    // Sets a callback to receive media streams from other peers, independent of where the call was initiated.
    EasyRtcService.setStreamAcceptor(this.onStreamAccepted);
    // Sets a callback to receive notification of a media stream closing.
    EasyRtcService.setOnStreamClosed((easyrtcId, mediaStream, streamName) => {
      if (streamName == "secondStream") {
        const userId = EasyRtcService.idToName(easyrtcId);
        const userVO: UserVO = this.usersService.findUserById(userId);
        userVO.secondStreamActive = false;
        userVO.mediaStreamSecondVideo = null;
      }
      const pc = EasyRtcService.getPeerConnectionByUserId(easyrtcId);
      const removedStream = pc.getRemoteStreams().find(s => s.streamName === streamName);
      if (removedStream) {
        pc.removeStream(removedStream);
        if (pc._remoteStreams) {
          pc._remoteStreams = pc._remoteStreams.filter(s => {
            return s.streamName !== streamName;
          });
        }
      }
    });
    const config = new MatSnackBarConfig();
    config.duration = SNACKBAR_DURATION;
    const setDisconnectListener: any = EasyRtcService.setDisconnectListener();
    const setPeerFailingListener: any = EasyRtcService.setPeerFailingListener();
    setDisconnectListener(() => {
      logger.log('ERROR easyrtc.setDisconnectListener');
      // todo uncommit
      // this.snackBar.open('Lost connection to the socket server', 'Close', config);
    });
    setPeerFailingListener(easyrtcid => {
      logger.log('disconnect', easyrtcid, EasyRtcService.idToName(easyrtcid));
    }, easyrtcid => {
      logger.log('recover', easyrtcid, EasyRtcService.idToName(easyrtcid));
    });
  }
  private initObservers() {
    if (this.subscribes.length > 0) {
      this.unsubscribe();
    }
    this.subscribes.push(this.roomState.isMicroOn$.subscribe(value => {
      EasyRtcService.enableMicrophone(value, EasyRtcService.getLocalMediaIds()[0]);
    }));
    this.subscribes.push(this.roomState.isCamOn$.subscribe((value) => {
      const iosInfo = this.platformDetectorService.getIOS_version();
      if (!iosInfo.User_Agent || !iosInfo.User_Agent.toLowerCase().search('safari')) {
        EasyRtcService.enableCamera(value, EasyRtcService.getLocalMediaIds()[0]);
      }
    }));
    // this.subscribes.push(this.settingsStorage.selectedMediaId$.subscribe(this.onSelectMediaId));
    this.subscribes.push(this.settingsStorage.selectedChanel$.subscribe(this.onSelectedChanel));
    this.screenShareCtrl.listen();
  }
  private unsubscribe() {
    this.subscribes.map((subscribe) => subscribe.unsubscribe());
    this.subscribes = [];
  }
  async changeSelectedStream(params: MediaStreamConstraints) {

    if (this.roomState.isScreenShare === DisplayStatus.inactive) {
      try {
        const newStreamName = this.createStreamName();
        const prevStreamName = EasyRtcService.getLocalMediaIds().find(s => s !== newStreamName && s !=="secondStream");
        const newStream = await EasyRtcService.initMediaSourceByBrowserSupport(params, newStreamName);
        EasyRtcService.enableMicrophone(this.usersService.selfUser.isMicro, newStreamName);
        const iosInfo = this.platformDetectorService.getIOS_version();
        if (!iosInfo.User_Agent || !iosInfo.User_Agent.toLowerCase().search('safari')) {
          EasyRtcService.enableCamera(this.usersService.selfUser.isCamera, newStreamName);
        }
        this.settingsStorage.currentStream = newStream;
        this.usersService.selfUser.mediaStream = newStream;
        if (prevStreamName) {
          const renegotiate: any = EasyRtcService.renegotiate();
          for (const userRtcId of this.usersInCall) {
            EasyRtcService.addStreamToCall(userRtcId, newStreamName, () => {
              renegotiate(userRtcId);
              EasyRtcService.removeStreamFromCall(userRtcId, prevStreamName);
            });
          }
          EasyRtcService.closeLocalStream(prevStreamName);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }
  private onSelectedChanel = (data: StateVO) => {
    if (this.callStorage.usersStorage.users.filter(userItem => userItem.connected && userItem.rtcId).length > 0) {
      this.callStorage.usersStorage.users.filter(i => i.connected).map((user: UserVO) => {
        EasyRtcService.addStreamToCall(user.rtcId, data.current, () => {
          EasyRtcService.removeStreamFromCall(user.rtcId, data.prev);
        });
      });
    }
    EasyRtcService.closeLocalStream(data.prev);
  }
  // todo replace
  private createStreamName(): string {
    let name = CAM_1;
    if (this.roomState.isScreenShare !== DisplayStatus.inactive) {
      name = SCREEN_SHARE;
    } else {
      const localMediaIds: any = EasyRtcService.getLocalMediaIds();
      const value = Object.entries(localMediaIds).toString();
      if (value.includes(CAM_1)) { name = CAM_2; }
      if (value.includes(CAM_2)) { name = CAM_1; }
      if (value.includes(SCREEN_SHARE)) { name = CAM_1; }
    }
    return name;
  }
  private onAskAccess(askUserId: string, callback): void {
    const screenName = EasyRtcService.getLocalMediaIds()[0];
    let user = this.usersService.findUserById(EasyRtcService.idToName(askUserId));
    if (user) {
      callback(true, screenName);
    } else {
      this.usersService.changeUserList$.pipe(first()).subscribe(() => {
        user = this.usersService.findUserById(EasyRtcService.idToName(askUserId));
        if (user) {
          callback(true, screenName);
        } else {
          logger.log('not found user', askUserId, EasyRtcService.idToName(askUserId));
        }
      });
    }
  }
  // Sets a callback to receive media streams from other peers, independent of where the call was initiated.
  private onStreamAccepted = (easyrtcid: string, stream: MediaStream, streamName: string) => {

    if (streamName=="secondStream"){
        const userId = EasyRtcService.idToName(easyrtcid);
        const userVO: UserVO = this.usersService.findUserById(userId);
        userVO.secondStreamActive = true;
        userVO.mediaStreamSecondVideo = stream;
    }

    else {

        EasyRtcService.setEasyRtcId = easyrtcid;
        const user: UserVO = this.getUser(easyrtcid);
        const userId = EasyRtcService.idToName(easyrtcid);
        const userVO: UserVO = this.usersService.findUserById(userId);

        // const audioContext = new (window.AudioContext);
        // const audioStream = new MediaStream();

        // const mutedAudio = new Audio();
        // mutedAudio.muted = true;
        // mutedAudio.srcObject = audioStream;

        // stream.getAudioTracks().forEach(track => audioStream.addTrack(track));

        // const audioSource = audioContext.createMediaStreamSource(audioStream);
        // const gainNode = audioContext.createGain();
        // const streamAudio = audioContext.createMediaStreamDestination()

        // gainNode.gain.value = 1; 
        // audioSource.connect(gainNode);
        // gainNode.connect(streamAudio);
        // let audioTrack = stream.getAudioTracks()[0];
        // stream.removeTrack(audioTrack);
        // stream.addTrack(streamAudio.stream.getAudioTracks()[0]);

        if (!!userVO) {
        userVO.mediaStream = stream;
        userVO.streamName = streamName;   
        }

        user.streamName = streamName;
        if (this.room.getVideoById(easyrtcid) === null) { // todo refactor and delete getVideoById;
        this.room.createNewVideo(easyrtcid);
        }

    logger.log(`onStreamAccepted success by user: ${user.rtcId}, with name: ${user.name}, and streamName: ${streamName}`);

    // Add second video cam if active
    if (this.usersService.selfUser.secondStreamActive) {
        EasyRtcService.addStreamToCall(user.rtcId, "secondStream");
    }

    this.room.updateById(easyrtcid, stream, true);

    if (!this.usersInCall.includes(user.rtcId)) {
      this.usersInCall.push(user.rtcId);
      const videoId = localStorage.getItem('videoId');
      if (videoId) {
        let currentTime;
        this.playerStateService.playerEvent.forEach((ev) => {
          if (currentTime !== 0) {
            currentTime = ev.getCurrentTime();
          }
        });
        this.eventSocketService.notify(this.callStorage.roomId, {youtube: {
            videoId,
            event: 'enter',
            userId: user.id,
            currentTime: currentTime || 0
          }});
      }
    }

    const iosRemovePeerConnectionHandler = () => {
      const removedStreamName = streamName === CAM_1 ? SCREEN_SHARE : CAM_1;
      const pc = EasyRtcService.getPeerConnectionByUserId(easyrtcid);
      const removedStream = pc.getRemoteStreams().find(s => s.streamName === removedStreamName);
      if (removedStream) {
        pc.liveRemoteStreams = { removedStreamName: true };
        pc.onremovestream({ stream: removedStream });
      }
    };

    // @ts-ignore
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    const isFirefox = this.platformDetectorService.isFirefox();
    if (!isChrome && this.isIosInit.includes(easyrtcid) && !isFirefox) {
      iosRemovePeerConnectionHandler();
    } else {
      this.isIosInit.push(easyrtcid);
    }
    }
  }

  private onRoomOccupantCome = (roomName: string, newCallers: any, called? :boolean) => {
    if (!this.callIsMade){
    this.callToNewUsers(newCallers);
    }
    this.callIsMade = true;
  }
  // todo add type
  // todo add global states
  private callToNewUsers = (newUsers) => {
    // This function is used to call other participants after joining the room. It is used once, and is triggered by callback function.
    Object.entries(newUsers).map((userInfo) => {
      const user = this.getUser(userInfo[0]);
      if (!this.usersInCall.includes(user.rtcId)) {
        this.usersInCall.push(user.rtcId);
        EasyRtcService.call(user.rtcId); // user[0]= userID
      }
    });
  }
  private getUser(rtcId: string): UserVO {
    let user = this.callStorage.usersStorage.getUser(EasyRtcService.idToName(rtcId));
    if (!user) { // it hack for prod mode
      user = new UserVO();
      user.id = EasyRtcService.idToName(rtcId);
      this.callStorage.usersStorage.updateUser(user);
    }
    if (user.rtcId !== rtcId) {
      user.rtcId = rtcId;
      this.callStorage.usersStorage.onUsersUpdate$.next(true);
    }

    return user;
  }
}

