import { MiddlePanelMode, MiddlePanelModeUtil, QualityRating } from '../lm-quality-rating.constants';

const PANEL_MODE = 'rating_PanelMode';
const QUALITY_RATING = 'rating_QualityRating';
const PROBLEM_OPTIONS = 'rating_ProblemOptions';

export class QualityRatingStorageUtil {

  public static writeToSessionStorage(name: string, value: string | null): void {
    if (!!name) {
      if (!!value) { // update
        sessionStorage.setItem(name, value.toString());
      } else { // remove
        sessionStorage.removeItem(name);
      }
    }
  }

  // MiddlePanelMode

  public static writePanelModeToSessionStorage(value: string | null): void {
    QualityRatingStorageUtil.writeToSessionStorage(PANEL_MODE, value);
  }
  public static readPanelModeFromSessionStorage(): string | null {
    return sessionStorage.getItem(PANEL_MODE);
  }
  public static cleanPanelModeToSessionStorage(): void {
    const value: string = sessionStorage.getItem(PANEL_MODE);
    if (!!value) {
      QualityRatingStorageUtil.writePanelModeToSessionStorage(null);
    }
  }

  // QualityRating

  public static writeQualityRatingToSessionStorage(value: string | null): void {
    QualityRatingStorageUtil.writeToSessionStorage(QUALITY_RATING, value);
  }
  public static readQualityRatingFromSessionStorage(): string {
    return sessionStorage.getItem(QUALITY_RATING);
  }

  // ProblemOptions

  public static writeProblemOptionsToSessionStorage(value: string | null): void {
    QualityRatingStorageUtil.writeToSessionStorage(PROBLEM_OPTIONS, value);
  }
  public static readProblemOptionsFromSessionStorage(): string {
    return sessionStorage.getItem(PROBLEM_OPTIONS);
  }

  public static cleanRatingParamsStorage(): void {
    QualityRatingStorageUtil.writeQualityRatingToSessionStorage('');
    QualityRatingStorageUtil.writeProblemOptionsToSessionStorage('');
    QualityRatingStorageUtil.writePanelModeToSessionStorage('');
  }

}
