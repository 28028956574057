<div class="mr-10 md:block">
  <!-- hover / click behaviour can be changed -->
  <button
    class="p-1 pl-2 w-25 md:w-20 text-black text-base md:text-xs bg-white flex items-center mt-0 md:mt-4 justify-between"
    *ngIf="selectedLanguage" (click)="showLanguageList = !showLanguageList">
    <span> {{ selectedLanguage.name }}</span>
    <span class="material-symbols-outlined">
      expand_more
    </span>
  </button>
  <ul *ngIf="selectedLanguage" class="language-list w-24 box-border p-1" [hidden]="!showLanguageList">
    <li class="text-base md:text-xs text-black p-1 pl-2 cursor-pointer hover:bg-gray-100"
      [ngClass]="selectedLanguage._id === language._id? 'active bg-gray-200' : ''" *ngFor="let language of languages"
      (click)="onSelect(language._id)">
      {{ language.name }}
      <!-- horrible tick is just placeholder!!! -->
      <!-- <span *ngIf="selectedLanguage._id === language._id">✔</span> -->
    </li>
  </ul>
</div>