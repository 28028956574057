"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var isArray_1 = require("../util/isArray");
var race_1 = require("../observable/race");
function race() {
  var observables = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    observables[_i] = arguments[_i];
  }
  return function raceOperatorFunction(source) {
    if (observables.length === 1 && isArray_1.isArray(observables[0])) {
      observables = observables[0];
    }
    return source.lift.call(race_1.race.apply(void 0, [source].concat(observables)));
  };
}
exports.race = race;
