import {
	Component,
	EventEmitter,
	Inject,
	OnInit,
	Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Profile } from "src/app/types/profile.types";
import { ProfileService } from "src/app/profile/profile.service";
import { AuthenticationService } from "src/app/services/authentication/authentication.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SubRole } from "src/app/constants/subroles-constants";
import { GENRES, INSTRUMENTS } from "src/app/profile/profile.constants";
@Component({
	selector: "app-music-modal",
	templateUrl: "./music-modal.component.html",
	styleUrls: ["./music-modal.component.scss"],
})
export class MusicModalComponent implements OnInit {
	@Output() onSave = new EventEmitter();
	@Output() onSkip = new EventEmitter();
	instrumentsOptions = INSTRUMENTS;
	genresOptions = GENRES;
	form: FormGroup;
	subrole: string;
	get fields(): string[] {
		return Object.keys(this.form.controls);
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public profile: Profile,
		private formBuilder: FormBuilder,
		private profileService: ProfileService,
		private dialogRef: MatDialogRef<MusicModalComponent>,
		private authenticationService: AuthenticationService
	) {
		this.form = this.formBuilder.group({
			instruments: [[], Validators.required],
			musicGenres: [[], Validators.required],
		});
		this.subrole =
			this.profile.subrole === SubRole.STUDENT_MATCHED
				? "student"
				: "teacher";
	}

	ngOnInit(): void {
		this.updateForm();
	}

	private updateForm(): void {
		this.form.patchValue({
			instruments: this.profile.instruments,
			musicGenres: this.profile.musicGenres,
		});
	}

	onClickSave(): void {
		this.profileService.save(this.form.value).subscribe(() => {
			this.updateForm();
			this.onSave.emit();
			this.dialogRef.close();
		});
	}
	onClickSkip(): void {
		this.onSkip.emit();
		this.dialogRef.close();
	}
}
