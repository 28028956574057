import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent implements OnInit {

  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) public warning: string,
              public translate: TranslateService) {
    this.message = warning;
  }

  ngOnInit() {
  }

}
