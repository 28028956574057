import { Component, Input } from "@angular/core";

@Component({
	selector: "app-empty-list-container",
	standalone: true,
	imports: [],
	templateUrl: "./empty-list-container.component.html",
	styleUrl: "./empty-list-container.component.scss"
})
export class EmptyListContainerComponent {
	@Input() message: string;
}
