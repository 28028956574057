export const SUBROLES = {
	0: "none",
	1: "organisationTeacher",
	2: "teacherSelf",
	3: "teacherMatched",

	// not in use
	4: "studentSelf",
	//

	5: "studentLead",
	6: "studentMatched",
	7: "studentExternal"
};

export enum SubRole {
	NONE = 0,
	ORGANISATION_TEACHER = 1,
	TEACHER_SELF = 2,
	TEACHER_MATCHED = 3,

	// not in use
	STUDENT_SELF = 4,
	//

	STUDENT_LEAD = 5,
	STUDENT_MATCHED = 6,
	STUDENT_EXTERNAL = 7
}

export const isStudentSubrole = (subrole: SubRole) => {
	return (
		subrole === SubRole.STUDENT_SELF ||
		subrole === SubRole.STUDENT_LEAD ||
		subrole === SubRole.STUDENT_MATCHED ||
		subrole === SubRole.STUDENT_EXTERNAL
	);
};
