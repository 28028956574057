import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ButtonType } from "../../../call-room/data/ButtonType";
import { TranslateService } from "@ngx-translate/core";
import { LessonsService } from "../../../../services/lessons/lessons.service";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { co } from "@fullcalendar/core/internal-common";
// import { Student } from "src/app/students/students.service";

//** Component which shows an data overview before the final lesson booking */

export enum RequestType {
	ADD = "add",
	UPDATE = "update"
}

export type ConfirmData = {
	student: { name: string };
	date: string;
	timeZone: string;
	duration: string;
	title: string;
	requestType: RequestType;
	target?: string;
	repeatType?: string;
	bookingId?: string;
};

@Component({
	selector: "app-confirm-lesson",
	templateUrl: "./confirm-lesson.component.html",
	styleUrls: [
		"./confirm-lesson.component.scss",
		"./confirm-lesson.component.tablet.scss",
		"./confirm-lesson.component.mobile.scss"
	]
})
export class ConfirmLessonComponent implements OnInit {
	cancelButtonData: { text: string; callBack?: () => {} } = { text: "" };
	okButtonData: { text: string; callBack?: (dialog?: MatDialog) => {} } = {
		text: ""
	};
	lessonTimeInterval: string;
	duration: string;
	target: string;
	title: string;
	timeZone: string;
	disableConfirm = false;
	repeatType: string;
	successfullBooking = false;
	bookingExistError = false;
	bookingGeneralError = false;
	bookingErrorText = null;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		private selectedData: {
			confirmData: ConfirmData;
			buttons: { type: ButtonType; text: string; callBack?: () => {} }[];
			bookingData: any;
		},
		private dialog: MatDialog,
		private translateService: TranslateService,
		private lessonsService: LessonsService
	) {}

	get studentName(): string {
		return this.selectedData?.confirmData?.student?.name;
	}

	get isRequestTypeUpdate(): boolean {
		return this.selectedData?.confirmData?.requestType == RequestType.UPDATE
			? true
			: false;
	}

	ngOnInit() {
		const startDate = new Date(this.selectedData?.confirmData?.date);
		this.timeZone = this.selectedData?.confirmData.timeZone;
		this.lessonTimeInterval = this.getLessonTimeInterval(startDate);
		this.duration = this.selectedData?.confirmData.duration;
		this.target = this.selectedData?.confirmData.target;
		this.title = this.selectedData?.confirmData.title;
		this.repeatType = this.selectedData?.confirmData.repeatType;

		const okButtons = this.selectedData.buttons.filter(
			(i) => i.type === ButtonType.OK
		);
		if (okButtons.length > 0) {
			this.okButtonData = {
				text: okButtons[0].text,
				callBack: okButtons[0].callBack
			};
		}
		const cancelButtons = this.selectedData.buttons.filter(
			(i) => i.type === ButtonType.CANCEL
		);
		if (cancelButtons.length > 0) {
			this.cancelButtonData = {
				text: cancelButtons[0].text,
				callBack: cancelButtons[0].callBack
			};
		}
	}
	onCancelClick() {
		if (this.cancelButtonData.callBack) {
			this.cancelButtonData.callBack();
		}
	}
	onOkClick() {
		if (this.selectedData.confirmData.requestType == RequestType.ADD) {
			this.disableConfirm = true;
			let startTime = new Date(this.selectedData.bookingData.startTime);
			let endTime = new Date(this.selectedData.bookingData.endTime);
			startTime.setSeconds(0);
			endTime.setSeconds(0);
			this.selectedData.bookingData.startTime = startTime.toISOString();
			this.selectedData.bookingData.endTime = endTime.toISOString();

			this.lessonsService
				.addBooking(this.selectedData.bookingData)
				.pipe(
					catchError((error) => {
						return throwError(error);
					})
				)
				.subscribe(
					() => {
						this.successfullBooking = true;
						this.lessonsService.updateBookingList$.next();
						setTimeout(() => {
							this.dialog.closeAll();
						}, 2000);
					},
					(httpError) => {
						// Check if the error description contains "already exist"
						if (
							httpError.error.description.includes(
								"already exist"
							)
						) {
							this.bookingExistError = true;
						}
                        else if (
                            httpError.error.description.includes(
                                "overlapping"
                            ))
                            {
                                this.bookingGeneralError = true;
                                const dateRegex = /\d{4}-\d{2}-\d{2}/g;
                                const dates = httpError.error.description.match(dateRegex);
                                const [year, month, day] = dates[0].split("-");
                                const formattedDate = `${day}.${month}.${year}`;
                                this.bookingErrorText = this.translateService.instant("lessons.booking-overlap-err") + formattedDate;
                            }
                         else {
							this.bookingGeneralError = true;
							this.bookingErrorText = httpError.error.description;
						}
					}
				);
		}

		if (this.selectedData.confirmData.requestType == RequestType.UPDATE) {
			this.disableConfirm = true;
			this.lessonsService
				.updateBooking(
					this.selectedData.confirmData.bookingId,
					this.selectedData.bookingData
				)
				.pipe(
					catchError((error) => {
						return throwError(error);
					})
				)
				.subscribe(
					() => {
						// this.successfullBooking = true;
						this.lessonsService.updateBookingList$.next();
						this.dialog.closeAll();
					},
					(httpError) => {
						// Check if the error description contains "already exist"
						if (
							httpError.error.description.includes(
								"already exist"
							)
						) {
							this.bookingExistError = true;
						} else {
							this.bookingGeneralError = true;
							this.bookingErrorText = httpError.error.description;
						}
					}
				);
		}
	}

	getLessonTimeInterval(date: Date): string {
		const selectedLanguage = this.translateService.currentLang;
		const dateLocal = selectedLanguage === "eng" ? "en-US" : "de-DE";
		const options: Intl.DateTimeFormatOptions = {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric"
		};
		const fullDate = date.toLocaleDateString(dateLocal, options);
		const startTime = new Date(date.getTime()).toLocaleString(dateLocal, {
			hour: "2-digit",
			minute: "2-digit",
			hour12: false
		});
		let endDate = date;
		endDate.setMinutes(
			endDate.getMinutes() +
				parseInt(this.selectedData?.confirmData.duration)
		);
		const endTime = new Date(endDate.getTime()).toLocaleString(dateLocal, {
			hour: "2-digit",
			minute: "2-digit",
			hour12: false
		});
		return `${startTime} – ${endTime} <br>${fullDate}`;
	}
}
