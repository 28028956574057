<div class="popup-outer-container">
  <div class="popup-inner-container">
    <img class="warning-icon" src="../../assets/icons/Warning-orange.svg" alt="">
    <p class="info-text">{{ 'roomFull.alert' | translate }}</p>
    <button class="default-ui-button blue-ui-button cancel-btn" (click)="close()">
      {{ 'buttons.cancel' | translate }}
    </button>
  </div>
</div>

