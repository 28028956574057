<h1 mat-dialog-title>{{ "material.rename-file-title" | translate }}</h1>
<mat-list>
	<mat-dialog-content>
		<div mat-subheader>File</div>
		<mat-list-item>
			<mat-icon matListItemIcon>description</mat-icon>
			<div matListItemTitle>{{ data.fileData.name }}</div>
			<!-- <div matListItemLine>{{file.updated | date}}</div> -->
		</mat-list-item>

		<label
			>{{ "material.rename-file-message" | translate }}
			<input
				type="text"
				name="newName"
				[(ngModel)]="name"
				#input="ngModel"
				required
				pattern="^[a-zA-Z0-9\-_]{1,60}$"
				maxlength="50"
				placeholder="NewFileName..."
				autocomplete="off"
			/>
		</label>
		<mat-dialog-actions>
			<button class="error-bttn" (click)="onClose()">
				{{ "buttons.cancel" | translate }}
			</button>
			<button class="ok-bttn" (click)="onOkButton()" [disabled]="!input.valid">
				{{ "buttons.ok" | translate }}
			</button>
		</mat-dialog-actions>
	</mat-dialog-content>
</mat-list>
