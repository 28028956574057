import { Subject } from '../Subject';
import { SimpleOuterSubscriber, innerSubscribe, SimpleInnerSubscriber } from '../innerSubscribe';
export function retryWhen(notifier) {
  return source => source.lift(new RetryWhenOperator(notifier, source));
}
class RetryWhenOperator {
  constructor(notifier, source) {
    this.notifier = notifier;
    this.source = source;
  }
  call(subscriber, source) {
    return source.subscribe(new RetryWhenSubscriber(subscriber, this.notifier, this.source));
  }
}
class RetryWhenSubscriber extends SimpleOuterSubscriber {
  constructor(destination, notifier, source) {
    super(destination);
    this.notifier = notifier;
    this.source = source;
  }
  error(err) {
    if (!this.isStopped) {
      let errors = this.errors;
      let retries = this.retries;
      let retriesSubscription = this.retriesSubscription;
      if (!retries) {
        errors = new Subject();
        try {
          const {
            notifier
          } = this;
          retries = notifier(errors);
        } catch (e) {
          return super.error(e);
        }
        retriesSubscription = innerSubscribe(retries, new SimpleInnerSubscriber(this));
      } else {
        this.errors = undefined;
        this.retriesSubscription = undefined;
      }
      this._unsubscribeAndRecycle();
      this.errors = errors;
      this.retries = retries;
      this.retriesSubscription = retriesSubscription;
      errors.next(err);
    }
  }
  _unsubscribe() {
    const {
      errors,
      retriesSubscription
    } = this;
    if (errors) {
      errors.unsubscribe();
      this.errors = undefined;
    }
    if (retriesSubscription) {
      retriesSubscription.unsubscribe();
      this.retriesSubscription = undefined;
    }
    this.retries = undefined;
  }
  notifyNext() {
    const {
      _unsubscribe
    } = this;
    this._unsubscribe = null;
    this._unsubscribeAndRecycle();
    this._unsubscribe = _unsubscribe;
    this.source.subscribe(this);
  }
}
