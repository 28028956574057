"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var mergeMap_1 = require("./mergeMap");
function mergeMapTo(innerObservable, resultSelector, concurrent) {
  if (concurrent === void 0) {
    concurrent = Number.POSITIVE_INFINITY;
  }
  if (typeof resultSelector === 'function') {
    return mergeMap_1.mergeMap(function () {
      return innerObservable;
    }, resultSelector, concurrent);
  }
  if (typeof resultSelector === 'number') {
    concurrent = resultSelector;
  }
  return mergeMap_1.mergeMap(function () {
    return innerObservable;
  }, concurrent);
}
exports.mergeMapTo = mergeMapTo;
