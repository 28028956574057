<mat-dialog-content>
  <div class="popup-text" [innerHTML]="('popUp.remove' | translate) + ' ' + message + ' ' + ('popUp.fromCall' | translate) + '?'"></div>
  <mat-dialog-actions>
    <button [mat-dialog-close]="true">
      {{ 'popUp.remove' | translate }}
    </button>
    <button [mat-dialog-close]="false">
      {{ 'popUp.cancel' | translate }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
