import {Injectable} from '@angular/core';

export enum UserRole {
  CUSTOMER = '19',
  AGENT = '1a',
  DEV = 'dv', // '19' // experimental
  BOT = '18'
}
@Injectable()
export class RoleService {
  checkRole(role: string): boolean {
    switch (role) {
      case UserRole.CUSTOMER: {
        return true;
      }
      case UserRole.AGENT: {
        return true;
      }
      case UserRole.BOT: {
        return true;
      }
      default: {
        return false;
      }
    }
  }
}
