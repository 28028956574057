"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var SubscribeOnObservable_1 = require("../observable/SubscribeOnObservable");
function subscribeOn(scheduler, delay) {
  if (delay === void 0) {
    delay = 0;
  }
  return function subscribeOnOperatorFunction(source) {
    return source.lift(new SubscribeOnOperator(scheduler, delay));
  };
}
exports.subscribeOn = subscribeOn;
var SubscribeOnOperator = function () {
  function SubscribeOnOperator(scheduler, delay) {
    this.scheduler = scheduler;
    this.delay = delay;
  }
  SubscribeOnOperator.prototype.call = function (subscriber, source) {
    return new SubscribeOnObservable_1.SubscribeOnObservable(source, this.delay, this.scheduler).subscribe(subscriber);
  };
  return SubscribeOnOperator;
}();
