import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CAM_1 } from '../../data/rtc-constants';
import { StateVO } from '../../data/StateVO';
import { CallStorage, MEDIA_ERROR } from './call-storage.service';
import { RoomStateStorage } from './RoomStateStorage';
import { CaptureMediaService } from '../../../../services/capture-media.service';
import { MediaSettingsStorageService } from './media-settings-storage.service';
import { EasyRtcService } from '../../../../lib-rtc/services/easy-rtc.service';
import { MediaProperiesUtil } from 'src/app/lib-setting/components/setting-popup/setting-popup.interface';

export interface RtcStorageService {
  readonly selectedChanel$: Observable<StateVO>;
  readonly selectedChanel: string;
  readonly prevChanel: string;
  updateSelectedChanel: (value?: string) => void;
}

@Injectable({
  providedIn: 'root'
})
export class StreamSettingsStorage implements RtcStorageService {
  // hotfix
  public currentStream: MediaStream;

  private selectedChanelPrivate$: Subject<StateVO> = new Subject<StateVO>();
  private selectedChanelPrivate: StateVO = new StateVO(CAM_1, null);
  private selectedMediaIdPrivate$: BehaviorSubject<string> = new BehaviorSubject<string>('any');
  private selectedMediaIdPrivate = null;
  constructor(
    private callStorage: CallStorage,
    private roomState: RoomStateStorage,
    private captureMediaService: CaptureMediaService,
    private mssService: MediaSettingsStorageService,
  ) {
  }
  get selectedChanel$(): Observable<StateVO> {
    return this.selectedChanelPrivate$.asObservable();
  }
  get selectedChanel(): string {
    return this.selectedChanelPrivate.current;
  }
  get prevChanel(): string {
    return this.selectedChanelPrivate.prev;
  }
  updateSelectedChanel = (value?: string) => {
    if (!value) {
      value = CAM_1;
    }
    EasyRtcService.enableVideo(this.roomState.isCamOn, value);
    EasyRtcService.enableMicrophone(this.roomState.isMicroOn, value);
    if (value !== this.selectedChanelPrivate.current) {
      this.selectedChanelPrivate.prev = this.selectedChanelPrivate.current;
      this.selectedChanelPrivate.current = value;
      this.selectedChanelPrivate$.next(this.selectedChanelPrivate);
    }
  }
  get selectedMediaId$(): Observable<string> {
    return this.selectedMediaIdPrivate$.asObservable();
  }
  get selectedMediaId(): string {
    return this.selectedMediaIdPrivate;
  }
  set selectedMediaId(value: string) {
    const isError = this.checkMediaError(value);
    this.roomState.isErrorMediaSource = isError;
    if (!isError) {
      this.selectedMediaIdPrivate = value;
      this.selectedMediaIdPrivate$.next(value);
    }
  }
  private checkMediaError(mediaId: string) {
    return mediaId === MEDIA_ERROR;
  }
  clearCurrentStream(): void {
    this.currentStream = null;
  }
  setCurrentStream(currentStream: MediaStream): void {
    this.currentStream = currentStream;
  }
  async refreshMedia(): Promise<MediaStream> {
    if (this.currentStream) {
      return this.currentStream;
    }
    this.roomState.isLoading = true;
    await this.closeAllLocalStreamsAndPause();
    const mediaProperies = MediaProperiesUtil.readMediaProperiesFromStorage();
    const constraints = MediaProperiesUtil.createConstraints(mediaProperies);
    const stream: MediaStream | void = await EasyRtcService.initMediaSourceByBrowserSupport(constraints)
    .catch((err) => {
      this.roomState.isLoading = false;
      this.roomState.isErrorMediaSource = true;
      throw new Error('cant init stream');
    });
    if (stream) {
      const selectedDevice = this.mssService.vDeviceList.filter(d => d.label === stream.getVideoTracks()[0]?.label);
      if (selectedDevice.length > 0) {
        this.selectedMediaId = selectedDevice[0].deviceId;
      } else {
        this.selectedMediaId = stream.getVideoTracks()[0]?.label;
      }
      this.roomState.isLoading = false;
      this.roomState.isErrorMediaSource = false;
      this.currentStream = stream;
      return stream;
    } else {
      this.roomState.isLoading = false;
      this.roomState.isErrorMediaSource = true;
      throw new Error('cant init stream');
    }
  }

  closeAllLocalStreams(): void {
    const ids: string[] = EasyRtcService.getLocalMediaIds();
    for (const id of ids) {
      EasyRtcService.closeLocalStream(id);
    }
  }

  closeAllLocalStreamsAndPause(): Promise<void> {
    return new Promise<void>((resolve) => {
      // According to the documentation, the easyrtc.closeLocalStream(i) method is synchronous, but in fact it is not.
      // If you do not set the delay, then an error occurs: "Failed to get access to local media. Error code was NotReadableError.".
      this.closeAllLocalStreams();
      setTimeout(() => {
        resolve();
      }, 500);
    });
  }
}
