import { Injectable, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {ENGLISH, GERMAN, ISO_CONVERTER} from '../../constants/localstorage-constants';
import {Language} from '../../models/language';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { TranslateCacheService } from 'ngx-translate-cache';

@Injectable ({
  providedIn: 'root'
})
export class LanguageService implements OnDestroy {
  languages: Language[] = [];
  selectedLanguage: Language;
  selectedLocale: string;
  selectedLangId: string;
  private destroy$: Subject<any> = new Subject();

  constructor(public translateService: TranslateService, 
	// private translateCacheService: TranslateCacheService
) {
    this.translateService.addLangs([ENGLISH, GERMAN]);

    /** FYI according to ISO 639-1 German code is 'de', BUT according to ISO 639-2 its 'deu'
     * FYI according to ISO 639-1 English code is 'eng', BUT according to ISO 639-2 its 'eng'
     * Chrome currently uses kind of ISO-1, but you never know when it might change
     * Our back-end uses ISO-2
     */

    // Detect user browser default language
    let defaultLanguage = ENGLISH;

    // Check if the language code is one of the valid ISO 639-1 codes
    if (ISO_CONVERTER[localStorage.language]) {
      defaultLanguage = localStorage.language = ISO_CONVERTER[localStorage.language];
    } else if ([ENGLISH, GERMAN].includes(localStorage.language)) { // Check if the language code is one of the valid ISO 639-2 codes
      defaultLanguage = localStorage.language;
    } else {
      for (const language of navigator.languages) {
        // Language is usually like en_US or en_GB (in Chrome)
        const languageCode = language.slice(0, 2).toLocaleLowerCase();
        if (ISO_CONVERTER[languageCode] === GERMAN) {
          defaultLanguage = GERMAN;  // Our json files are named according to ISO 639-2
          break;
        }
      }
      localStorage.setItem('language', defaultLanguage);
    }

    this.translateService.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: LangChangeEvent) => {
        let langIso = '';

        if (event.lang === ENGLISH) {
          langIso = 'en';
        } else if (event.lang === GERMAN) {
          langIso = 'de';
        }

        // Add lang attribute to html element
        document.documentElement.lang = langIso;
      });

    this.translateService.setDefaultLang(defaultLanguage);
    this.translateService.use(defaultLanguage);
    this.translateService.getBrowserLang = () => defaultLanguage;
    // this.translateCacheService.init();
	  const dateLocal = defaultLanguage === 'eng' ? 'en-US' : 'de-DE';

  }

  change(languageIso: string): Promise<void> {
    const selectedIso = (!!this.selectedLanguage ? this.selectedLanguage.iso : null);
    const language: Language = this.languages.find((item: Language) => item.iso === languageIso);
    if (selectedIso !== languageIso && !!language) {
	  const dateLocal = languageIso === 'eng' ? 'en-US' : 'de-DE';

      return this.translateService.use(languageIso).toPromise()
      .then(() => {
        this.selectedLanguage = language;
        this.selectedLangId = language._id;
        localStorage.setItem('language', languageIso);
        return null;
      });
    } else {
      return Promise.resolve(null);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  setLanguages(languages: Language[]): void {
    this.languages.length = 0;
    this.languages.push(...(languages || []));
    this.updateSelectedLangId();
  }

  getLanguageById(languageId: string): Language {
    return this.languages.find((item: Language) => item._id === languageId);
  }

  updateSelectedLangId(): void {
    const langIso = this.translateService.getDefaultLang();
    const lang = this.languages.find((item: Language) => item.iso === langIso);
    if (lang) {
      this.selectedLangId = lang._id;
    }
  }

  translateArray(key: string, array: string[]): string {
	if (!array || !key || !array.length) {
		return "";
	}
	return array
		.map((item) => this.translateService.instant(`${key}.${item}`))
		.join(", ");
}
}
