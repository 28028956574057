// params example
// const params = {
//   _id: 'id', title: 'title', startTime: { id: 'startTime', type: 'date' }, joinTime: { id: 'startTime', type: 'boolean' }
// }
// todo move to decorator
export function copyParams(me: object, copyFrom: object, params: object) {
  const TYPE = 'type';
  const ID = 'id';
  const copyValue = (key: any) => {
    if (key !== undefined && key != null ) {
      if (key.hasOwnProperty(ID)) {
        let newParamValue = null;
        if (key.hasOwnProperty(TYPE)) {
          switch (key[TYPE]) {
            case 'date': {
              const day: number = 24 * 3600 * 1000;
              newParamValue = copyFrom[key[TYPE]] ? new Date(copyFrom[key[TYPE]]) : new Date(Date.now() + day);
              break;
            }case 'boolean': {
              newParamValue = copyFrom ? copyFrom[key[TYPE]] : false;
              break;
            }
          }
        }
        me[params[key[ID].toString()]] = newParamValue;
      } else { // if not object it expect string
        me[params[key.toString()]] = copyFrom[key.toString()];
      }
    }
  };
  Object.keys(params).map((prop: string) => {
    if (prop in copyFrom) {
      copyValue(prop);
    }
  });
}
