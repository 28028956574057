import { DatePipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FileUploadConfirmModules } from "./file-upload-confirm.modules";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
	selector: "app-file-upload-confirm",
	standalone: true,
	imports: [FileUploadConfirmModules, DatePipe],
	templateUrl: "./file-upload-confirm.component.html",
	styleUrl: "./file-upload-confirm.component.scss"
})
export class FileUploadConfirmComponent {
	isUploading = false;
	isUploadComplete = false;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			files: FileList;
			callBack: () => Observable<any>;
		},
		private dialogRef: MatDialogRef<FileUploadConfirmComponent>
	) {}

	public onClose(): void {
		this.dialogRef.close(false);
	}
	public onOkButton(): void {
		this.isUploading = true;

		if (this.data.callBack) {
			this.data
				.callBack()
				.pipe(
					finalize(() => {
						this.isUploading = false;
						this.isUploadComplete = true;
					})
				)
				.subscribe(
					(resp) => {},
					(error) => {
						console.error("Upload error:", error);
						this.isUploading = false;
						this.isUploadComplete = false;
					},
					() => {
						setTimeout(() => {
							this.onClose();
						}, 1800);
						console.log("All files uploaded successfully");
					}
				);
		}
	}
}
