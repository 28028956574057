import {
	ChangeDetectorRef,
	Component,
	Inject,
	input,
	SimpleChanges
} from "@angular/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { provideNativeDateAdapter } from "@angular/material/core";
import { AuthenticationService } from "../services/authentication/authentication.service";
import {
	REGULAR_TEACHERS,
	AVAILABILITY_SLOTS_REGULAR_LESSON,
	AVAILABILITIES_REGULAR_LESSON
} from "../constants/connection-constants";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Profile } from "../types/profile.types";
import { filter, map, min } from "rxjs/operators";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {
	trigger,
	state,
	style,
	animate,
	transition
	// ...
} from "@angular/animations";

import { LessonsService } from "../services/lessons/lessons.service";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { DateAdapter } from "@angular/material/core";

@Component({
	selector: "app-availability-calendar",
	standalone: true,
	imports: [
		MatCardModule,
		MatDatepickerModule,
		CommonModule,
		FormsModule,
		MatInputModule,
		MatSelectModule,
		MatFormFieldModule,
		TranslateModule,
		ReactiveFormsModule
	],
	providers: [provideNativeDateAdapter()],
	templateUrl: "./availability-calendar.component.html",
	styleUrl: "./availability-calendar.component.scss",
	animations: [
		trigger("openClose", [
			state(
				"open",
				style({
					opacity: 1
				})
			),
			state(
				"closed",
				style({
					opacity: 0.8
				})
			),
			transition("open => closed", [animate("1s")]),
			transition("closed => open", [animate("0.5s")])
		])
	]
})
export class AvailabilityCalendarComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { selectedTeacher },
		private http: HttpClient,
		private authenticationService: AuthenticationService,
		private lessonsService: LessonsService,
		public dialog: MatDialogRef<AvailabilityCalendarComponent>,
		private dateAdapter: DateAdapter<Date>
	) {}
	public teacherControl = new FormControl();
	selected;
	profile: Profile;
	teachers: any[];
	selectedTeacherId: string;
	selectTeacherName: string;
	selectedTeacherPhoto: string;
	dayAvailabilities: any[];
	startDate: Date;
	endDate: Date;
	splittedIntervals: any[];
	selectedTimeSlot;
	templateDate;
	formStep = "chooseTeacher";
	isInfoOpen = true;
	bookingDay;
	lessonPackage;
	duration;
	slots;

	days = [
		{ day: 0, startTime: null },
		{ day: 1, startTime: null },
		{ day: 2, startTime: null },
		{ day: 3, startTime: null },
		{ day: 4, startTime: null },
		{ day: 5, startTime: null },
		{ day: 6, startTime: null }
	];

	availabilityDaysFilter = (d: Date | null): boolean => {
		return false;
	};

	async ngOnInit() {
		dayjs.extend(timezone);
		dayjs.extend(utc);
		this.profile = await this.authenticationService.profile;
		this.lessonPackage = await this.lessonsService
			.getLessonPackageByStudentEmail(this.profile.email)
			.subscribe((lessonPackage) => {
				this.duration = parseInt(lessonPackage.duration);
			});

		this.getTeachers(this.profile.userId).subscribe((teachers) => {
			this.teachers = teachers.list;
		});
		this.dateAdapter.getFirstDayOfWeek = () => {
			return 1;
		};
		if (
			this.data?.selectedTeacher &&
			this.data?.selectedTeacher !== "all"
		) {
			console.log(this.data?.selectedTeacher);
			this.selectTeacher(this.data?.selectedTeacher);
		}
	}

	getTeachers(userId): Observable<any> {
		const url = REGULAR_TEACHERS.replace(":userId", userId);
		return this.http.get(url, {
			headers: this.authenticationService.headers,
			withCredentials: true
		});
	}

	onDateChange(date: Date) {
		this.dayAvailabilities = [];
		this.splittedIntervals = [];
		this.startDate = new Date(date);
		this.endDate = new Date(date);
		this.templateDate = date.toLocaleDateString("de-DE");
		this.endDate.setHours(23);
		this.startDate.setHours(4); // todo FIX ME
		const isoEnd = this.endDate.toISOString();
		const isoStart = this.startDate.toISOString();

		this.getSelectedTeacherAvailabilities(isoStart, isoEnd);
	}

	availabilitiesForFilter() {
		this.getAvailabilities(this.selectedTeacherId).subscribe(
			(results) => {
				this.days = this.days.map((day) => {
					const availabilities = results.filter(
						(r) => r.day == day.day
					);
					if (availabilities.length > 0) {
						day.startTime = availabilities[0].startTime;
					}
					return day;
				});

				this.availabilityDaysFilter = (d: Date | null): boolean => {
					if (!d) return false;

					const dayOfWeek = d.getDay(); // 0 for Sunday, 1 for Monday, etc.
					const day = this.days.find((day) => day.day === dayOfWeek);

					// Allow selection only if startTime is not null
					return day?.startTime !== null;
				};
			},
			(error) => {}
		);
	}

	getSelectedTeacherAvailabilities(isoStart: string, isoEnd: string) {
		this.getAvailabilitySlots(
			this.selectedTeacherId,
			isoStart,
			isoEnd
		).subscribe({
			next: (availabilities) => {
				const startTimeSlots = availabilities.map((timestamp) => {
					const date = new Date(timestamp);
					return date.toLocaleTimeString("en-GB", {
						hour: "2-digit",
						minute: "2-digit",
						hour12: false
					});
				});

				const addMinutes = (time, minsToAdd) => {
					const [hours, minutes] = time.split(":").map(Number);
					const totalMinutes = hours * 60 + minutes + minsToAdd;
					const newHours = Math.floor(totalMinutes / 60);
					const newMinutes = totalMinutes % 60;
					return `${String(newHours).padStart(2, "0")}:${String(
						newMinutes
					).padStart(2, "0")}`;
				};

				this.slots = startTimeSlots.map((time) => ({
					startTime: time,
					endTime: addMinutes(time, this.duration)
				}));
			},
			error: (error) => {}
		});
	}

	getAvailabilitySlots(userId, isoStart, isoEnd): Observable<any> {
		const url =
			AVAILABILITY_SLOTS_REGULAR_LESSON.replace(":userId", userId) +
			`&startTime=${isoStart}&endTime=${isoEnd}&duration=15m`;

		return this.http.get(url, {
			headers: this.authenticationService.headers,
			withCredentials: true
		});
	}

	getAvailabilities(userId): Observable<any> {
		const url = AVAILABILITIES_REGULAR_LESSON.replace(":userId", userId);
		return this.http.get(url, {
			headers: this.authenticationService.headers,
			withCredentials: true
		});
	}

	splitTimeIntervals(startTime, endTime, intervalMinutes) {
		const intervals = [];
		let currentTime = new Date(startTime);
		while (currentTime < endTime) {
			const nextTime = new Date(
				currentTime.getTime() + intervalMinutes * 60000
			);
			intervals.push({
				startTime: this.formatTime(currentTime),
				endTime: this.formatTime(nextTime)
			});
			currentTime = nextTime;
		}
		return intervals;
	}

	formatTime(date) {
		return date.toTimeString().slice(0, 5);
	}

	getDateOfTomorrow() {
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		return tomorrow;
	}

	selectTimeSlot(slot) {
		this.selectedTimeSlot = slot;
		this.isInfoOpen = false;
		this.formStep = "confirm";
	}

	goBack() {
		this.isInfoOpen = true;
		this.formStep = "chooseDateAndTime";
	}

	confirmBookingRequest() {
		this.createBookingRequest();
	}

	createBookingRequest() {
		let startTime = new Date(this.selected);
		const endTime = new Date(this.selected);

		var timeParts = this.selectedTimeSlot.startTime.split(":");
		var hours = parseInt(timeParts[0], 10);
		var minutes = parseInt(timeParts[1], 10);

		startTime.setHours(hours);
		startTime.setMinutes(minutes);

		timeParts = this.selectedTimeSlot.endTime.split(":");
		hours = parseInt(timeParts[0], 10);
		minutes = parseInt(timeParts[1], 10);

		endTime.setHours(hours);
		endTime.setMinutes(minutes);

		const startTimeUtc = startTime.toISOString();
		const endTimeUtc = endTime.toISOString();

		const bookingData = {
			teacherId: this.selectedTeacherId,
			studentId: this.profile.userId,
			title: "Requested Booking",
			description: "string",
			startTime: startTimeUtc,
			endTime: endTimeUtc,
			duration: this.duration + "m",
			type: "single",
			status: "pending",
			notify: true
		};

		this.lessonsService.createBookingRequest(bookingData).subscribe({
			next: (response) => {
				this.formStep = "success";
				setTimeout(() => {
					this.dialog.close();
				}, 1000);
			},
			error: (error) => {
				this.formStep = "error";
			}
		});
	}

	selectTeacher(event) {
		this.selectedTeacherId = event.value ? event.value.id : event.id;
		this.selectTeacherName = event.value ? event.value.name : event.name;
		this.selectedTeacherPhoto = event.value
			? event.value.photo
			: event.photo;
		this.formStep = "chooseDateAndTime";
		this.availabilitiesForFilter();
	}
}
