import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';

@Component({
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<AccessDeniedComponent>,
    private authenticationService: AuthenticationService,
  ) { }

  cancel(): void {
    this.dialogRef.close();
    this.authenticationService.checkLoggedIn$().toPromise().then((loggedIn) => {
      this.router.navigate(!!loggedIn ? ['/platform/start'] : ['/']);
    });
  }
}
