import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonType } from '../../../call-room/data/ButtonType';

@Component({
  selector: 'app-record-warning',
  templateUrl: './record-warning.component.html',
  styleUrls: [
    './record-warning.component.scss',
    './record-warning.component.tablet.scss',
    './record-warning.component.mobile.scss',
  ],
})
export class RecordWarningComponent implements OnInit {
  text: string = '';
  title: string = '';
  cancelButtonData: {text: string, callBack?: () => {} } = {text: ''};
  okButtonData: {text: string, callBack?: () => {} } = {text: ''};
  constructor(@Inject(MAT_DIALOG_DATA) private data: {text: string, title: string,
    buttons: { type: string, text: string, callBack: () => {} }[] }) {
  }

  ngOnInit() {
    this.text = this.data.text;
    this.title = this.data.title;
    const okButtons = this.data.buttons.filter(i => i.type === ButtonType.OK);
    if (okButtons.length > 0) {
      this.okButtonData = { text: okButtons[0].text, callBack: okButtons[0].callBack};
    }
    const cancelButtons = this.data.buttons.filter(i => i.type === ButtonType.CANCEL);
    if (cancelButtons.length > 0) {
      this.cancelButtonData = { text: cancelButtons[0].text, callBack: cancelButtons[0].callBack};
    }

  }
  onCancelClick() {
    if (this.cancelButtonData.callBack) {
      this.cancelButtonData.callBack();
    }
  }
  onOkClick() {
    if (this.okButtonData.callBack) {
      this.okButtonData.callBack();
    }
  }

}
