import { AgeGroup, Gender, SkillLevel } from "../types/profile.types";

interface ProfileSelectOption<type> {
	value: type;
	title: string;
}

export const BETA_TESTING_OPTIONS: ProfileSelectOption<boolean>[] = [
	{ value: true, title: "buttons.yes" },
	{ value: false, title: "buttons.no" }
];

export const SPOKEN_LANGUAGES: ProfileSelectOption<any>[] = [
	{ value: "AF", title: "languages.afrikaans" },
	{ value: "AR", title: "languages.arabic" },
	{ value: "BN", title: "languages.bengali" },
	{ value: "BG", title: "languages.bulgarian" },
	{ value: "ZH", title: "languages.chinese" },
	{ value: "DA", title: "languages.danish" },
	{ value: "NL", title: "languages.dutch" },
	{ value: "EN", title: "languages.english" },
	{ value: "FI", title: "languages.finnish" },
	{ value: "FR", title: "languages.french" },
	{ value: "DE", title: "languages.german" },
	{ value: "EL", title: "languages.greek" },
	{ value: "HE", title: "languages.hebrew" },
	{ value: "HI", title: "languages.hindi" },
	{ value: "ID", title: "languages.indonesian" },
	{ value: "IT", title: "languages.italian" },
	{ value: "JA", title: "languages.japanese" },
	{ value: "KO", title: "languages.korean" },
	{ value: "MS", title: "languages.malay" },
	{ value: "NO", title: "languages.norwegian" },
	{ value: "FA", title: "languages.persian" },
	{ value: "PL", title: "languages.polish" },
	{ value: "PT", title: "languages.portuguese" },
	{ value: "RO", title: "languages.romanian" },
	{ value: "RU", title: "languages.russian" },
	{ value: "ES", title: "languages.spanish" },
	{ value: "SV", title: "languages.swedish" },
	{ value: "TH", title: "languages.thai" },
	{ value: "TR", title: "languages.turkish" },
	{ value: "UK", title: "languages.ukrainian" }
];
export const GENDERS: ProfileSelectOption<Gender>[] = [
	{
		value: "female",
		title: "genders.female"
	},
	{
		value: "male",
		title: "genders.male"
	},
	{
		value: "non-binary",
		title: "genders.non-binary"
	},
	{
		value: "transgender",
		title: "genders.transgender"
	},
	{
		value: "intersex",
		title: "genders.intersex"
	},
	{
		value: "other",
		title: "genders.other"
	},
	{
		value: "prefer-not",
		title: "genders.prefer-not"
	}
];

export const GENRES: ProfileSelectOption<string>[] = [
	{ value: "classic", title: "musicGenres.classic" },
	{ value: "electro", title: "musicGenres.electro" },
	{ value: "film-music", title: "musicGenres.film-music" },
	{ value: "folk-music", title: "musicGenres.folk-music" },
	{ value: "funk", title: "musicGenres.funk" },
	{ value: "hip-hop", title: "musicGenres.hip-hop" },
	{ value: "improvisation", title: "musicGenres.improvisation" },
	{ value: "jazz", title: "musicGenres.jazz" },
	{ value: "metal", title: "musicGenres.metal" },
	{ value: "musical", title: "musicGenres.musical" },
	{ value: "opera", title: "musicGenres.opera" },
	{ value: "other-music-genre", title: "musicGenres.other-music-genre" },
	{ value: "pop", title: "musicGenres.pop" },
	{ value: "rap", title: "musicGenres.rap" },
	{ value: "rock", title: "musicGenres.rock" },
	{ value: "schlager", title: "musicGenres.schlager" },
	{ value: "soul", title: "musicGenres.soul" }
];
export const INSTRUMENTS: ProfileSelectOption<string>[] = [
	{ value: "e-guitar", title: "instruments.e-guitar" },
	{ value: "piano-grand-piano", title: "instruments.piano-grand-piano" },
	{ value: "violin", title: "instruments.violin" },
	{ value: "trumpet", title: "instruments.trumpet" },
	{ value: "flute", title: "instruments.flute" },
	{ value: "singing", title: "instruments.singing" },
	{ value: "chimes", title: "instruments.chimes" },
	{ value: "dj", title: "instruments.dj" },
	{ value: "e-piano-keyboard", title: "instruments.e-piano-keyboard" },
	{ value: "horn", title: "instruments.horn" },
	{ value: "bongo", title: "instruments.bongo" },
	{ value: "guitar", title: "instruments.guitar" },
	{ value: "music-production", title: "instruments.music-production" },
	{ value: "viola", title: "instruments.viola" },
	{ value: "cello-violoncello", title: "instruments.cello-violoncello" },
	{ value: "bass-guitar-e-bass", title: "instruments.bass-guitar-e-bass" },
	{ value: "recorder", title: "instruments.recorder" },
	{ value: "double-bass", title: "instruments.double-bass" },
	{ value: "trombone", title: "instruments.trombone" },
	{ value: "tuba", title: "instruments.tuba" },
	{ value: "harp", title: "instruments.harp" },
	{ value: "saxophone", title: "instruments.saxophone" },
	{ value: "music-theory", title: "instruments.music-theory" },
	{ value: "composition", title: "instruments.composition" },
	{ value: "sound-engineering", title: "instruments.sound-engineering" },
	{ value: "drums-percussion", title: "instruments.drums-percussion" },
	{ value: "sound-therapy", title: "instruments.sound-therapy" },
	{ value: "ukulele", title: "instruments.ukulele" },
	{ value: "marimba", title: "instruments.marimba" },
	{ value: "bagpipe", title: "instruments.bagpipe" },
	{ value: "accordion", title: "instruments.accordion" },
	{ value: "other-instrument", title: "instruments.other-instrument" }
];

export const VAT_STATUS: ProfileSelectOption<string>[] = [
	{
		value: "small-businessman",
		title: "vat-status.small-businessman"
	},
	{
		value: "exempt",
		title: "vat-status.exempt"
	},
	{
		value: "registered",
		title: "vat-status.registered"
	},
	{
		value: "other-eu",
		title: "vat-status.other-eu"
	},
	{
		value: "third-country",
		title: "vat-status.third-country"
	}
];

export const LANGUAGE_LEVELS: ProfileSelectOption<string>[] = [
	{ value: "A1", title: "A1" },
	{ value: "A2", title: "A2" },
	{ value: "B1", title: "B1" },
	{ value: "B2", title: "B2" },
	{ value: "C1", title: "C1" },
	{ value: "C2", title: "C2" },
	{ value: "native", title: "languageLevels.native" }
];

export const AGE_GROUPS: AgeGroup[] = [
	"0-10",
	"11-17",
	"18-29",
	"30-44",
	"45-64",
	"65-100"
];

export const STUDENT_EXPERIENCE_LEVELS: ProfileSelectOption<SkillLevel>[] = [
	{ value: "experience_no", title: "student-experience.experience_no" },
	{ value: "experience_self", title: "student-experience.experience_self" },
	{ value: "experience_1-3", title: "student-experience.experience_1-3" },
	{ value: "experience_more", title: "student-experience.experience_more" }
];

function yearsAgo(years) {
	const date = new Date();
	const targetDate = new Date(
		date.getFullYear() - years,
		date.getMonth(),
		date.getDate()
	);
	return targetDate.toISOString().split("T")[0];
}

export const MIN_DATE = yearsAgo(99);
export const MAX_DATE = yearsAgo(18);

export const NOTIFICATION_OPTIONS = [
	"confirmation",
	"reminder1h",
	"reminder24h",
	"reschedule",
	"cancellation"
];
