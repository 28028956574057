"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var zip_1 = require("../observable/zip");
function zip() {
  var observables = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    observables[_i] = arguments[_i];
  }
  return function zipOperatorFunction(source) {
    return source.lift.call(zip_1.zip.apply(void 0, [source].concat(observables)));
  };
}
exports.zip = zip;
