import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { AuthenticationService } from "../services/authentication/authentication.service";
import {
	REGULAR_STUDENTS,
	STUDENT_ACTIVATION_EMAIL
} from "../constants/connection-constants";
import { PAYMENT_SESSION } from "../constants/connection-constants";

export enum ActivationStatus {
	PENDING = "pending",
	ACTIVATED = "activated",
	DEACTIVATED = "deactivated",
	REACTIVATED = "reactivated"
}

export type Student = {
	id: string;
	name: string;
	email: string;
	subrole: number;
	activationStatus: ActivationStatus;
	// to do: add instrument and genre types and move all to type file
	instruments: any[];
	// genres: string[];
	favoriteSong: string | null;
	notes: string | null;
	ageGroup: string | null;
};

export type StudentListResponse = {
	list: Student[];
	count: number;
	page: string;
	pages: number;
};

@Injectable({
	providedIn: "root"
})
export class StudentsService {
	constructor(
		private http: HttpClient,
		private authenticationService: AuthenticationService
	) {}

	getStudents(userId, page, limit): Observable<StudentListResponse> {
		let url = REGULAR_STUDENTS.replace(":userId", userId);

		if (page) {
			url += `?page=${page}`;
		}
		if (limit) {
			url += `&limit=${limit}`;
		}

		return this.http.get<StudentListResponse>(url, {
			headers: this.authenticationService.headers,
			withCredentials: true
		});
	}

	getPaymentSession(studentId: string): Observable<any> {
		const url = PAYMENT_SESSION.replace(":studentId", studentId);
		return this.http.post(url, {
			headers: this.authenticationService.headers,
			withCredentials: true
		});
	}

	sendActivationEmail(email: string): Observable<any> {
		const url = STUDENT_ACTIVATION_EMAIL;
		return this.http.post(url, { email });
	}

	// getStudents(userId): Observable<any> {
	// 	const url = REGULAR_STUDENTS.replace(":userId", userId);

	// 	return this.http.get(url, {
	// 		headers: this.authenticationService.headers,
	// 		withCredentials: true,
	// 	});
	// }
}
