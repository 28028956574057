import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {ADD_PERSONAL_ROOM, GET_PERSONAL_ROOM, PERSONAL_ROOM, ROOMS} from '../constants/connection-constants';
import { Room } from '../models/room';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  private hasEnteredRoomPrivate: boolean = false; // A workaround to refresh start page if we returned there from the call

  get hasEnteredRoom(): boolean {
    return this.hasEnteredRoomPrivate;
  }

  set hasEnteredRoom(value: boolean) {
    this.hasEnteredRoomPrivate = value;
  }

  private get authHttpOptions() {
    return {
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: this.auth.accessToken
      })
    };
  }

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  getRoom(): Observable<object> {
    return this.http.post(ROOMS, {}, this.authHttpOptions);
  }

  checkLoggedIn$(): Observable<any> {
    return this.auth.checkLoggedIn$();
  }

  siriusNow() {
    return this.getRoom().toPromise().then((room: Room) => {
      sessionStorage.setItem('roomUrl', room.target);
      sessionStorage.setItem('roomPassword', room.password);

      const roomUrl = room.target.substring(room.target.lastIndexOf('/') + 1);
      return roomUrl;
    });
  }

  siriusFuture() {
    return this.getRoom().toPromise().then((room: Room) => {
      return {
        link: room.target,
        password: room.password,
      };
    });
  }

  triggerLoader(value?: number): void {
    this.auth.triggerLoader(value);
  }

  getPersonalRoom$(): Observable<object> {
    return this.http.get(GET_PERSONAL_ROOM, this.authHttpOptions);
  }

  addPersonalRoom$(roomName: string, roomPassword: string): Observable<object> {
    return this.http.post(ADD_PERSONAL_ROOM, {title: roomName, password: roomPassword}, this.authHttpOptions);
  }

  updatePersonalRoom(roomName: string, styles: string): Observable<object> {
    return this.http.put(PERSONAL_ROOM + '/' + roomName, { styles }, this.authHttpOptions);
  }

  deletePersonalRoom$(roomId: string): Observable<object> {
    return this.http.delete(PERSONAL_ROOM + '/' + roomId, this.authHttpOptions);
  }
}
