
export const PRM_DEBUG = 'debug';
export const DEBUG_VALUE_21 = '21';
export const DEBUG_VALUE_22 = '22';
export const DEBUG_VALUE_23 = '23'; // TunerComponent
export const DEBUG_VALUE_24 = '24'; // CallRoomComponent
export const DEBUG_VALUE_25 = '25'; // LmQualityRatingComponent

export class LocationUtil {
  public static findGetParameter(parameterName: string, location: Location = window.location) {
    let tmp: string[] = [];
    let result = null;
    const items = location.search.substr(1).split('&');
    for (let index = 0; result === null && index < items.length; index++) {
        tmp = items[index].split('=');
        if (tmp[0] === parameterName) {
          result = decodeURIComponent(tmp[1]);
        }
    }
    return result;
  }
}
