<mat-dialog-content>
    <ng-container *ngIf="!successfullBooking && !bookingExistError && !bookingGeneralError">
        <div class="popup-text-title">{{title}}</div>
        <div class="popup-text"><mat-icon class="person-outline-icon">person_outline</mat-icon>
            <div>
                {{'lessons.student-label' | translate}}: {{studentName}}
            </div>
        </div>
        <div class="popup-text">
            <mat-icon class="lesson-calendar">event</mat-icon>
            <div [innerHtml]="lessonTimeInterval"></div>
        </div>
        <div class="popup-text">
            <mat-icon class="access-time-icon">access_time</mat-icon>
            <div>
                {{duration}}
            </div>
        </div>
        <div *ngIf="!isRequestTypeUpdate" class="popup-text">
            <mat-icon class="access-time-icon">event_repeat</mat-icon>
            <div>
                {{repeatType}}
            </div>
        </div>
        <mat-dialog-actions>
            <span class='error-bttn' [mat-dialog-close]="true" (click)="onCancelClick()">
                {{ cancelButtonData.text | translate }}
            </span>
            <button class='ok-bttn' [disabled]="disableConfirm" (click)="onOkClick()">
                {{ okButtonData.text | translate }}
            </button>
        </mat-dialog-actions>
    </ng-container>

    <ng-container *ngIf="successfullBooking">
        <div class="text-lg mb-0">
            <p class="text-base leading-tight text-sirius-black">{{"lessons.successful-booking" | translate}}</p>
        </div>
    </ng-container>

    <ng-container *ngIf="bookingGeneralError">
        <div class="text-lg mb-0">
            <p class="text-base leading-tight text-sirius-black">{{bookingErrorText}}</p>
        </div>
        <button class='ok-bttn' [mat-dialog-close]="true" (click)="onCancelClick()">
            {{"popUp.close" | translate}}
        </button>
    </ng-container>

    <ng-container *ngIf="bookingExistError">
        <div class="text-lg mb-0">
            <p class="text-base leading-tight text-sirius-black">{{"lessons.booking-err" | translate}}</p>
        </div>
        <button class='ok-bttn' [mat-dialog-close]="true" (click)="onCancelClick()">
            {{"popUp.close" | translate}}
        </button>
    </ng-container>

</mat-dialog-content>