import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { fileSharingModules } from "./file-sharing.modules";
import { StudentsService } from "../../../../students/students.service";
import { LessonUtils } from "src/app/utils/lesson-utils";
import { catchError, debounceTime, switchMap } from "rxjs/operators";
import { BehaviorSubject, of, Subject } from "rxjs";
import { StudentList } from "@data-types/studentList.type";
import { LibPipeModule } from "../../../../lib-pipe/lib-pipe.module";
import { FilesCloudService } from "src/app/services/material-cloud/files-cloud.service";
import { EmptyListContainerComponent } from "../../../../components/util/empty-list-container/empty-list-container.component";

@Component({
	selector: "app-file-sharing",
	standalone: true,
	imports: [fileSharingModules, LibPipeModule, EmptyListContainerComponent],
	templateUrl: "./file-sharing.component.html",
	styleUrl: "./file-sharing.component.scss"
})
export class FileSharingComponent {
	page: number = 1;
	limit: string = "10";
	studentStatuses: { [key: string]: string } = {};
	studentsList: StudentList;
	selectedStudentIds: Set<any> = new Set();
	private checkboxSubject = new Subject<any>();
	public isLoading$ = new BehaviorSubject<boolean>(true);

	// To- Do set the correct data type when the structure will be ready
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<FileSharingComponent>,
		private studentsService: StudentsService,
		private lessonUtils: LessonUtils,
		private filesService: FilesCloudService
	) {
		const { profile } = data;
		this.studentsService
			.getStudents(profile.userId, this.page, this.limit)
			.pipe(
				switchMap((resp) => {
					this.isLoading$.next(true);
					return this.lessonUtils.getStudentsWithLessonPackages(resp);
				}),
				catchError((error) => {
					console.error("An error ocurred:", error);
					this.isLoading$.next(false);
					return of(null);
				})
			)
			.subscribe((resp) => {
				if (resp) {
					const { count, list, page, pages } = resp;
					this.studentsList = list;
					this.calculateLessonPackageStatuses(this.studentsList);
				}
				this.isLoading$.next(false);
			});
		this.checkboxSubject.pipe(debounceTime(400)).subscribe((value: any) => {
			value.checked ? this.onShare(value) : this.onRevoke(value);
		});
	}

	isStudentInSharedList(studentId): boolean {
		const { fileData } = this.data;
		return fileData.accessList?.some((id) => id === studentId);
	}

	calculateLessonPackageStatuses(studentList: StudentList) {
		studentList.forEach((student) => {
			if (student.activationStatus === "activated") {
				this.studentStatuses[student.id] =
					this.lessonUtils.setPackageLessonStatus(
						student.lessonPackage.status
					);
			} else {
				this.studentStatuses[student.id] =
					"lessons-overview.package-status.pending";
			}
		});
	}

	toggleSelection(event: any, studentId: string, fileId: string): void {
		this.checkboxSubject.next({
			checked: event.checked,
			studentId,
			fileId
		});
	}

	public close(): void {
		this.dialogRef.close(false);
	}

	onShare(sharingData): void {
		console.log("Sharing with:", sharingData);
		this.filesService
			.shareFile(sharingData.fileId, sharingData.studentId)
			.subscribe((data) => {
				console.log(data);
			});
	}
	onRevoke(sharingData): void {
		console.log("Revoking to:", sharingData);
		this.filesService
			.revokeSharedFile(sharingData.fileId, sharingData.studentId)
			.subscribe((data) => {
				console.log(data);
			});
	}
}
