import { Component, Inject } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef
} from "@angular/material/dialog";
import { FormsModule } from "@angular/forms";
import { JsonPipe } from "@angular/common";
import { MatListModule } from "@angular/material/list";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";

@Component({
	selector: "app-file-rename",
	standalone: true,
	imports: [
		MatDialogModule,
		MatListModule,
		TranslateModule,
		MatIconModule,
		FormsModule,
		JsonPipe
	],
	templateUrl: "./file-rename.component.html",
	styleUrl: "./file-rename.component.scss"
})
export class FileRenameComponent {
	name: string;
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			fileData;
		},
		private dialogRef: MatDialogRef<FileRenameComponent>
	) {}

	public onClose(): void {
		this.dialogRef.close(false);
	}
	public onOkButton(): void {
		const { name } = this.data.fileData;
		const splittedName = name.split(".");
		const extension = splittedName[splittedName.length - 1];
		const newName = `${this.name}.${extension}`;
		this.dialogRef.close({ newName: newName });
	}
}
