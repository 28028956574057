<div class="main-container bg-white text-offblack">
	<div *ngIf="name" class="text-headline-1 font-bold mb-[15px]">
		👤 {{ name }}
	</div>
	<!-- to do : add ellipsis -->
	<div *ngIf="email" class="text-text-1 font-bold mb-[15px]">
		{{ email }}
	</div>
	<div class="grid grid-cols-2 text-text-1 gap-[10px]">
		<ng-container *ngIf="date">
			<div class="font-bold">{{ "student-info.date" | translate }}</div>
			<div>{{ date }}</div>
		</ng-container>
		<ng-container *ngIf="time">
			<div class="font-bold">{{ "student-info.time" | translate }}</div>
			<div>{{ time }}</div>
		</ng-container>
		<ng-container *ngIf="instruments">
			<div class="font-bold">
				{{ "student-info.instrument" | translate }}
			</div>
			<div>{{ instruments }}</div>
		</ng-container>
		<ng-container *ngIf="genre">
			<div class="font-bold">{{ "student-info.genre" | translate }}</div>
			<div>{{ genre }}</div>
		</ng-container>
		<ng-container *ngIf="ageGroup">
			<div class="font-bold">
				{{ "student-info.age-group" | translate }}
			</div>
			<div>{{ ageGroup }}</div>
		</ng-container>
		<ng-container *ngIf="skillLevel">
			<div class="font-bold">
				{{ "student-info.skill-level" | translate }}
			</div>
			<div>{{ "skill-level." + skillLevel | translate }}</div>
		</ng-container>
		<ng-container *ngIf="favouriteSong">
			<div class="font-bold">
				{{ "student-info.favourite-song" | translate }}
			</div>
			<div>{{ favouriteSong }}</div>
		</ng-container>
		<ng-container *ngIf="notesForTeacher">
			<div class="font-bold">
				{{ "student-info.notes-for-you" | translate }}
			</div>
			<div>{{ notesForTeacher }}</div>
		</ng-container>
	</div>
	<div class="button-box flex justify-center">
		<button
			(click)="close()"
			class="bg-indigo text-white rounded-full text-text-1 font-bold hover:bg-indigo-hover"
		>
			{{ "popUp.close" | translate }}
		</button>
	</div>
</div>
