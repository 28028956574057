import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { LessonsService } from "../services/lessons/lessons.service";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";

@Component({
	selector: "app-accept-decline-booking",
	standalone: true,
	imports: [CommonModule, TranslateModule],
	templateUrl: "./accept-decline-booking.component.html",
	styleUrl: "./accept-decline-booking.component.scss"
})
export class AcceptDeclineBookingComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { booking; status: string },
		public lessonsService: LessonsService,
		public dialog: MatDialogRef<AcceptDeclineBookingComponent>
	) {}

	status: string;
	bookingId: string;
	booking;

	ngOnInit() {
		this.status = this.data.status;
		this.bookingId = this.data.booking.id;
	}

	updateBookingStatus() {
		let bookingObject = { status: this.status };
		this.lessonsService
			.updateBooking(this.data.booking.id, bookingObject)
			.subscribe(() => {
				this.lessonsService.updateBookingList$.next();
				this.dialog.close();
			});
	}
	close() {
		this.dialog.close();
	}
}
