import { copyParams } from "./ServerResponceParcer";
import { BehaviorSubject, Subject } from "rxjs";

export class UserVO {
	imageId: string;
	connected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	isTeacher$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	id: string;
	rtcId: string;
	streamName: string;
	isSpy: boolean = false;
	self: boolean = false;
	reconnectCalc: number = 0;
	youTubeVideoId: string;
	hasYoutube: boolean;

	public name$ = new BehaviorSubject<string>("");
	public isCamera$ = new BehaviorSubject<boolean>(false);
	public isMicro$ = new BehaviorSubject<boolean>(true);
	public isDisplay$ = new BehaviorSubject<boolean>(false);
	public isHandOn$ = new BehaviorSubject<boolean>(false);
	public isReflection$ = new BehaviorSubject<boolean>(false);
	public isRecordingBot$ = new BehaviorSubject<boolean>(false);
	public secondStreamActive$ = new BehaviorSubject<boolean>(false);
	public mediaStream: MediaStream;
	public mediaStreamSecondVideo: MediaStream;
	public screenShareStream: MediaStream;

	constructor() {}
	get name(): string {
		return this.name$.getValue();
	}
	set name(value: string) {
		if (this.name !== value) {
			this.name$.next(value);
		}
	}
	get connected(): boolean {
		return this.connected$.getValue();
	}
	set connected(value: boolean) {
		this.connected$.next(value);
	}
	get isTeacher(): boolean {
		return this.isTeacher$.getValue();
	}
	set isTeacher(value: boolean) {
		this.isTeacher$.next(value);
	}
	get isMicro(): boolean {
		return this.isMicro$.getValue();
	}
	set isMicro(value: boolean) {
		this.isMicro$.next(value);
	}
	get isCamera(): boolean {
		return this.isCamera$.getValue();
	}
	set isCamera(value: boolean) {
		this.isCamera$.next(value);
	}
	get isDisplay(): boolean {
		return this.isDisplay$.getValue();
	}
	set isDisplay(value: boolean) {
		this.isDisplay$.next(value);
	}
	get isHandOn(): boolean {
		return this.isHandOn$.getValue();
	}
	set isHandOn(value: boolean) {
		this.isHandOn$.next(value);
	}
	get isReflection(): boolean {
		return this.isReflection$.getValue();
	}
	set isReflection(value: boolean) {
		this.isReflection$.next(value);
	}
	get isRecordingBot(): boolean {
		return this.isRecordingBot$.getValue();
	}
	set isRecordingBot(value: boolean) {
		this.isRecordingBot$.next(value);
	}
	get secondStreamActive(): boolean {
		return this.secondStreamActive$.getValue();
	}
	set secondStreamActive(value: boolean) {
		this.secondStreamActive$.next(value);
	}

	updateByServerData(serverData: object) {
		const optionsName = "options";
		const props = {
			name: "name",
			id: "id",
			file_id: "imageId",
			connected: "connected",
		};
		const propsOptions = {
			microphone: "isMicro",
			id: "id",
			camera: "isCamera",
			screenshare: "isDisplay",
			reflection: "isReflection",
			risehand: "isHandOn",
			recordingBot: "isRecordingBot",
			isTeacher: "isTeacher",
		};
		copyParams(this, serverData, props);
		if (serverData[optionsName]) {
			copyParams(this, serverData[optionsName], propsOptions);
		}
		return this;
	}
	updateByBotData(serverData: object) {
		this.isSpy = true;
		const props = { id: "id", connected: "connected" };
		copyParams(this, serverData, props);
	}
	update(user: UserVO) {
		const props = {
			name: "name",
			id: "id",
			imageId: "imageId",
			connected: "connected",
			isTeacher: "isTeacher",
			isMicro: "isMicro",
			rtcId: "rtcId",
			streamName: "streamName",
			isCamera: "isCamera",
			isDisplay: "isDisplay",
			isHandOn: "isHandOn",
			isReflection: "isReflection",
			reconnectCalc: "reconnectCalc",
			isRecordingBot: "isRecordingBot",
		};
		copyParams(this, user, props);
	}
	// todo refactor
	isEqual = (user: UserVO, onlyNameCheck?: boolean): boolean => {
		let callback = true;
		const notNull = (prop: any): boolean => {
			return !(prop === undefined || prop === null);
		};
		if (user === undefined || user == null) {
			callback = false;
		}
		if (user.id !== this.id) {
			callback = false;
		} else if (onlyNameCheck) {
			return callback;
		} else if (notNull(user.imageId) && user.imageId !== this.imageId) {
			callback = false;
		} else if (
			notNull(user.connected) &&
			user.connected !== this.connected
		) {
			callback = false;
		} else if (
			notNull(user.isTeacher) &&
			user.isTeacher !== this.isTeacher
		) {
			callback = false;
		} else if (notNull(user.name) && user.name !== this.name) {
			callback = false;
		} else if (notNull(user.isMicro) && user.isMicro !== this.isMicro) {
			callback = false;
		} else if (
			notNull(user.isDisplay) &&
			user.isDisplay !== this.isDisplay
		) {
			callback = false;
		} else if (notNull(user.isCamera) && user.isCamera !== this.isCamera) {
			callback = false;
		} else if (notNull(user.isHandOn) && user.isHandOn !== this.isHandOn) {
			callback = false;
		} else if (
			notNull(user.isReflection) &&
			user.isReflection !== this.isReflection
		) {
			callback = false;
		} else if (
			notNull(user.isRecordingBot) &&
			user.isRecordingBot !== this.isRecordingBot
		) {
			callback = false;
		}
		return callback;
	};
}
