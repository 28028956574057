import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
	MatDialogRef,
	MAT_DIALOG_DATA,
	MatDialogState
} from "@angular/material/dialog";
import { USER_KEY } from "../../../../constants/localstorage-constants";
import { UserVO } from "../../../../services/data/UserVO";
import { CallStorage } from "../../../call-room/service/storage/call-storage.service";
import { RoomStateStorage } from "../../../call-room/service/storage/RoomStateStorage";
import { Subscription, from } from "rxjs";
import { UntypedFormGroup } from "@angular/forms";
import { RoomAuthService } from "src/app/services/ws/room-auth.service";
import { first } from "rxjs/operators";
import { AuthenticationService } from "../../../../services/authentication/authentication.service";
import { UnsupportedBrowserService } from "../../../../unsupported-browser/unsupported-browser.service";
import {
	DEMAND,
	ROOM,
	TURN,
	WebSocketService
} from "../../../../services/ws/web-socket.service";
import { RoomInfo } from "../../../../models/room-info";
import { logger } from "src/app/lib-core/logger";
import { UsersService } from "../../../../services/users.service";
import { BotService } from "../../../../services/bot/bot.service";
import { ensureGlobalObject } from "../../../../services/utils";
import { RecordingService } from "../../../../services/recording/recording.service";
import { RoomService } from "src/app/services/room.service";

@Component({
	selector: "app-popup-desktop",
	templateUrl: "./desktop/lobby-popup-desktop.component.html",
	styleUrls: ["./desktop/lobby-popup-desktop.component.scss"]
})
export class LobbyPopupBaseComponent implements OnInit, OnDestroy {
	enterCallForm: UntypedFormGroup;
	isAuthError: boolean = false;
	showPass: boolean = false;
	childLoading: boolean = true;

	// All of them are public, because in production mode (Angular) HTML template can only access public fields
	users: UserVO[];
	title: string;
	isLive: boolean;
	usersConnected: number;
	maxUsersAmount: number;
	userName: string;
	userFirstLetter: string = "";
	errorMessage: string;
	displaySettings: boolean;
	settings: { micro: boolean; cam: boolean } = {
		micro: this.roomState.isMicroOn,
		cam: this.roomState.isCamOn
	};
	videoSettingsCanBeClosed = false;
	public isUserLogged: boolean;

	private subscribes = new Array<Subscription>();
	private socketEventRoomSub: Subscription | null = null;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public callStorage: CallStorage,
		public roomState: RoomStateStorage,
		private roomAuthService: RoomAuthService,
		public dialogRef: MatDialogRef<any>,
		public authenticationService: AuthenticationService,
		protected unsupportedBrowserService: UnsupportedBrowserService,
		protected webSocketService: WebSocketService,
		protected usersService: UsersService,
		protected recordingService: RecordingService,
		protected roomService: RoomService
	) {
		// logger.log('GETTING DATA ', data);
		this.errorMessage = data.errorMessage;
		this.usersConnected = data.usersConnected;
		this.title = data.title;
		this.isLive = data.isLive;
		this.maxUsersAmount = data.maxUsersAmount;
	}

	ngOnInit() {
		// Check if browser is supported
		this.unsupportedBrowserService.checkBrowserSupport(() => {
			if (this.dialogRef?.getState() === MatDialogState.OPEN) {
				this.dialogRef.close(true);
			}
		});
		this.userName = sessionStorage.getItem(USER_KEY);
		this.userFirstLetter = this.userName
			? this.userName.substr(0, 1).toUpperCase()
			: "";
		this.authenticationService.isLoggedIn$
			.pipe(first())
			.subscribe((value) => {
				this.isUserLogged = value;
				if (value && sessionStorage.roomPassword) {
					// This will be true if the room was created by current user via 'Sirius Now'
					this.proceedToSettings();
				}
			});
	}
	ngOnDestroy(): void {
		this.subscribes.forEach((subscribe) => {
			subscribe.unsubscribe();
		});
		if (!!this.socketEventRoomSub) {
			this.socketEventRoomSub.unsubscribe();
		}
	}

	togglePass() {
		this.showPass = !this.showPass;
	}

	async joinClick(): Promise<void> {
		const canJoin = this.authenticationService.canJoin();
		const isApprover = this.authenticationService.isApprover();
		this.userName = sessionStorage.getItem(USER_KEY);
		this.userFirstLetter = this.userName
			? this.userName.substr(0, 1).toUpperCase()
			: "";
		this.callStorage.settingParams = this.settings;
		if (canJoin || isApprover) {
			this.dialogRef.close(true);
		} else {
			if (!!this.socketEventRoomSub) {
				this.socketEventRoomSub.unsubscribe();
			}
			this.socketEventRoomSub = this.webSocketService
				.eventByName(ROOM)
				.subscribe((data) => this.listenToSocket(data));
			if (BotService.isBot) {
				await new Promise((r) => setTimeout(r, 1500));
				ensureGlobalObject("APP.conference._room").isJoined = () =>
					true;
				await this.recordingService
					.confirm(this.callStorage.title)
					.toPromise();
			}
			// this.webSocketService.subscribe(ROOM, this.listenToSocket);
			this.webSocketService.dispatch(TURN, {
				option: DEMAND,
				name: this.userName,
				status: true,
				recordingBot: BotService.skipPreJoin
			});
		}
	}

	private listenToSocket = (data) => {
		// logger.log('%LobbuPopupBase.event() ', data[0]);
		this.videoSettingsCanBeClosed = true;
		switch (data[0]._event) {
			case TURN:
				this.turnHandler(data[0]);
				break;
			case DEMAND:
				this.demandHandler(data[0]);
				break;
			// default:
			//   logger.log('Lobby default event', data[0]._event, 'with data', data[0]);
			//   break;
		}
	};

	protected turnHandler(data): void {
		// Send demand
		// this.callStorage.usersStorage.selfUser.id returns incorrect value!!!
		const currentUser = data.users.find(
			(user) => user.id === this.usersService.selfUser.id
		);
		if (currentUser) {
			if ("demand" in currentUser.options && currentUser.options.demand) {
				logger.log("Your request is waiting.");
				this.callStorage.accessStatus = "pending";
			}
		}
	}

	protected demandHandler(data): void {
		// For students
		if (data.join === true) {
			// Check maybe room is full
			const connectedUsers =
				this.roomService.connectedUsersSubject.getValue();
			const userLimit = this.roomService.userLimitSubject.getValue();
			this.handleRoomInfo(connectedUsers, userLimit);
		} else if (data.join === false) {
			this.callStorage.accessStatus = "denied";
		}
		this.displaySettings = undefined;
	}

	handleRoomInfo(connectedUsers: number, userLimit: number): void {
		if (connectedUsers >= userLimit) {
			this.callStorage.accessStatus = "roomFull";
			this.dialogRef.close(true);
		} else {
			//   localStorage.setItem('bgColor', roomInfo.styles);
			this.callStorage.accessStatus = "allowed";
			this.dialogRef.close(true);
		}
	}

	displayChanged(displayValue): void {
		this.displaySettings = displayValue;
		this.isAuthError = sessionStorage.wrongPass;
		sessionStorage.wrongPass = false;
	}
	entryAllowed(displayValue): void {
		// this.displaySettings = displayValue;
		this.joinClick();
	}

	isInitMedia(value): void {
		this.childLoading = value;
	}

	proceedToSettings(): void {
		sessionStorage.roomPassword = this.enterCallForm.value.userPassword;
		this.roomAuthService
			.checkJoinPossibility(this.callStorage.roomId)
			.pipe(first())
			.subscribe((value) => {
				if (value.error) {
					// todo remove
					this.isAuthError = true;
				} else {
					this.isAuthError = false;
					this.displaySettings = true;
				}
			});
	}

	enterCall(): void {
		if (!this.enterCallForm.valid) {
			logger.log("login form is invalid ");
			this.isAuthError = true; // ToDo: Add password check for this parameter
			this.displaySettings = false;
		} else {
			logger.log("login form is valid ");
			this.proceedToSettings();
		}
	}
	cancel(buttonClicked?: boolean) {
		this.dialogRef.close(false);
	}
	close(): void {
		this.dialogRef.close();
	}

	public doInputUserPassword(): void {
		if (this.isAuthError) {
			this.isAuthError = false;
		}
	}
}
