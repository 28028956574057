import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { ButtonType } from '../../../call-room/data/ButtonType';
import {AbstractControl, FormGroup, UntypedFormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: [
    './youtube.component.scss',
    './youtube.component.tablet.scss',
    './youtube.component.mobile.scss',
  ],
})
export class YouTubeComponent implements OnInit {
  text: string = '';
  title: string = '';
  cancelButtonData: {text: string, callBack?: () => {} } = {text: ''};
  okButtonData: {text: string, callBack?: (url: string) => {} } = {text: ''};
  youTubeForm: FormGroup;

  get youTubeUrl(): AbstractControl {
    return this.youTubeForm.controls['youTubeUrl'];
  }
  constructor(@Inject(MAT_DIALOG_DATA) private data: {text: string, title: string,
    buttons: { type: string, text: string, callBack: () => {} }[], dialog: MatDialog }) {
  }

  ngOnInit() {
    this.text = this.data.text;
    this.title = this.data.title;
    const okButtons = this.data.buttons.filter(i => i.type === ButtonType.OK);
    if (okButtons.length > 0) {
      this.okButtonData = { text: okButtons[0].text, callBack: okButtons[0].callBack};
    }
    const cancelButtons = this.data.buttons.filter(i => i.type === ButtonType.CANCEL);
    if (cancelButtons.length > 0) {
      this.cancelButtonData = { text: cancelButtons[0].text, callBack: cancelButtons[0].callBack};
    }
    const urlRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    this.youTubeForm = new FormGroup({
      youTubeUrl: new UntypedFormControl('', {
        validators: [Validators.pattern(urlRegex), Validators.required]
      })
    });
  }

  open() {
    if (this.youTubeForm.valid) {
      this.okButtonData.callBack(this.youTubeUrl.value);
      const dialog = this.data.dialog.getDialogById('youtube-dialog');
      if (!!dialog) {
        dialog.close();
      }
    }
  }

}
