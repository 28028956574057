"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var Subscriber_1 = require("../Subscriber");
var Subscription_1 = require("../Subscription");
function finalize(callback) {
  return function (source) {
    return source.lift(new FinallyOperator(callback));
  };
}
exports.finalize = finalize;
var FinallyOperator = function () {
  function FinallyOperator(callback) {
    this.callback = callback;
  }
  FinallyOperator.prototype.call = function (subscriber, source) {
    return source.subscribe(new FinallySubscriber(subscriber, this.callback));
  };
  return FinallyOperator;
}();
var FinallySubscriber = function (_super) {
  __extends(FinallySubscriber, _super);
  function FinallySubscriber(destination, callback) {
    var _this = _super.call(this, destination) || this;
    _this.add(new Subscription_1.Subscription(callback));
    return _this;
  }
  return FinallySubscriber;
}(Subscriber_1.Subscriber);
