import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from "@angular/core";
import { PlatformDetectorService } from "../services/platform-detector/platform-detector.service";
import { VideoSettingsService } from "./service/video-settings.service";
import { RoomStateStorage } from "../components/call-room/service/storage/RoomStateStorage";
// import { BotService } from "../services/bot/bot.service";
import { CustomThemeService } from "../services/custom-theme.service";
import { AuthenticationService } from "../services/authentication/authentication.service";
import { SubRole } from "../constants/subroles-constants";
import { getSubDomain } from "../helpers/url";

@Component({
	selector: "app-video-settings",
	templateUrl: "./video-settings.component.html",
	styleUrls: [
		"./video-settings.component.scss",
		"./video-settings.component.tablet.scss",
		"./video-settings.component.mobile.scss"
	]
})
export class VideoSettingsComponent implements OnInit, AfterViewChecked {
	@Input() displaySettings;
	@Input() videoSettingsCanBeClosed = false;
	@Output() isInitMedia = new EventEmitter<boolean>();
	@Output() doInputUserPassword = new EventEmitter<boolean>();
	@Output() entryAllowed = new EventEmitter<boolean>();
	public win = window;
	public roomLink = location.host + location.pathname;
	public displayPermission = true;
	get isUserNameEntered() {
		return this.videoSettingsService.isUserNameEntered;
	}
	public useDefaultName = false;
	public backgroundColour = "#faeaec"
	subrole: SubRole;

	customWaitingRoomBgColours = {
		'puremusic': '#031725',
		'test-school': '#031725',
	};

	constructor(
		private platformDetectorService: PlatformDetectorService,
		private videoSettingsService: VideoSettingsService,
		private changeDetectorRef: ChangeDetectorRef,
		private roomState: RoomStateStorage,
		//  private botService: BotService,
		private auth: AuthenticationService,
		public customThemeService: CustomThemeService
	) {
		const subdomain = getSubDomain()

		if (this.customWaitingRoomBgColours[subdomain]) {
			this.backgroundColour = this.customWaitingRoomBgColours[subdomain];
		}
	}

	async ngOnInit() {
		this.subrole = await this.auth.profile.then(
			(profile) => profile.subrole
		);

		if (
			[2, 3, 6].includes(this.subrole) &&
			!this.displayPermission &&
			!!window.sessionStorage.getItem("userName") &&
			// !this.videoSettingsService.fromStartPage &&
			!window.localStorage.getItem("callInfoComponent")
		) {
			this.videoSettingsService.isUserNameEntered = true;
		}
		this.setIsDefaultName();
	}

	ngAfterViewChecked() {
		this.changeDetectorRef.detectChanges();
	}

	public isSafariOnIos() {
		return (
			(this.platformDetectorService.isIphone() ||
				this.platformDetectorService.isIPad()) &&
			this.platformDetectorService.isBrowserSafari()
		);
	}

	public isDesktop() {
		//gets around weird ipad bug where it thinks its desktop
		return (
			this.platformDetectorService.isDesktop() &&
			!this.platformDetectorService.isTablet()
		);
	}

	allowedEntry(event) {
		return this.entryAllowed.emit(event);
	}

	isMediaInit(event) {
		return this.isInitMedia.emit(event);
	}

	checkPermission(event) {
		this.displayPermission = event;
	}

	setIsDefaultName() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const isScheduledLesson = !!urlParams.get("roomKey");
		const isTeacher = this.roomState.isTeacher;
		const sessionStorageName =
			sessionStorage.getItem("userName")?.trim() || "";

		if ([2, 3, 6].includes(this.subrole) && sessionStorageName) {
			this.useDefaultName = true;
		}
	}
}
