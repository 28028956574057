import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export enum RoomType {
	PERSONAL = "personal",
	LESSON = "lesson", //single, repeat & trial
	EXTERNAL = "external"
}

@Injectable({
	providedIn: "root"
})
export class RoomService {
	public connectedUsersSubject: BehaviorSubject<number> =
		new BehaviorSubject<number>(0);
	connectedUsers$ = this.connectedUsersSubject.asObservable();

	public userLimitSubject: BehaviorSubject<number> =
		new BehaviorSubject<number>(0);
	userLimit$ = this.userLimitSubject.asObservable();

	public roomType: BehaviorSubject<RoomType> = new BehaviorSubject<RoomType>(
		null
	);
	roomType$ = this.roomType.asObservable();

	constructor() {}

	setConnectedUsers(users: number) {
		this.connectedUsersSubject.next(users);
	}
	setUserLimit(limit: number) {
		this.userLimitSubject.next(limit);
	}
	setRoomType(type) {
		this.roomType.next(type);
	}
}
