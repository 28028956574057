import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

@Component({
	selector: "app-checkout-popup",
	standalone: true,
	imports: [],
	templateUrl: "./checkout-popup.component.html",
	styleUrl: "./checkout-popup.component.scss"
})
export class CheckoutPopupComponent {
	teacher = "";
	constructor(
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA)
		public data: { teacher: string }
	) {
		this.teacher = data.teacher;
	}

	closeDialog() {
		this.dialog.getDialogById("checkout-dialog").close();
	}
}
