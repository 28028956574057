import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ButtonType } from "../components/call-room/data/ButtonType";
import { AvailabilityService } from "../services/availability/availability.service";
import { FormControl, FormGroup, Validators, FormArray } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-add-override",
	templateUrl: "./add-override.component.html",
	styleUrls: ["./add-override.component.scss"]
})
export class AddOverrideComponent implements OnInit {
	cancelButtonData: { text: string; callBack?: () => {} } = { text: "" };
	okButtonData: { text: string; callBack?: (data?: any) => {} } = {
		text: ""
	};
	lessonTimeInterval: string;
	duration: string;
	target: string;
	title: string = "Which times are you not available?";
	timeZone: string;
	slots;
	fullDate;
	blockWholeDay: boolean = false;

	addOverrideForm = new FormGroup({
		timeSlots: new FormArray([
			new FormGroup({
				startTime: new FormControl("10:00", [Validators.required]),
				endTime: new FormControl("11:00", [Validators.required]),
				slots: new FormControl(this.availabilityService.defaultSlots, [
					Validators.required
				]),
				showStartTimeListBox: new FormControl(false, [
					Validators.required
				]),
				showEndTimeListBox: new FormControl(false, [
					Validators.required
				])
			})
		]),

		blockWholeDay: new FormControl(false, [Validators.required])
	});

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialog: MatDialog,
		private availabilityService: AvailabilityService,
		private translateService: TranslateService
	) {}

	get timeSlotsArray(): FormArray {
		return this.addOverrideForm.get("timeSlots") as FormArray;
	}

	addTimeslot() {
		const items = this.addOverrideForm.get("timeSlots") as FormArray;
		if (!items.invalid) {
			items.push(
				new FormGroup({
					startTime: new FormControl("10:00", [Validators.required]),
					endTime: new FormControl("11:00", [Validators.required]),
					slots: new FormControl(
						this.availabilityService.defaultSlots,
						[Validators.required]
					),
					showStartTimeListBox: new FormControl(false, [
						Validators.required
					]),
					showEndTimeListBox: new FormControl(false, [
						Validators.required
					])
				})
			);
		}
	}

	deleteTimeslot(index: number) {
		const items = this.addOverrideForm.get("timeSlots") as FormArray;
		items.removeAt(index);
	}

	ngOnInit() {
		// data.data.start is send from the calendar component

		// Second step to create date for the override data
		const startDate = this.data.data.date;

		// Interval for the view
		this.lessonTimeInterval = this.blockWholeDay
			? "00:00 – 23:30"
			: this.getLessonTimeInterval(startDate);

		this.slots = this.availabilityService.defaultSlots;

		const okButtons = this.data.buttons.filter(
			(i) => i.type === ButtonType.OK
		);
		if (okButtons.length > 0) {
			this.okButtonData = {
				text: okButtons[0].text,
				callBack: okButtons[0].callBack
			};
		}
		const cancelButtons = this.data.buttons.filter(
			(i) => i.type === ButtonType.CANCEL
		);
		if (cancelButtons.length > 0) {
			this.cancelButtonData = {
				text: cancelButtons[0].text,
				callBack: cancelButtons[0].callBack
			};
		}

		this.addOverrideForm.get("blockWholeDay").valueChanges.subscribe(() => {
			this.blockWholeDay =
				this.addOverrideForm.get("blockWholeDay").value;
			this.lessonTimeInterval = this.blockWholeDay
				? "00:00 – 23:30" + "<br>" + this.fullDate
				: this.getLessonTimeInterval(startDate);
			// You can perform any other actions or update your application state here
		});
	}

	get timeSlots(): FormArray {
		return this.addOverrideForm.get("timeSlots") as FormArray;
	}

	onCancelClick() {
		if (this.cancelButtonData.callBack) {
			this.cancelButtonData.callBack();
		}
	}

	onOkClick() {
		// console.log("currentDateInTargetTimezone", currentDateInTargetTimezone);

		if (this.okButtonData.callBack) {
			// this.selected date is the date from the calendar component
			// Third step to create date for the override data: the date is used to create the override data, the hours and minutes are set via the convertToUTC function the input are the form values;

			const timeSlots = [];

			if (!this.blockWholeDay) {
				for (const control of this.addOverrideForm.controls.timeSlots
					.controls) {
					const slot = {
						startTime: control.get("startTime").value,
						endTime: control.get("endTime").value,
						overrideDate: this.data.data.date
					};

					timeSlots.push(slot);
				}
			} else {
				const slot = {
					startTime: null,
					endTime: null,
					overrideDate: this.data.data.date
				};
				timeSlots.push(slot);
			}
			this.okButtonData.callBack({
				dialog: this.dialog,
				dates: timeSlots
			});
		}
	}

	// this function is used to show the time in the view
	getLessonTimeInterval(date: string): string {
		const formattedDate = new Date(date);
		const selectedLanguage = this.translateService.currentLang;

		const dateLocal = selectedLanguage === "eng" ? "en-US" : "de-DE";
		const options: Intl.DateTimeFormatOptions = {
			weekday: "long",
			day: "numeric",
			month: "long"
		};

		const timezoneDateMinusOffset = new Date(
			formattedDate.getTime() - formattedDate.getTimezoneOffset() * 60000
		).toLocaleDateString(dateLocal, options);

		this.fullDate = timezoneDateMinusOffset;

		// init the form with the start and end time
		this.addOverrideForm.controls.timeSlots
			.at(0)
			.controls.startTime.setValue("10:00");
		this.addOverrideForm.controls.timeSlots
			.at(0)
			.controls.endTime.setValue("22:00");

		return `${this.fullDate}`;
	}

	/**
	 * @param {string} type - form control name (e.g. startTime, endTime)
	 **/

	selectSlot(slot, type, timeListBoxControl, index: number) {
		const timeControl =
			this.addOverrideForm.controls.timeSlots.controls.at(index).controls[
				type
			];
		// sets the value of the form control
		timeControl.setValue(slot);
		// hide the list box
		timeListBoxControl.setValue(false);

		// const selectedStartTime = this.addOverrideForm.get("startTime").value;
		// const selectedEndTime = this.addOverrideForm.get("endTime").value;
		// this.lessonTimeInterval = `${selectedStartTime} – ${selectedEndTime} <br>${this.fullDate}`;
	}

	/**
	 * @param {HTMLInputElement} el - The HTML input element.
	 * @param {any} control - The control associated with the input element.
	 * @param {any} time - The time value to be used.
	 * @param {any} timeControl
	 * @param {string} type - The type of the input element.
	 **/

	toggleTimeInput(
		el: HTMLInputElement,
		control,
		time,
		timeControl,
		type: string,
		index: number
	) {
		const itemControl = control;
		itemControl?.value.forEach((slot) => {
			slot.checked = slot.slot === time;
		});
		itemControl.setValue(itemControl?.value);

		timeControl.setValue(!timeControl.value);
		if (type === "endTime") {
			const slotsControl = control;
			const selectedStartIndex = slotsControl.value.findIndex(
				(slot) =>
					slot.slot ===
					this.addOverrideForm.controls.timeSlots.controls.at(index)
						.controls.startTime.value
			);
			slotsControl.value.forEach((slot, index) => {
				slot.hide = index <= selectedStartIndex;
			});
			slotsControl.setValue(slotsControl.value);
			this.addOverrideForm.controls.timeSlots
				.at(index)
				.controls.showStartTimeListBox.setValue(false);
		} else {
			const slotsControl = control;
			const selectedEndIndex = slotsControl.value.findIndex(
				(slot) =>
					slot.slot ===
					this.addOverrideForm.controls.timeSlots.controls.at(index)
						.controls.endTime.value
			);
			slotsControl.value.forEach((slot, index) => {
				slot.hide = index >= selectedEndIndex;
			});
			slotsControl.setValue(slotsControl.value);
			this.addOverrideForm.controls.timeSlots
				.at(index)
				.controls.showEndTimeListBox.setValue(false);
		}
	}

	close(control) {
		control.setValue(false);
	}
}
