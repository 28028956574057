import dayjs from "dayjs";
import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";

export function convertToTime(time: string, timeZone: string): string {
	const d = new Date(time).toLocaleString("en-US", {
		timeZone,
		hour: "2-digit",
		minute: "2-digit",
		hour12: false
	});
	const [hours, minutes] = d.split(":");
	return `${hours}:${minutes}`;
}

export function convertToDate(time: string, timeZone: string): string {
	const d = new Date(time).toLocaleString("en-US", {
		timeZone
	});
	const [month, day] = d.split("/");
	return `${day}.${month}.`;
}

export function convertToDateWithYear(time: string, timeZone: string): string {
	const d = new Date(time).toLocaleString("en-US", {
		timeZone
	});
	const [month, day, year] = d.split("/");
	year.split(",")[0];
	return `${day}.${month}.${year.split(",")[0]}`;
}

export function getDayOfWeek(
	time: string,
	timeZone: string,
	locale: string
): string {
	const d = new Date(time).toLocaleString(locale, {
		timeZone,
		weekday: "long"
	});
	return d;
}

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
	format(date: Date, displayFormat: Object): string {
		if (displayFormat === "input") {
			const options: Intl.DateTimeFormatOptions = {
				weekday: "long",
				day: "2-digit",
				month: "2-digit",
				year: "numeric"
			};
			const language =
				localStorage.getItem("lang") ??
				localStorage.getItem("language") ??
				"eng";
			const locale = language === "deu" ? "de-DE" : "en-GB";
			return (
				new Intl.DateTimeFormat(locale, options)
					.format(date)
					// @ts-ignore
					.replaceAll("/", ".")
			);
		}

		return date.toDateString();
	}

	override getFirstDayOfWeek(): number {
        return 1;
    }
}

// takes HH:mm format
export function addMinutesToTime(time, minutesToAdd) {
	const timeParsed = dayjs(time, "HH:mm");
	const newTime = timeParsed.add(minutesToAdd, "minute");
	return newTime.format("HH:mm");
}

// takes HH:mm format, returns utc timestamp based on current timezone
export function timePickerStringToUTC(time, date) {
	let res = new Date(date);
	const [hours, minutes] = time.split(":").map(Number);
	res.setHours(hours);
	res.setMinutes(minutes);
	return new Date(res.toUTCString()).toISOString();
}


//  some ai generated mess to make material datepicker useable
export class UtcDateAdapter extends NativeDateAdapter {

  override parse(value: any): Date | null {
    if (typeof value === 'string') {
      const [day, month, year] = value.split('/').map(Number);
      return new Date(Date.UTC(year, month - 1, day));
    }
    return super.parse(value);
  }

  override format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      const day = date.getUTCDate().toString().padStart(2, '0');
      const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`;
    }
    return super.format(date, displayFormat);
  }

  override createDate(year: number, month: number, date: number): Date {
    return new Date(Date.UTC(year, month, date));
  }

  override toIso8601(date: Date): string {
    return date.toISOString();
  }

  override deserialize(value: any): Date | null {
    if (typeof value === 'string' && value) {
      return new Date(Date.parse(value));
    }
    return super.deserialize(value);
  }
}
