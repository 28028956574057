<mat-dialog-content>
  <div class="content-outer">
    <div class="caption">
      {{ 'popUp.access-denied' | translate }}
    </div>
    <button class="default-ui-button orange-ui-button" (click)="cancel()">
      {{ 'buttons.cancel' | translate }}
    </button>
  </div>
</mat-dialog-content>
