import { Injectable } from '@angular/core';
import {OrganizationDataService} from '../components/admin/services/organization-data.service';
import {BehaviorSubject} from 'rxjs';
import { getSubDomain } from '../helpers';
import { OrganizationVO } from '../components/admin/data/OrganizationVO';

@Injectable({
  providedIn: 'root'
})
export class CustomThemeService {
  subDomain: string;
  logoURL$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  backgroundURL$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  watermarkURL$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  orgName: string;

  constructor(private organizationDataService: OrganizationDataService) {
    this.subDomain = getSubDomain();
    // this.subDomain = 'tosha'; // Uncomment for local testing
    if (this.subDomain) {
      this.organizationDataService.getBySubdomain(this.subDomain)
        .then((data: OrganizationVO) => {
          this.orgName = data.name;
          if (!!data.logo) {
            this.logoURL$.next(data.logo);
          }
          if (!!data.backgroundImage) {
            this.backgroundURL$.next(data.backgroundImage);
          }
          if (!!data.watermark) {
            this.watermarkURL$.next(data.watermark);
          }
        });
    }
  }
}
