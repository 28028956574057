export const RATING_ROOM_ALIAS = 'rating_RoomAlias';
export const RATING_HIDDEN_HIGHER_PANEL = 'rating_HiddenHigherPanel';

const CN_TRUE = 'true';
const CN_FALSE = 'false';


export class SessionStorageUtil {

  public static setItem(name: string, value: string | null): void {
    if (!!name) {
      if (!!value) {
        window.sessionStorage.setItem(name, value);
      } else {
        window.sessionStorage.removeItem(name);
      }
    }
  }

  public static getItem(name: string): string | null {
    return (!!name ? window.sessionStorage.getItem(name) : null);
  }

  // RatingRoomAlias
  public static writeRatingRoomAliasToSessionStorage(value: string): void {
    SessionStorageUtil.setItem(RATING_ROOM_ALIAS, value);
  }
  public static readRatingRoomAliasFromSessionStorage(): string {
    return SessionStorageUtil.getItem(RATING_ROOM_ALIAS);
  }

  // isHiddenHigherPanel
  public static writeRatingIsHiddenHigherPanelToSessionStorage(value: boolean | null): void {
    if (value === null) {
      SessionStorageUtil.setItem(RATING_HIDDEN_HIGHER_PANEL, '');
    } else {
      SessionStorageUtil.setItem(RATING_HIDDEN_HIGHER_PANEL, (value ? CN_TRUE : CN_FALSE));
    }
  }
  public static readRatingIsHiddenHigherPanelFromSessionStorage(): boolean {
    return (SessionStorageUtil.getItem(RATING_HIDDEN_HIGHER_PANEL) === CN_TRUE);
  }

}
