import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

/**
 * <app-spinner *ngIf="isLoad" [isFullOwner]="true"></app-spinner>
 */

const DEAULT_DIAMETER = 50;


@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent implements OnInit {

  @Input()
  public color: ThemePalette = 'accent';
  @Input()
  public isFullOwner = false;
  @Input()
  public diameter = DEAULT_DIAMETER;

  constructor() { }

  ngOnInit() {
  }

}
