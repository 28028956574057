import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class UtilityService {
	constructor() {}

	getTimeIn15MinSteps() {
		const now = new Date();
		let minutes = now.getMinutes();
		let seconds = now.getSeconds();
		if (seconds > 0) {
			minutes += 1;
		}
		minutes = Math.ceil(minutes / 15) * 15;
		if (minutes === 60) {
			now.setHours(now.getHours() + 1);
			minutes = 0;
		}
		now.setMinutes(minutes);
		now.setSeconds(0);
		return now.toTimeString().slice(0, 5); // Return as HH:MM
	}
}
