type Theme = {
  iconColor: "light" | "dark";
  activeColor: "default" | "dark";
};

export const DEFAULT_BG_COLOR = "#162329";

export const BG_THEMES: {
  [key: string]: Theme;
} = {
  "#162329": { iconColor: "light", activeColor: "default" },
  "#FAEAEC": { iconColor: "dark", activeColor: "default" },
  "#908AF5": { iconColor: "light", activeColor: "dark" },
  "#3794DB": { iconColor: "light", activeColor: "dark" },
  "#D06931": { iconColor: "light", activeColor: "dark" },
};

export const BG_COLORS: string[] = Object.keys(BG_THEMES);
