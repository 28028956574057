<mat-dialog-content>
    <div style="opacity: 0;" class="content">
        <div class="calendar-content">
            <!-- <input style="opacity: 0" matInput [min]="minDate" [max]="maxDate" [ngxMatDatetimePicker]="picker"
                placeholder="Choose a date" [formControl]="dateControl"> -->
            <!-- <ngx-mat-datetime-picker (closed)="datePickerOnClosed()" #picker panelClass='calendar-content'
                [stepHour]="stepHour" [stepMinute]="stepMinute" [enableMeridian]="enableMeridian">
            </ngx-mat-datetime-picker> -->
        </div>
    </div>
</mat-dialog-content>