import { Injectable } from "@angular/core";
import { LessonsService } from "../services/lessons/lessons.service";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class LessonUtils {
	constructor(private lessonsService: LessonsService) {}

	getStudentsWithLessonPackages(data: any): Observable<any> {
		const { list } = data;

		const lessonPackageObservables = list.map((student: any) =>
			this.lessonsService.getLessonPackage(student.email).pipe(
				map((lessonPackage) => ({
					...student,
					lessonPackage: lessonPackage
				})),
				catchError((error) => {
					console.error(
						`Failed to get lesson package for ${student.email}`,
						error
					);
					return of({
						...student
					});
				})
			)
		);

		return forkJoin(lessonPackageObservables).pipe(
			map((studentsWithPackages: any[]) => {
				const filteredStudents = studentsWithPackages.filter(
					(student) => student?.lessonPackage?.active === true
				);
				return {
					...data,
					list: filteredStudents
				};
			})
		);
	}

	setPackageLessonStatus(status: string): string {
		switch (status) {
			case "pending":
				return "lessons-overview.package-status.pending";
			case "active":
				return "lessons-overview.package-status.active";
			case "pastdue":
				return "lessons-overview.package-status.pastdue";
			case "paused":
				return "lessons-overview.package-status.paused";
			case "unpaid":
				return "lessons-overview.package-status.unpaid";
			case "canceled":
				return "lessons-overview.package-status.canceled";
			default:
				return "Unknown";
		}
	}
}
