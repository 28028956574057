"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var Subscriber_1 = require("../Subscriber");
function isEmpty() {
  return function (source) {
    return source.lift(new IsEmptyOperator());
  };
}
exports.isEmpty = isEmpty;
var IsEmptyOperator = function () {
  function IsEmptyOperator() {}
  IsEmptyOperator.prototype.call = function (observer, source) {
    return source.subscribe(new IsEmptySubscriber(observer));
  };
  return IsEmptyOperator;
}();
var IsEmptySubscriber = function (_super) {
  __extends(IsEmptySubscriber, _super);
  function IsEmptySubscriber(destination) {
    return _super.call(this, destination) || this;
  }
  IsEmptySubscriber.prototype.notifyComplete = function (isEmpty) {
    var destination = this.destination;
    destination.next(isEmpty);
    destination.complete();
  };
  IsEmptySubscriber.prototype._next = function (value) {
    this.notifyComplete(false);
  };
  IsEmptySubscriber.prototype._complete = function () {
    this.notifyComplete(true);
  };
  return IsEmptySubscriber;
}(Subscriber_1.Subscriber);
