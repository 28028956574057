import { Injectable } from '@angular/core';

import { UsersService } from '../users.service';
import { WebSocketService } from './web-socket.service';


export enum SOCKET_EVENT {
  AFFECT = 'affect',
  // CHAT  = 'chat',
  // DEMAND  = 'demand',
  // ERROR  = 'error',
  GONE = 'gone',
  // HIDE  = 'hide',
  JOIN  = 'join',
  JOIN_SPY  = 'joinSpy',
  // KICK  = 'kick',
  // MUTE  = 'mute',
  // RECORDING  = 'recording'
  // ROOM  = 'room',
  // SEND  = 'send',
  TURN = 'turn',
  VIEW = 'view',
  EXCHANGE = 'exchange',
  NOTIFY = 'notify'
}

export interface ExchangeData {
  name: string;
  unmute?: boolean;
  unhide?: boolean;
  style?: string;
}

@Injectable({
  providedIn: 'root'
})
export class EventSocketService {

  constructor(
    private webSocketService: WebSocketService,
    private usersService: UsersService,
  ) {
  }

  public affect(option: string, target: string): void {
    this.webSocketService.dispatch(SOCKET_EVENT.AFFECT, { option, target });
  }

  public gone(room: string, user: string, agent: string, device: string, camera: string, microphone: string): void {
    this.webSocketService.dispatch(SOCKET_EVENT.GONE,
      {
        room,
        user,
        agent,
        device,
        camera,
        microphone
      });
  }

  public turn(option: string, status: boolean, currentUserId?: string, reconnectTurn?: boolean): void {
    this.webSocketService.dispatch(SOCKET_EVENT.TURN,
      {
        option,
        status,
        currentUserId: (currentUserId || this.currentUserId()),
        reconnectTurn: (reconnectTurn || this.reconnectTurn())
      });
  }

  public view(room: string, reconnectView?: boolean): void {
    this.webSocketService.dispatch(SOCKET_EVENT.VIEW, {
      room,
      reconnectView: (reconnectView || this.reconnectView())
    });
  }

  public exchange(name: string, unmute: boolean, unhide: boolean, userId: string): void {
    if (!!name && (unmute || unhide)) {
      const data: ExchangeData = { name, unmute, unhide };
      this.webSocketService.dispatch(SOCKET_EVENT.EXCHANGE, { data, target: userId });
    }
  }

  // ** Private API **

  private currentUserId(): string {
    return this.usersService.selfUser.id;
  }

  private reconnectTurn(): boolean {
    const reconnectTurn = (sessionStorage.getItem('reconnectTurn') === 'true');
    return (reconnectTurn != null ? reconnectTurn : false);
  }

  private reconnectView(): boolean {
    const reconnectView = (sessionStorage.getItem('reconnectView') === 'true');
    return (reconnectView != null ? reconnectView : false);
  }

  public notify(roomId: string, data: any): void {
    this.webSocketService.dispatch(SOCKET_EVENT.NOTIFY, { roomId, data });
  }

}
