import {
	MAIN_VIDEO,
	SELF_VIDEO,
} from "../components/call-room/data/local-constants";

export function displayGridView(
	event: Event | boolean,
	gridName: string,
	blockName: string,
	ratio: number,
	padding: number,
	controlPanelHeight: number,
	headerHeight: number
): void {
	const participants: NodeListOf<HTMLElement> =
		document.querySelectorAll(blockName);
	const participantsGrid: HTMLElement = document.getElementById(gridName);
	const { innerHeight } = window;
	let containerWidth: number = null;

	const container = document.getElementById("video-component");

	if (container) {
		containerWidth = document.getElementById("video-component").clientWidth;
	}

	let innerWidth = containerWidth
		? Math.min(containerWidth, window.innerWidth)
		: window.innerWidth;
	innerWidth -= padding * 2;

	Array.from(participants as NodeListOf<HTMLElement>).forEach(
		(participant, index) => {
			participant.style.fontSize = participant.clientHeight * 0.25 + "px";

			switch (participants.length) {
				case 1: {
					// if (!event) {
					//   participantsGrid.style.width = innerWidth + "px";
					// }
					// participantsGrid.style.top = headerHeight + "px";
					// // if (innerWidth < participant.clientWidth || innerWidth > 414) {
					// participant.style.height = innerHeight - controlPanelHeight - headerHeight + "px";
					// participant.style.width = participant.clientHeight * ratio + "px  !important";
					// // } else {
					// //   participant.style.width = innerWidth + "px";
					// //   participant.style.height = participant.clientWidth / ratio + "px";
					// // }
					// //   participantsGrid.style.width = participant.clientWidth + 'px';

					// participantsGrid.style.top =
					//   Math.floor((innerHeight - participant.clientHeight - controlPanelHeight) / 2) + headerHeight / 2 + "px";
					// break;

					if (!event) {
						participantsGrid.style.width = innerWidth + "px";
					}
					if (
						innerHeight <=
							participant.clientHeight +
								(controlPanelHeight - 1) +
								(headerHeight - 1) &&
						innerWidth >= participant.clientWidth
					) {
						participant.style.height =
							innerHeight -
							(controlPanelHeight - 1) -
							(headerHeight - 1) +
							"px";
						participant.style.width =
							Math.floor(participant.clientHeight * ratio) + "px";
						participantsGrid.style.width =
							participant.clientWidth + 1 + "px";
						participantsGrid.style.top = 0 + headerHeight + "px";
					} else {
						participant.style.width = Math.floor(innerWidth) + "px";
						participant.style.height =
							Math.floor(participant.clientWidth / ratio) + "px";
						participantsGrid.style.width = innerWidth + "px";
						participantsGrid.style.top =
							Math.floor(
								(innerHeight -
									participant.clientHeight -
									controlPanelHeight +
									headerHeight) /
									2
							) + "px";
					}

					break;
				}
				case 2: {
					if (
						innerWidth >= 0 &&
						innerWidth + padding * 2 <= 1024 &&
						innerWidth < innerHeight - padding * 2
					) {
						participantsGrid.style.width = innerWidth + "px";

						const availableHeight =
							innerHeight - controlPanelHeight - headerHeight;

						if (
							participant.clientWidth >= innerWidth &&
							participants[0].clientWidth ===
								participants[1].clientWidth &&
							participant.clientHeight * 2 <=
								availableHeight - padding
						) {
							participant.style.width = innerWidth + "px";
							participant.style.height =
								Math.floor(participant.clientWidth / ratio) +
								"px";
							participantsGrid.style.top =
								Math.floor(
									(innerHeight -
										participant.clientHeight * 2) /
										2
								) -
								controlPanelHeight / 2 +
								headerHeight / 2 -
								padding / 2 +
								"px";
							participantsGrid.style.width = innerWidth + "px";
						} else {
							participantsGrid.style.top =
								0 + headerHeight + "px";
							participant.style.height =
								Math.floor(
									(innerHeight - 1) / 2 -
										controlPanelHeight / 2 -
										headerHeight / 2
								) -
								padding / 2 +
								"px";
							participant.style.width =
								Math.floor(participant.clientHeight * ratio) +
								"px";
							participantsGrid.style.width =
								Math.floor(participant.clientWidth) + "px";
						}
					} else {
						if (!event) {
							participantsGrid.style.width = innerWidth + "px";
						}
						if (
							innerHeight <=
								participant.clientHeight +
									(controlPanelHeight - 1) +
									(headerHeight - 1) &&
							innerWidth >= participant.clientWidth * 2 + padding
						) {
							participant.style.height =
								innerHeight -
								(controlPanelHeight - 1) -
								(headerHeight - 1) +
								"px";
							participant.style.width =
								Math.floor(participant.clientHeight * ratio) +
								"px";
							participantsGrid.style.width =
								participant.clientWidth * 2 +
								padding +
								1 +
								"px";
							participantsGrid.style.top =
								0 + headerHeight + "px";
						} else {
							participant.style.width =
								Math.floor(innerWidth / 2) - padding / 2 + "px";
							participant.style.height =
								Math.floor(participant.clientWidth / ratio) +
								"px";
							participantsGrid.style.width = innerWidth + "px";
							participantsGrid.style.top =
								Math.floor(
									(innerHeight -
										participant.clientHeight -
										controlPanelHeight +
										headerHeight) /
										2
								) + "px";
						}
					}
					break;
				}
				default: {
					if (!event) {
						participantsGrid.style.width = innerWidth + "px";
					}

					if (innerHeight <= 480) {
						if (
							innerHeight <=
								participant.clientHeight +
									(controlPanelHeight - 1) +
									(headerHeight - 1) &&
							innerWidth >=
								(participant.clientWidth + padding) *
									participants.length -
									padding
						) {
							participant.style.height =
								innerHeight -
								(controlPanelHeight - 1) -
								(headerHeight - 1) +
								"px";
							participant.style.width =
								Math.floor(participant.clientHeight * ratio) +
								"px";
							participantsGrid.style.width =
								Math.floor(
									(participant.clientWidth + padding) *
										participants.length -
										padding
								) + "px";
						} else {
							const paddingTotal =
								(participants.length - 1) * padding;
							participant.style.width =
								Math.floor(
									(innerWidth - paddingTotal) /
										participants.length
								) + "px";
							participant.style.height =
								Math.floor(participant.clientWidth / ratio) +
								"px";
							participantsGrid.style.width = innerWidth + "px";
						}
						participantsGrid.style.top =
							Math.floor(
								(innerHeight -
									participant.clientHeight -
									controlPanelHeight +
									headerHeight) /
									2
							) + "px";
					} else {
						if (
							innerWidth <= participantsGrid.clientWidth &&
							innerHeight >
								participant.clientHeight * 2 +
									(headerHeight - 1) +
									(controlPanelHeight - 1) +
									padding
						) {
							participant.style.width =
								Math.floor(innerWidth / 2) - padding / 2 + "px";
							participantsGrid.style.width = innerWidth + "px";
							participant.style.height =
								participant.clientWidth / ratio + "px";
						} else {
							participant.style.height =
								Math.floor(
									innerHeight / 2 -
										controlPanelHeight / 2 -
										headerHeight / 2
								) -
								padding / 2 +
								"px";
							participant.style.width =
								Math.floor(participant.clientHeight * ratio) +
								"px";
							participantsGrid.style.width =
								Math.floor(participant.clientWidth * 2) +
								padding +
								"px";
						}
						participantsGrid.style.top =
							Math.floor(
								(innerHeight -
									participant.clientHeight * 2 -
									controlPanelHeight +
									headerHeight) /
									2
							) + "px";
					}
				}
			}
		}
	);
}

export function getSelfVideo(isOldVariant: boolean): HTMLVideoElement {
	if (!isOldVariant) {
		return null;
	}
	const selfVideo = document.getElementById(SELF_VIDEO).children[0]
		.children[0] as HTMLVideoElement;
	selfVideo.muted = true;
	return selfVideo;
}

export function getMainVideo(isOldVariant): HTMLVideoElement {
	if (!isOldVariant) {
		return null;
	}
	return document.getElementById(MAIN_VIDEO).children[0]
		.children[0] as HTMLVideoElement;
}

export function removeChildById(id: string) {
	const item = document.getElementById(id);
	item.parentNode.removeChild(item);
}

export function getVideoById(id: string, isOldVariant: boolean) {
	if (!isOldVariant) {
		return null;
	}
	try {
		const item = document.getElementById(id);
		if (item.tagName === "USER-CALL") {
			// todo refactor
			return document.getElementById(id).children[0].children[0]
				.children[0] as HTMLVideoElement;
		} else {
			return item.children[0].children[0] as HTMLVideoElement;
		}
	} catch (e) {
		return null;
	}
}
