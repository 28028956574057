"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var innerSubscribe_1 = require("../innerSubscribe");
function sample(notifier) {
  return function (source) {
    return source.lift(new SampleOperator(notifier));
  };
}
exports.sample = sample;
var SampleOperator = function () {
  function SampleOperator(notifier) {
    this.notifier = notifier;
  }
  SampleOperator.prototype.call = function (subscriber, source) {
    var sampleSubscriber = new SampleSubscriber(subscriber);
    var subscription = source.subscribe(sampleSubscriber);
    subscription.add(innerSubscribe_1.innerSubscribe(this.notifier, new innerSubscribe_1.SimpleInnerSubscriber(sampleSubscriber)));
    return subscription;
  };
  return SampleOperator;
}();
var SampleSubscriber = function (_super) {
  __extends(SampleSubscriber, _super);
  function SampleSubscriber() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.hasValue = false;
    return _this;
  }
  SampleSubscriber.prototype._next = function (value) {
    this.value = value;
    this.hasValue = true;
  };
  SampleSubscriber.prototype.notifyNext = function () {
    this.emitValue();
  };
  SampleSubscriber.prototype.notifyComplete = function () {
    this.emitValue();
  };
  SampleSubscriber.prototype.emitValue = function () {
    if (this.hasValue) {
      this.hasValue = false;
      this.destination.next(this.value);
    }
  };
  return SampleSubscriber;
}(innerSubscribe_1.SimpleOuterSubscriber);
