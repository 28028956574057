<!--<div class="logo-outer"><a><img alt="Sirius" class="logo" src="assets/icons/sirius/Sirius.png"></a></div>-->
<div class="popup-outer-container">
	<!-- <p> no</p> -->
	<!--  <div class="popup-inner-container" *ngIf="!displaySettings">-->
	<!--    <h2>{{ 'lobby-popup-desktop.title' | translate }}</h2>-->
	<!--    <div class="content">-->
	<!--      <form id="enterCallForm" (ngSubmit)="enterCall()" [formGroup]="enterCallForm">-->
	<!--        <label for="userPassword">{{ 'lobby-popup-desktop.password-label' | translate }}</label>-->
	<!--        <div class="password-field-outer">-->
	<!--          <input id="userPassword" [placeholder]="'lobby-popup-desktop.password-placeholder' | translate"-->
	<!--                 formControlName="userPassword"-->
	<!--                 onkeyup="this.value = this.value.replace(/\s/g,'');"-->
	<!--                 (change)="replaceSpace($event)"-->
	<!--                 (input)="doInputUserPassword()"-->
	<!--                 type="{{showPass ? 'text' : 'password'}}"-->
	<!--                 maxlength="60"-->
	<!--            [ngStyle]="{'border-color': !enterCallForm.controls['userPassword'].valid && enterCallForm.controls['userPassword'].dirty ? '#FF1818' : ''}">-->
	<!--          <div class="toggle-password-visibility" [ngClass]="{'show': showPass, 'hide': !showPass}"-->
	<!--            (click)="togglePass()"></div>-->
	<!--        </div>-->
	<!--        <h6-->
	<!--          [hidden]="enterCallForm.controls['userPassword'].valid || !enterCallForm.controls['userPassword'].dirty"-->
	<!--          class="error-message">-->
	<!--          {{ 'lobby-popup-desktop.password-incorrect' | translate }}-->
	<!--        </h6>-->
	<!--        <h6 class="error-message" [hidden]="!isAuthError">-->
	<!--          {{ 'lobby-popup-desktop.password-is-not-correct' | translate }}-->
	<!--        </h6>-->
	<!--        <div class="row-container">-->
	<!--          <button class="default-ui-button transparent-ui-border-button common-button" type="button" (click)="cancel()">-->
	<!--            {{ 'buttons.cancel' | translate }}-->
	<!--          </button>-->
	<!--          <button class="default-ui-button blue-ui-button common-button" type="submit" [disabled]="!enterCallForm.valid">-->
	<!--            {{ 'buttons.continue' | translate }}-->
	<!--          </button>-->
	<!--        </div>-->
	<!--        <div *ngIf="this.callStorage.serverData.personalRoom && !(authenticationService.isLoggedIn$ | async)" class="teacher-login">-->
	<!--          <span>{{ 'lobby-popup-desktop.your-classroom' | translate }} </span>-->
	<!--          <a routerLink="/login">{{ 'buttons.login' | translate }}</a>-->
	<!--        </div>-->
	<!--        <div class="guest-enter-caption" [innerHTML]="'lobby-popup-desktop.caption' | translate | safeHtml"></div>-->
	<!--      </form>-->
	<!--    </div>-->
	<!--  </div>-->
	<div [class.full-screen-loading]="childLoading" *ngIf="this.isAuthError">
		<app-language-selector *ngIf="!isUserLogged" />
		<app-video-settings
			[displaySettings]="displaySettings"
			(entryAllowed)="entryAllowed($event)"
			(cancel)="cancel()"
			(isInitMedia)="isInitMedia($event)"
		>
		</app-video-settings>
	</div>
	<div [class.full-screen-loading]="childLoading" *ngIf="!this.isAuthError">
		<app-language-selector *ngIf="!isUserLogged" />
		<app-video-settings
			[displaySettings]="displaySettings"
			(doInputUserPassword)="doInputUserPassword()"
			[videoSettingsCanBeClosed]="videoSettingsCanBeClosed"
			(entryAllowed)="entryAllowed($event)"
			(cancel)="cancel()"
			(isInitMedia)="isInitMedia($event)"
		>
		</app-video-settings>
	</div>
</div>
