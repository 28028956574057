"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var TimeoutErrorImpl = function () {
  function TimeoutErrorImpl() {
    Error.call(this);
    this.message = 'Timeout has occurred';
    this.name = 'TimeoutError';
    return this;
  }
  TimeoutErrorImpl.prototype = Object.create(Error.prototype);
  return TimeoutErrorImpl;
}();
exports.TimeoutError = TimeoutErrorImpl;
