import { Component, ViewEncapsulation, forwardRef, AfterViewInit, ElementRef, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import intlTelInput from "intl-tel-input";

@Component({
	selector: "app-intl-tel-input",
	templateUrl: "./intl-tel-input.component.html",
	styleUrls: ["./intl-tel-input.component.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => IntlTelInputComponent),
			multi: true,
		},
	],
	encapsulation: ViewEncapsulation.None,
})
export class IntlTelInputComponent implements ControlValueAccessor, AfterViewInit {
	private iti: any;
	value: any;

	onChange: any = () => {};
	onTouch: any = () => {};

	@ViewChild('phoneInput', { static: true }) phoneInput: ElementRef;

	ngAfterViewInit() {
		const input = this.phoneInput.nativeElement as HTMLInputElement;

		if (!this.iti) {
			this.iti = intlTelInput(input, {
				utilsScript: "assets/javascript/utils.js",
				preferredCountries: ["de", "at", "ch"],
				localizedCountries: {
					de: "Deutschland",
					at: "Österreich",
					ch: "Schweiz",
				},
				autoInsertDialCode: true,
				formatOnDisplay: true,
				separateDialCode: true,
			});
		}

		if (this.value) {
			this.iti.setNumber(this.value);
		}

		input.addEventListener('keyup', (e) => this.onNumberChange(e));
	}

	onNumberChange(e: Event) {
		this.value = this.iti.getNumber();
		this.onChange(this.value);
		this.onTouch();
	}

	writeValue(value: any): void {
		this.value = value;
		if (this.iti && value) {
			this.iti.setNumber(value);
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}
}
