import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from "@angular/core";
import { StudentsService } from "../students/students.service";
import { CommonModule } from "@angular/common";

@Component({
	selector: "app-stripe-pricing-table",
	standalone: true,
	imports: [CommonModule],
	templateUrl: "./stripe-pricing-table.component.html",
	styleUrl: "./stripe-pricing-table.component.scss",
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StripePricingTableComponent {
	@Input() pricingTableId;
	@Input() publishKey;
	@Input() userId;

	public clientSecret: string = "";
	constructor(private studentsService: StudentsService) {}

	ngOnInit() {
		this.studentsService.getPaymentSession(this.userId).subscribe((res) => {
			this.clientSecret = res.client_secret;
		});
	}
}
