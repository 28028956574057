"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var isArray_1 = require("../util/isArray");
var combineLatest_1 = require("../observable/combineLatest");
var from_1 = require("../observable/from");
var none = {};
function combineLatest() {
  var observables = [];
  for (var _i = 0; _i < arguments.length; _i++) {
    observables[_i] = arguments[_i];
  }
  var project = null;
  if (typeof observables[observables.length - 1] === 'function') {
    project = observables.pop();
  }
  if (observables.length === 1 && isArray_1.isArray(observables[0])) {
    observables = observables[0].slice();
  }
  return function (source) {
    return source.lift.call(from_1.from([source].concat(observables)), new combineLatest_1.CombineLatestOperator(project));
  };
}
exports.combineLatest = combineLatest;
