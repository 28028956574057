import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from "@angular/forms";
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef,
} from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { RecordingListItem } from "src/app/services/recording/recording.service";

@Component({
	selector: "app-recording-details",
	standalone: true,
	templateUrl: "./recording-details.component.html",
	styleUrl: "./recording-details.component.scss",
	imports: [CommonModule, TranslateModule, MatDialogModule, ReactiveFormsModule],
})
export class RecordingDetailsComponent {
	public form: FormGroup;
	constructor(
		@Inject(MAT_DIALOG_DATA)
		private data: RecordingListItem,
		private dialogRef: MatDialogRef<RecordingDetailsComponent>
	) {
		this.form = new FormGroup({
			name: new FormControl(data.name, [
				Validators.required,
				Validators.maxLength(255),
			]),
		});
	}
	public onSubmit() {
		if (this.form.valid) {
			this.dialogRef.close(this.form.value.name);
		}
	}

	public close(): void {
		this.dialogRef.close(false);
	}
}
