"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function not(pred, thisArg) {
  function notPred() {
    return !notPred.pred.apply(notPred.thisArg, arguments);
  }
  notPred.pred = pred;
  notPred.thisArg = thisArg;
  return notPred;
}
exports.not = not;
