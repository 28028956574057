import { logger } from '../lib-core/logger';

export function playDummySound(): void {
  // Workaround for iOS browsers, which won't play any audio until one is played on user's gesture
  const audioWorkaround: HTMLAudioElement = new Audio('/assets/EnterSound.mp3');
  audioWorkaround.volume = 0; // Because it's a workaround
  audioWorkaround.play()
  .then(() => {
    logger.log('audioWorkaround played successfully');
  }).catch((error) => {
    console.error('audioWorkaround play error', error);
});
}
