<div class="container">
    <mat-calendar [minDate]="todayDate" (selectedChange)="dateChange($event)" color="primary" class="calendar-content"
        [(selected)]="selected"></mat-calendar>
    <span class="text-xs ml-3 font-bold">{{'calendar.select-time' | translate}}</span>
    <div class="timepicker">
        <ngx-timepicker-field [format]="24" [defaultTime]="'16:00'" (timeChanged)="timeChange($event)" [minutesGap]="15"
            controlOnly="true"></ngx-timepicker-field>
    </div>

    <div *ngIf="type !== 'reschedule'" class="ml-3">
        <form>
            <mat-form-field>
                <mat-label>{{'lessons.repetition' | translate}}</mat-label>
                <mat-select [formControl]="repeatTypeValue" name="repeat">
                    @for (item of repeatTypeList; track item) {
                    <mat-option [value]=item.value>{{item.title | translate}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </form>
    </div>


    <button (click)="nextButton(type)" *ngIf="dateChanged"
        class="blue-ui-button ml-auto block mr-2 mb-2 mt-2">{{'buttons.next'|translate}}</button>
</div>