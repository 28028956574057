import { Injectable, OnDestroy } from '@angular/core';
import { ERROR, JOIN, ROOM, VIEW, WebSocketService } from './web-socket.service';
import { EventSocketService } from './event-socket.service';
import { RtcStreamViewModel } from '../../components/call-room/service/viev-model/rtc-stream-view-model';
import { UserVO } from '../data/UserVO';
import { CallStorage } from '../../components/call-room/service/storage/call-storage.service';
import { UsersService } from '../users.service';
import { LanguageVo } from '../data/LanguageVo';
import { RoomStateStorage } from '../../components/call-room/service/storage/RoomStateStorage';
import { Observable, Subject } from 'rxjs';
import { RoomState } from '../../components/call-room/data/room-state';
import { CONTACT_URL } from '../../constants/generic.constants';
import { PermissionsService } from '../../permissions.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '@angular/router';
import { logger } from 'src/app/lib-core/logger';
import { PlayerStateService } from '../youtube/player-state.service';
import { RoomService } from '../room.service';

@Injectable({
  providedIn: 'root'
})
export class RoomDataParserService implements OnDestroy {
  public redirectLink = CONTACT_URL;
  public isError: Subject<object> = new Subject<object>();
  private selfUser: UserVO;
  private state: Subject<RoomState> = new Subject<RoomState>();

  constructor(
    private roomService: RoomService,
    private webSocketService: WebSocketService,
    private rtcStreamVM: RtcStreamViewModel,
    public callStorage: CallStorage,
    private usersService: UsersService,
    private roomState: RoomStateStorage,
    public authenticationService: AuthenticationService,
    private permissionService: PermissionsService,
    private router: Router,
  ) {
    // logger.log('@RoomDataParserService(); webSocketService.subscribe(ROOM)');
    this.webSocketService.subscribe(ROOM, this.parse); // parse function as handler from below, ws will send ERROR, VIEW or JOIN
  }
  ngOnDestroy(): void {
    this.unsubscribe();
  }
  public getState = (): Observable<RoomState> => {
    return this.state;
  }


  // Parse function: Important Step for joining room / creating room. When a WS answer comes fron webSocketService.subscribe the parse function will act as switch for different cases.

  private parse = (data: any) => {
    data = data[0];
    // logger.groupCollapsed(data._event);
    // logger.log('@', data);
    // logger.groupEnd();
    // logger.log('@RoomDataParser.event() ', data._event, data);
    switch (data._event) {
      case ERROR: {
        if (data.description === 'Wrong room password!') {
          const redirectTo = !!this.authenticationService.isLoggedIn$.value ?  ['platform', 'start'] : [''];
          sessionStorage.isLive = false;
          sessionStorage.reconnectView = false;
          sessionStorage.reconnectJoin = false;
          sessionStorage.reconnectTurn = false;
          if (!this.authenticationService.getRoomEntryPage()) {
            this.router.navigate(redirectTo).then();
          }
        }
        this.isError.next(data);
        break;
      }
      case VIEW: {
        this.callStorage.serverData = data;
        sessionStorage.reconnectView = true;
        this.roomState.isTeacher = !!data.teacher;
        this.updateData(data);
        this.usersService.updateUserVOInfoList(data.users);
        break;
      }
      case JOIN: {
        window.sessionStorage.setItem('skipDemand', 'true');
        this.updateData(data);
        if (data != null) {
          logger.log('@RoomDataParser.parse(JOIN)'
          + '\nnow    ="' + data.now + '",\nnotific="' + data.notificationTime + '"\nexpires="' + data.endTime + '",'
          );
          this.roomState.setPeriodWarningAndRelease(data.now, data.endTime, data.notificationTime);
        }
        break;
      }
      default: {
        this.updateData(data);
      }
    }
  }
  private updateData = (data: any) => {
    try {
      if (data.hasOwnProperty('title')) {
        this.callStorage.updateTitle(data.title);
      }

      if (data.hasOwnProperty('agentUrl')) {
        this.redirectLink = data.agentUrl;
      }
      if (data.hasOwnProperty('userLimit')) {
        this.roomService.setUserLimit(data.userLimit);
      }
      if (data.hasOwnProperty('connected')) {
        this.roomService.setConnectedUsers(data.connected);
      }
      if (data.hasOwnProperty('startTime')) {
        const stime = 'startTime';
        this.callStorage.startTime = Date.parse(data[stime]);
      }
      let event = '';
      if (data.hasOwnProperty('_event')) {
        event = data._event;
      }
      if (data.hasOwnProperty('users')) {
        let users: Array<UserVO> = [];
        users = users.concat(this.parseUsers(data.users));
        this.callStorage.usersStorage.updateUsers(users, true, this.selfUser);
        this.updateUserList(data.users, event === 'gone'); // new variant
        this.authenticationService.permissions = data;
      }
      
      if (data.hasOwnProperty('type')) {
        this.roomService.setRoomType(data.type);
      }

      this.state.next(("live" as RoomState));
      
    } catch (e) {
      console.error(e.message);
      throw new Error('incorrect server data');
    }
  }
  unsubscribe = () => {
    this.webSocketService.unSubscribe(ROOM, this.updateData);
    this.webSocketService = null;
  }
  private parseUsers = (usersObj: Array<any>): Array<UserVO> => {
    const users = new Array<UserVO>();
    let self: UserVO; // only when exist

    //  this map function is used to trigger the updateByServerData function from UserVO.ts
    Object.entries(usersObj).map((userObj: any) => {
      userObj = userObj[1];
      if (userObj.hasOwnProperty('self') && userObj.self) {
        self = new UserVO().updateByServerData(userObj);
        this.selfUser = self;
        if (userObj.hasOwnProperty('permissions')) {
          this.parsePermis(userObj.permissions);
        }
      } else if (this.selfUser && this.selfUser.id === userObj.id) {
        // todo refactor
      } else if (userObj.connected) {
        users.push(new UserVO().updateByServerData(userObj));
      }
    });
    return users;
  }
  private updateUserList = (users: any[], isGone: boolean): void => {
    if (Array.isArray(users) && users.length > 0) {
      const oldSelfUser = this.usersService.selfUser;
      const oldIsMicro = (!!oldSelfUser ? oldSelfUser.isMicro : false);
      const oldIsCamera = (!!oldSelfUser ? oldSelfUser.isCamera : false);

      this.usersService.updateUserList(this.parseUserVO(users));

      const newSelfUser = this.usersService.selfUser;
      const newIsMicro = (!!newSelfUser ? newSelfUser.isMicro : false);
      const newIsCamera = (!!newSelfUser ? newSelfUser.isCamera : false);

      if (newIsMicro !== oldIsMicro) {
        this.rtcStreamVM.enableMicrophone(newIsMicro);
      }
      if (newIsCamera !== oldIsCamera) {
        this.rtcStreamVM.enableCamera(newIsCamera);
      }
      if (isGone) {
        this.usersService.updateUserOnPanelByGone();
      }
      this.usersService.changeUsers();
    }
  }

  private parseUserVO(usersObj: Array<any>): Array<UserVO> {
    const result: Array<UserVO> = [];
    Object.entries(usersObj || []).map((userObj: any) => {
      userObj = userObj[1];
      const newUserVO = new UserVO().updateByServerData(userObj);
      result.push(newUserVO);
      if (userObj.hasOwnProperty('self') && userObj.self) {
        newUserVO.self = true;
        if (userObj.hasOwnProperty('permissions')) {
          this.parsePermis(userObj.permissions);
        }
      }
    });
    return result;
  }


  private parsePermis(data: Array<any>) {
    this.permissionService.camera.next(data.includes('camera'));
    this.permissionService.microphone.next(data.includes('microphone'));
    this.permissionService.display.next(data.includes('screenshare'));
    this.permissionService.risehand.next(data.includes('risehand'));
    this.permissionService.reflection.next(data.includes('reflection'));
    this.permissionService.recording.next(data.includes('recording'));
    this.permissionService.kick.next(data.includes('kick'));
    this.permissionService.mute.next(data.includes('mute'));
    this.permissionService.hide.next(data.includes('hide'));
    this.permissionService.join.next(data.includes('join'));
    this.permissionService.apporover.next(data.includes('apporover'));
  }
}
