const MEDIA_TOGGLE_STATUS_KEY = "room_user_options";
const SESSION_STORAGE_ROOM_ID = "room_id";
const TRIAL_LESSON_END_TIME = "trial_lesson_end_time";

type TrialLessonEndDetails = {
	roomId: string;
	endTime: number;
	teacherName: string;
};

interface MediaExtras {
	isCam?: boolean;
	isMicro?: boolean;
	isReflection?: boolean;
}

export class StorageUtil {
	// Write media parameters to the session storage.
	public static writeMediaPrmsToSessionStorage(saveParam?: MediaExtras) {
		// saveMediaExtras
		if (saveParam) {
			// update
			const updatedState: MediaExtras = Object.assign(
				StorageUtil.readMediaPrmsFromSessionStorage() || {},
				saveParam
			);
			sessionStorage.setItem(
				MEDIA_TOGGLE_STATUS_KEY,
				JSON.stringify(updatedState).toString()
			);
		} else {
			// remove
			sessionStorage.removeItem(MEDIA_TOGGLE_STATUS_KEY);
		}
	}
	// Read media parameters from the session storage.
	public static readMediaPrmsFromSessionStorage(): MediaExtras {
		// loadMediaExtras
		const initParam = { isCam: true, isMicro: true, isReflection: true }; // default value
		const storageParam = JSON.parse(
			sessionStorage.getItem(MEDIA_TOGGLE_STATUS_KEY)
		) as MediaExtras;
		return Object.assign(initParam, storageParam);
	}

	// Write the room ID in the session storage.
	public static writeRoomIdToSessionStorage(roomId: string) {
		if (!!roomId) {
			// update
			sessionStorage.setItem(SESSION_STORAGE_ROOM_ID, roomId);
		} else {
			// remove
			sessionStorage.removeItem(SESSION_STORAGE_ROOM_ID);
		}
	}
	// Read room ID from the session storage.
	public static readRoomIdToSessionStorage(): string {
		return sessionStorage.getItem(SESSION_STORAGE_ROOM_ID) || "";
	}

	/**
	 * writes the lesson end time to the session storage (for the 24 hour 'offer' countdown)
	 *
	 * in the format { roomId: string, endTime: number, teacherName: string }
	 */
	public static writeLessonEndTimeToLocalStorage(
		roomId: string,
		teacherName: string
	) {
		const storedEndTime = StorageUtil.readLessonEndTimeFromLocalStorage();
		const endTime = new Date().getTime();

		if (storedEndTime) {
			const storedEndTimeRoomId = storedEndTime.roomId;
			if (storedEndTimeRoomId === roomId) {
				return;
			}
		}

		localStorage.setItem(
			TRIAL_LESSON_END_TIME,
			JSON.stringify({ roomId, endTime, teacherName })
		);
	}

	public static readLessonEndTimeFromLocalStorage(): TrialLessonEndDetails | null {
		return localStorage.getItem(TRIAL_LESSON_END_TIME)
			? JSON.parse(localStorage.getItem(TRIAL_LESSON_END_TIME))
			: null;
	}
}
