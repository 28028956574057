import {OrganizationVO} from './OrganizationVO';

export class OrganizationParser {
  static parse (dataList: any[]): OrganizationVO[] {
    return dataList.map(item => {
      const org = new OrganizationVO();
      org.id = item._id;
      org.subdomain = item.subdomain;
      org.name = item.name;
      if (item.theme) {
        const logoObj = item.theme.find(themeItem => themeItem.option === 'logo');
        const watermarkObj = item.theme.find(themeItem => themeItem.option === 'watermark');
        const backgroundObj = item.theme.find(themeItem => themeItem.option === 'background');
        org.logo = logoObj ? logoObj.value : undefined;
        org.watermark = watermarkObj ? watermarkObj.value : undefined;
        org.backgroundImage = backgroundObj ? backgroundObj.value : undefined;
      }
      org.apiKey = item.api_key;
      return org;
    });
  }
  static toServerUpdate(org: OrganizationVO): any {
    const updateData: FormData = new FormData();
    updateData.set('name', org.name);
    updateData.set('subdomain', org.subdomain);
    // Attachments (if any)
    if (org.attachments) {
      if (org.attachments.logo) {
        updateData.set('logo', org.attachments.logo.data, org.attachments.logo.name);
      }
      if (org.attachments.watermark) {
        updateData.set('watermark', org.attachments.watermark.data, org.attachments.watermark.name);
      }
      if (org.attachments.background) {
        updateData.set('background', org.attachments.background.data, org.attachments.background.name);
      }
    }
    return updateData;
  }
}
