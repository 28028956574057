import { Component, Inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { LessonsService } from "../services/lessons/lessons.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import {
	FormGroup,
	FormControl,
	FormBuilder,
	Validators
} from "@angular/forms";

@Component({
	selector: "app-cancel-booking",
	standalone: true,
	imports: [
		CommonModule,
		MatButtonModule,
		MatDialogModule,
		TranslateModule,
		ReactiveFormsModule
	],
	templateUrl: "./cancel-booking.component.html",
	styleUrl: "./cancel-booking.component.scss"
})
export class CancelBookingComponent {
	public cancelingError: boolean = false;
	textareaValue: string = "";
	textareaForm: FormGroup;
	startTime: string;
	status: string;

	constructor(
		public dialogRef: MatDialogRef<CancelBookingComponent>,
		private lessonsService: LessonsService,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA)
		public data: { bookingId: string; bookingType: string, startTime: string }
	) {
		this.textareaForm = this.fb.group({
			textarea: ["", Validators.required] // Set an empty initial value
		});
	}

	ngOnInit() {
		this.startTime = this.data.startTime;
		if (this.data.bookingType == "SINGLE") {
			this.status = "canceled";
		}
		else if (this.data.bookingType == "TRIAL") {
			this.status = "cancel_request";
		}
		else {
			this.status = "canceled";
		}
	}

	closeDialog() {
		this.dialogRef.close();
	}

	get startTimeUnder24Hours() {
		const currentTime = new Date().getTime();
		const bookingTime = new Date(this.startTime).getTime();
		const timeDifference = bookingTime - currentTime;
		const hoursDifference = timeDifference / (1000 * 3600); // time difference in hours
		return hoursDifference < 24;
	}

	cancelBooking() {
		this.lessonsService
			.updateBooking(this.data.bookingId, {
				status: this.status,
				cancelReason: this.textareaForm.value.textarea
			})
			.subscribe({
				next: () => {
					this.dialogRef.close();
					this.lessonsService.updateBookingList$.next();
				},
				error: (err) => {
					this.cancelingError = true;
				}
			});
	}
}
