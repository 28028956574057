import { Injectable } from '@angular/core';
import { Observable, from, Subject } from "rxjs";
import { map,filter,flatMap,mergeMap} from 'rxjs/operators'
declare const navigator: any;

@Injectable({
  providedIn: 'root'
})

export class MidiService {
  private midiAccess$: Observable<any>;
  private inputStream$: Observable<any>;
  private messages$: Observable<any>;

// mapping for midi notes, midinumber -> position in piano key array   
private noteTransforms = {
    36: '0', 37: '1', 38: '2', 39: '3', 40: '4', 41: '5',
    42: '6', 43: '7', 44: '8', 45: '9', 46: '10', 47: '11',
    48: '12', 49: '13', 50: '14', 51: '15', 52: '16', 53: '17',
    54: '18', 55: '19', 56: '20', 57: '21', 58: '22', 59: '23',
    60: '24', 61: '25', 62: '26', 63: '27', 64: '28', 65: '29',
    66: '30', 67: '31', 68: '32', 69: '33', 70: '34', 71: '35',
    72:'36', 73:'37', 74:'38', 75:'39', 76:'40', 77:'41', 78:'42',
    79: '43', 80: '44', 81: '45', 82: '46', 83: '47', 84: '48',
    85: '49', 86: '50', 87: '51', 88: '52', 89: '53', 90: '54',
    91: '55', 92: '56', 93: '57', 94: '58', 95: '59', 96: '60', 
    97: '61', 98: '62', 99: '63', 100: '64'
  }; 

  async getMidiInputDevices(): Promise <any> {
    const midiAccess = await navigator.requestMIDIAccess()
    const midiInputs = midiAccess.inputs
    return midiInputs
  }

  get midiInputAccess$():Observable <any> {
    return from(navigator.requestMIDIAccess());
  }

  async getMidiPermissions():Promise<any>{
    return await navigator.permissions.query({ name: "midi", sysex: true })
  }

  getMidiMessages(): Observable<any> {
    if (navigator.requestMIDIAccess()){
    this.midiAccess$ = from(navigator.requestMIDIAccess()); // Promise gets transformed into observeable
    this.inputStream$ = this.midiAccess$.pipe(
        mergeMap((midi: any) => Array.from(midi.inputs.values())), // Convert input values to an array
      );
    this.messages$ = this.inputStream$
      .pipe(filter(input => input !== undefined)) // filter undefined inputs
      .pipe(flatMap(input => this.midiMessageAsObservable(input)))
      .pipe(map((message: any) => {
        const status = message.data[0] & 0xf0;
        return {
          status: status === 144 ? 'PRESSED' : 'RELEASED',
          name: this.noteTransforms[message.data[1]],          
          pressure: message.data[2]
        }}))
    return this.messages$;
    }
    else return null
  }

  private midiMessageAsObservable(input) {
    const source = new Subject();
    input.onmidimessage = note => source.next(note);
    return source.asObservable();
  }


}


