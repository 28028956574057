import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-trial-schedule",
	standalone: true,
	imports: [CommonModule, MatButtonModule, TranslateModule],
	templateUrl: "./trial-schedule.component.html",
	styleUrl: "./trial-schedule.component.scss"
})
export class TrialScheduleComponent {
	teacherName;
	timestamp;
	constructor(
		@Inject(MAT_DIALOG_DATA)
		private data: { teacherName; timestamp },
		private dialogRef: MatDialogRef<TrialScheduleComponent>,
	) {
		this.teacherName = data.teacherName;
		this.timestamp = data.timestamp;
	}

	close(res: boolean) {
		this.dialogRef.close(res);
	}
}
