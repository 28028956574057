import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ButtonType } from "../../../call-room/data/ButtonType";
import {
	FormControl,
	FormGroup,
	UntypedFormControl,
	Validators
} from "@angular/forms";
import { LessonsService } from "../../../../services/lessons/lessons.service";
import {
	Student
} from "src/app/students/students.service";

@Component({
	selector: "app-select-student-dialog",
	templateUrl: "./select-student-dialog.component.html",
	styleUrls: [
		"./select-student-dialog.component.scss",
		"./select-student-dialog.component.tablet.scss",
		"./select-student-dialog.component.mobile.scss"
	]
})
export class SelectStudentDialogComponent implements OnInit {
	text: string = "";
	title: string = "";
	cancelButtonData: { text: string; callBack?: () => {} } = { text: "" };
	okButtonData: {
		text: string;
		callBack?: (student: Student) => {};
	} = { text: "" };
	selectStudentForm: FormGroup;
	studentList: Student[];
	studentListDialog;
	userId: string;
	continueBtnText: string;
	@ViewChild("picker") picker: any;

	public disabled = false;
	public enableMeridian = false;
	public stepHour = 1;
	public stepMinute = 15;
	public stepSecond = 1;
	date = new Date();
	minDate = new Date();
	maxDate = new Date();
	selectedDate: Date;
	timeZone: string;

	public formGroup = new FormGroup({
		date: new FormControl(null, [Validators.required]),
		date2: new FormControl(null, [Validators.required])
	});

	public options = [
		{ value: true, label: "True" },
		{ value: false, label: "False" }
	];
	shouldHideSelectContact;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		private data: {
			userId: string;
			buttons: { type: string; text: string; callBack: () => {} }[];
			dialog: MatDialog;
		},
		private lessonsService: LessonsService
	) {
		this.maxDate.setDate(this.maxDate.getDate() + 30);
	}

	ngOnInit() {
		this.userId = this.data.userId;
		this.lessonsService
			.getActiveRegularStudents(this.userId)
			.subscribe((res) => {
				this.studentList = res.list;
			});

		const okButtons = this.data.buttons.filter(
			(i) => i.type === ButtonType.OK
		);
		if (okButtons.length > 0) {
			this.okButtonData = {
				text: okButtons[0].text,
				callBack: okButtons[0].callBack
			};
			this.continueBtnText = okButtons[0].text;
		}
		const cancelButtons = this.data.buttons.filter(
			(i) => i.type === ButtonType.CANCEL
		);
		if (cancelButtons.length > 0) {
			this.cancelButtonData = {
				text: cancelButtons[0].text,
				callBack: cancelButtons[0].callBack
			};
		}
		this.selectStudentForm = new FormGroup({
			student: new UntypedFormControl("", [Validators.required])
		});
	}

	open() {
		if (this.selectStudentForm.valid) {
			const selectedStudent = this.studentList.find(
				(student) => student.id === this.selectStudentForm.value.student
			);
			this.okButtonData.callBack(selectedStudent);
			const dialog = this.data.dialog.getDialogById(
				"select-student-dialog"
			);

			this.studentListDialog = dialog;
			this.studentListDialog.close();
		}
	}
}
