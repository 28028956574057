"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var Subscriber_1 = require("../Subscriber");
function mapTo(value) {
  return function (source) {
    return source.lift(new MapToOperator(value));
  };
}
exports.mapTo = mapTo;
var MapToOperator = function () {
  function MapToOperator(value) {
    this.value = value;
  }
  MapToOperator.prototype.call = function (subscriber, source) {
    return source.subscribe(new MapToSubscriber(subscriber, this.value));
  };
  return MapToOperator;
}();
var MapToSubscriber = function (_super) {
  __extends(MapToSubscriber, _super);
  function MapToSubscriber(destination, value) {
    var _this = _super.call(this, destination) || this;
    _this.value = value;
    return _this;
  }
  MapToSubscriber.prototype._next = function (x) {
    this.destination.next(this.value);
  };
  return MapToSubscriber;
}(Subscriber_1.Subscriber);
