<ng-container *ngIf="isFullOwner">
  <div class="spnr-backing-ground">
  </div>
  <div class="spnr-front-ground">
    <mat-spinner [color]="color" [diameter]="diameter"></mat-spinner>
  </div>
</ng-container>
<ng-container *ngIf="!isFullOwner">
  <mat-spinner [diameter]="diameter" [color]="color"></mat-spinner>
</ng-container>
