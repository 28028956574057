<div class="popup-body">
  <img class="cross"
       src="/assets/icons/common/cross.svg"
       (click)="close()"
  />
  <h2>
    {{ 'room-info-popup.title' | translate }}
  </h2>
  <h4>
    {{ 'room-info-popup.description' | translate }}
  </h4>
  <div class="gray-bg">
    <h4>
      {{ 'call-room.link' | translate }}
      <br>
      {{ 'call-room.password' | translate }}
    </h4>
    <h4>
      {{link}}
      <br>
      {{password}}
    </h4>
    <img *ngIf="!isCopied" (click)="copy()" class="copy" src="/assets/icons/sirius/InviteParticipantsButton.svg"/>
    <img *ngIf="isCopied" class="copy" src="/assets/icons/sirius/Success.svg"/>
  </div>
  <button (click)="close()" class="close-bttn">
    {{ 'popUp.close' | translate }}
  </button>
</div>
