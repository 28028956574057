import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ButtonType } from "../../../call-room/data/ButtonType";

@Component({
	selector: "app-custom-message-popup",
	templateUrl: "./custom-message.component.html",
	styleUrls: [
		"./custom-message.component.scss",
		"./custom-message.component.tablet.scss",
		"./custom-message.component.mobile.scss"
	]
})
export class CustomMessageComponent implements OnInit {
	text: string = "";
	cancelButtonData: { text: string; callBack?: () => {} } = { text: "" };
	okButtonData: { text: string; callBack?: () => {} } = { text: "" };
	singleBtn = false;
	constructor(
		@Inject(MAT_DIALOG_DATA)
		private data: {
			text: string;
			buttons: { type: string; text: string; callBack: () => {} }[];
			singleBtn: boolean;
		}
	) {}

	ngOnInit() {
		this.text = this.data.text;
		this.singleBtn = this.data.singleBtn;
		const okButtons = this.data.buttons.filter(
			(i) => i.type === ButtonType.OK
		);
		if (okButtons.length > 0) {
			this.okButtonData = {
				text: okButtons[0].text,
				callBack: okButtons[0].callBack
			};
		}
		const cancelButtons = this.data.buttons.filter(
			(i) => i.type === ButtonType.CANCEL
		);
		if (cancelButtons.length > 0) {
			this.cancelButtonData = {
				text: cancelButtons[0].text,
				callBack: cancelButtons[0].callBack
			};
		}
	}
	onCancelClick() {
		if (this.cancelButtonData.callBack) {
			this.cancelButtonData.callBack();
		}
	}
	onOkClick() {
		if (this.okButtonData.callBack) {
			this.okButtonData.callBack();
		}
	}
}
