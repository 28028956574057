import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { MediaProperiesUtil } from '../../lib-setting/components/setting-popup/setting-popup.interface';
import { toBrowserSupportedTrack } from '../../services/utils';
import { EasyRtcService } from '../../lib-rtc/services/easy-rtc.service';

const DELAY_STOP_STREAM = 200;

interface IDeviceService {
  getDevices(): Promise<MediaDeviceInfo[]>;
  getMediaStream(params?: MediaStreamConstraints): Promise<MediaStream>;
  getExistOrNewStream(params?: MediaStreamConstraints): Promise<MediaStreamConstraints>; // all ways return stream if it possible
  stopStream(stream?: MediaStream);
  getUserMedia(params?: MediaStreamConstraints): Promise<MediaStream>;
  getUserMediaByDeviceId(audioDeviceIdInp: string, videoDeviceIdInp: string): Promise<MediaStream>;
  enumerateDevices(isHideDefault?: boolean): Promise<MediaDeviceInfo[]>;
  getDeviceVideoInput(mediaDeviceInfoList: MediaDeviceInfo[]): MediaDeviceInfo[];
  getDeviceAudioOutput(mediaDeviceInfoList: MediaDeviceInfo[]): MediaDeviceInfo[];
  getDeviceAudioInput(mediaDeviceInfoList: MediaDeviceInfo[]): MediaDeviceInfo[];
  findMediaDeviceInfo(mediaDeviceInfoList: MediaDeviceInfo[], mediaDeviceId: string): MediaDeviceInfo | null;
  videoEnable(mediaStream: MediaStream | null, enable: boolean): void;
  audioEnable(mediaStream: MediaStream | null, enable: boolean): void;
  getSupportedConstraints(): MediaTrackSupportedConstraints;
  findFreeMediaStream(): Promise<MediaStream>;
  mediaTracksEnable(trackList: MediaStreamTrack[] | null | undefined, enable: boolean): void;
  findFreeStreamByKind(mediaDeviceList: MediaDeviceInfo[], kind: MediaDeviceKind): Promise<MediaStream | null>;
  findFreeStreamByDeviceKind(kind: MediaDeviceKind): Promise<MediaStream>;
  writeText(text: string): Promise<void>;
  getUserAgentData(): any;
  checkNavigatorPermissions(name: PermissionName): Promise<PermissionStatus>;
  getDisplayVideo(displayConfig): Promise<MediaStream>;
  getDisplayAudio(): Promise<MediaStream>;
  hasGetUserMediaEnable(): boolean;
  onDeviceChange(updateDeviceList$: Observable<MediaDeviceInfo[]>): void;
  hasGetUserMediaEnableForWebRTC(): ((constraints?: MediaStreamConstraints) => Promise<MediaStream>)
    | ((constraints: MediaStreamConstraints, successCallback,
        errorCallback) => void);
}

export interface VideoDisplayConfig {
  width?: number;
  height?: number;
  cursor: string;
  frameRate: number;
}

@Injectable({
  providedIn: 'root'
})
export class MediaDevicesService implements IDeviceService {
  constructor(private easyRtcService: EasyRtcService){}
  public audioOutputDeviceChange$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public static setAlternativeGetUserMediaForUnsupportedBrowsers() {
    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
      (navigator as any).mediaDevices = {};
    }

    // Some browsers partially implement mediaDevices. We can't just assign an object
    // with getUserMedia as it would overwrite existing properties.
    // Here, we will just add the getUserMedia property if it's missing.
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = (constraints) => {
        // First get ahold of the legacy getUserMedia, if present
        const getUserMedia = ((navigator as any).webkitGetUserMedia || (navigator as any).mozGetUserMedia);

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          alert('getUserMedia is not implemented in this browser');
        }

        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
  }

  public getUserMedia(params?: MediaStreamConstraints): Promise<MediaStream> {
    const constraints = params || { audio: true,  video: true };
    return navigator.mediaDevices.getUserMedia(constraints);
  }

  public getUserMediaByDeviceId(audioDeviceIdInp: string, videoDeviceIdInp: string): Promise<MediaStream> {
    const audioDeviceIdVal = (audioDeviceIdInp ? { exact: audioDeviceIdInp } : undefined);
    const videoDeviceIdVal = (videoDeviceIdInp ? { exact: videoDeviceIdInp } : undefined);
    const constraints: MediaStreamConstraints = { audio: { deviceId: audioDeviceIdVal }, video: { deviceId: videoDeviceIdVal }};
    return navigator.mediaDevices.getUserMedia(constraints);
  }

  public async enumerateDevices(isHideDefault?: boolean): Promise<MediaDeviceInfo[]> {
    const result: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
    return (isHideDefault ? result.filter((item) => item.deviceId.length > 14) : result);
  }

  public getDeviceVideoInput(mediaDeviceInfoList: MediaDeviceInfo[]): MediaDeviceInfo[] {
    return (mediaDeviceInfoList || []).filter((mediaDeviceInfo) => mediaDeviceInfo.kind === 'videoinput');
  }

  public getDeviceAudioOutput(mediaDeviceInfoList: MediaDeviceInfo[]): MediaDeviceInfo[] {
    return (mediaDeviceInfoList || []).filter((mediaDeviceInfo) => mediaDeviceInfo.kind === 'audiooutput');
  }

  public getDeviceAudioInput(mediaDeviceInfoList: MediaDeviceInfo[]): MediaDeviceInfo[] {
    return (mediaDeviceInfoList || []).filter((mediaDeviceInfo) => mediaDeviceInfo.kind === 'audioinput');
  }

  public findMediaDeviceInfo(mediaDeviceInfoList: MediaDeviceInfo[], mediaDeviceId: string): MediaDeviceInfo | null {
    let result: MediaDeviceInfo | null = null;
    if (mediaDeviceInfoList.length > 0) {
      if (!!mediaDeviceId) {
        result = mediaDeviceInfoList.find((item) => item.deviceId === mediaDeviceId);
      }
      if (!result) {
        result = mediaDeviceInfoList[0];
      }
    }
    return result;
  }

  public async stopMediaStream(
    mediaStream: MediaStream | null, kind?: 'video' | 'audio', delayStop: number = DELAY_STOP_STREAM
  ): Promise<void> {
    const mediaStreamTracks: MediaStreamTrack[] = (!!mediaStream ? mediaStream.getTracks() : []);
    if (Array.isArray(mediaStreamTracks) && mediaStreamTracks.length > 0) {
      for (const mediaStreamTrack of mediaStreamTracks) {
        if (!kind || mediaStreamTrack.kind === kind) {
          mediaStreamTrack.stop();
        }
      }
      await this.sleep(delayStop);
    }
    return Promise.resolve();
  }

  public videoEnable(mediaStream: MediaStream | null, enable: boolean): void {
    if (!!mediaStream) {
      this.mediaTracksEnable(mediaStream.getVideoTracks(), enable);
    }
  }

  public audioEnable(mediaStream: MediaStream | null, enable: boolean): void {
    if (!!mediaStream) {
      this.mediaTracksEnable(mediaStream.getAudioTracks(), enable);
    }
  }

  public sleep(ms = 100): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public getSupportedConstraints(): MediaTrackSupportedConstraints {
    return navigator.mediaDevices.getSupportedConstraints();
  }

  public async findFreeMediaStream(): Promise<MediaStream> {
    const devices: MediaDeviceInfo[] = await this.enumerateDevices(/*isHideDefault?: boolean*/);
    const streamVideo = await this.findFreeStreamByKind(devices, 'videoinput');
    const streamAudio = await this.findFreeStreamByKind(devices, 'audioinput');
    if (!!streamAudio && !!streamVideo && ('addTrack' in streamVideo)) {
      streamVideo.addTrack(streamAudio.getAudioTracks()[0]);
    }
    return streamVideo;
  }

  // ** Private API **

  private mediaTracksEnable(trackList: MediaStreamTrack[] | null | undefined, enable: boolean): void {
    if (!!trackList && trackList.length > 0) {
      for (const track of trackList) {
        if (track.enabled !== enable) {
          track.enabled = enable;
        }
      }
    }
  }

  private async findFreeStreamByKind(mediaDeviceList: MediaDeviceInfo[], kind: MediaDeviceKind): Promise<MediaStream | null> {
    let resultStream: MediaStream | null = null;
    if (!mediaDeviceList || mediaDeviceList.length === 0 || !kind) {
      return resultStream;
    }
    const devices = mediaDeviceList.filter(d => d.kind === kind && d.deviceId);

    for (let idx = 0; !resultStream && idx < devices.length; idx++) {
      const deviceId = devices[idx].deviceId;
      const constraints: MediaStreamConstraints = {};
      if ('audioinput' === kind) {
        constraints.audio = { deviceId };
      } else if ('videoinput' === kind) {
        constraints.video = { deviceId };
      }
      resultStream = await navigator.mediaDevices.getUserMedia(constraints).catch(() => null);
    }
    return resultStream;
  }

  async stopStream(stream?: MediaStream) {
    if (!stream || !stream.getTracks()) {
      return;
    }
    const tracks: MediaStreamTrack[] = stream.getTracks();
    for (let idx = 0, len = tracks.length; idx < len; idx++) {
      tracks[idx].stop();
    }
    await sleep(400);
  }
  // @ts-ignore
  async getExistOrNewStream(params?: MediaStreamConstraints = { video: true, audio: true } ): Promise<MediaStream> {
    try {
      return this.getMediaStream(params);
    } catch (e) {  // device busy check case
      // todo add check;
      const stream = await this.findFreeStreamByDeviceKind('videoinput');
      const audioStream = await this.findFreeStreamByDeviceKind('audioinput');
      stream.addTrack(audioStream.getAudioTracks()[0]);
      return stream;
    }
  }

  private async findFreeStreamByDeviceKind(kind: MediaDeviceKind): Promise<MediaStream> {
    const devices = (await this.getDevices()).filter(d => d.kind === kind && d.deviceId );
    const kindToParam = {
      videoinput: 'video',
      audioinput: 'audio',
    };
    let stream: MediaStream;
    if (!devices || devices.length === 0) {
      throw new Error(`Permission_Access_Error`.toUpperCase());
    }
    // @ts-ignore
    for (const device: MediaDeviceInfo of devices) {
      const params = { video: false, audio: false };
      params[kindToParam[kind]] = { deviceId: device.deviceId };
      stream = await this.getMediaStream(params).catch( () => null);
      if (stream) { // break case
        break;
      }
    }
    if (!stream) {
      throw new Error(`all_${kind}_busy`.toUpperCase());
    }
    return stream;
  }

  async getMediaStream(params?: MediaStreamConstraints): Promise<MediaStream> {
    try {
      const constraints = params || { audio: true,  video: true };
      return await navigator.mediaDevices.getUserMedia(constraints);
    } catch (e) {
      throw e;
    }
  }

  getDevices(): Promise<MediaDeviceInfo[]> {
    return navigator.mediaDevices.enumerateDevices();
  }

  writeText(text: string): Promise<void> {
    return navigator.clipboard.writeText(text);
  }

  getUserAgentData(): any {
    return (navigator as any).userAgentData;
  }

  checkNavigatorPermissions(name: PermissionName): Promise<PermissionStatus> {
    return navigator.permissions.query({name});
  }

  getDisplayVideo(displayConfig: VideoDisplayConfig): Promise<MediaStream> {
    try {
      if ((navigator  as any).getDisplayMedia) {
        return (navigator  as any).getDisplayMedia({ video: displayConfig, audio: true });
      } else if ((navigator.mediaDevices as any).getDisplayMedia) {
        return (navigator.mediaDevices as any).getDisplayMedia({ video: displayConfig, audio: true });
      } else {
        throw new Error('error display dont found'); // todo add handler
      }
    } catch (exception) {
      console.error(exception);
      throw new Error('error display dont found');
    }
  }

  getDisplayAudio(): Promise<MediaStream> {
    const mediaProperties = MediaProperiesUtil.readMediaProperiesFromStorage();
    const constraints = MediaProperiesUtil.createConstraints(mediaProperties);
    return navigator.mediaDevices.getUserMedia({ audio: toBrowserSupportedTrack(constraints.audio as MediaTrackConstraints) });
  }

  hasGetUserMediaEnable(): boolean {
    return (!!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia);
  }

  hasGetUserMediaEnableForWebRTC(): ((constraints?: MediaStreamConstraints) => Promise<MediaStream>)
    | ((constraints: MediaStreamConstraints, successCallback,
        errorCallback) => void) {
    return ((navigator as any).getUserMedia || navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }

  onDeviceChange(updateDeviceList$: Observable<MediaDeviceInfo[]>): void {
     navigator.mediaDevices.ondevicechange = () => {
       updateDeviceList$.subscribe();
     };
  }

  async registerAudioSettingsChange(deviceId:string, input:boolean) {
  if(input == false) {
  MediaProperiesUtil.writeAudioOutputDeviceToStorage(deviceId);
  }
  if(input == true) {
  MediaProperiesUtil.writeAudioInputDeviceToStorage(deviceId);
  }

  const mediaProperties = MediaProperiesUtil.readMediaProperiesFromStorage();
  // const mediaStream = EasyRtcService.getLocalStream();
  // if (!!mediaStream) {
  //   await this.easyRtcService.stopMediaStreamTrack(mediaStream.getTracks());
  // }
  const constraints = MediaProperiesUtil.createConstraints(mediaProperties);
  return constraints
  }

}

const sleep = (ms = 100) => new Promise(resolve => setTimeout(resolve, ms)); // todo remove hack

