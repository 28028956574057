export enum LessonsStatus {
	PENDING = "pending",
	ACTIVE = "active",
	PAUSED = "paused",
	PASTDUE = "pastdue",
	UNPAID = "unpaid",
	CANCELED = "canceled"
}

export enum ActivationStatus {
	PENDING = "pending",
	ACTIVATED = "activated",
	DEACTIVATED = "deactivated",
	REACTIVATED = "reactivated"
}
