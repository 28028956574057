import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LANGUAGES, GET_USER_PROFILE, TIMEZONES } from 'src/app/constants/connection-constants';

import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

import { ProfileDto } from './profile-api.interface';

const HTTP_OPTIONS = { withCredentials: true };

@Injectable({
  providedIn: 'root'
})
export class ProfileApiService {

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService
  ) {
  }

  // ** Public API **

  public get(): Promise<ProfileDto | HttpErrorResponse> {
    return this.http.get<ProfileDto | HttpErrorResponse>(GET_USER_PROFILE, this.authHttpOptions()).toPromise();
  }

  // public save(data: any): Observable<object> {
  //   return this.http.put(PROFILE, data, this.authHttpOptions());
  // }

  // public getLanguages(): Observable<object> {
  //   return this.http.get(LANGUAGES, HTTP_OPTIONS);
  // }

  // public getTimezones(): Observable<object> {
  //   return this.http.get(TIMEZONES, HTTP_OPTIONS);
  // }

  // ** Private API **

  private authHttpOptions() {
    return {
      ...HTTP_OPTIONS,
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: this.auth.accessToken
      })
    };
  }

}
