import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  camera = new BehaviorSubject<boolean>(true);
  microphone = new BehaviorSubject<boolean>(true);
  display = new BehaviorSubject<boolean>(true);
  risehand = new BehaviorSubject<boolean>(false);
  reflection = new BehaviorSubject<boolean>(false);
  recording = new BehaviorSubject<boolean>(false);
  kick = new BehaviorSubject<boolean>(false);
  mute = new BehaviorSubject<boolean>(false);
  hide = new BehaviorSubject<boolean>(false);
  apporover = new BehaviorSubject<boolean>(false);
  join = new BehaviorSubject<boolean>(false);
  constructor() { }
}
