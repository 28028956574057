"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var EmptyErrorImpl = function () {
  function EmptyErrorImpl() {
    Error.call(this);
    this.message = 'no elements in sequence';
    this.name = 'EmptyError';
    return this;
  }
  EmptyErrorImpl.prototype = Object.create(Error.prototype);
  return EmptyErrorImpl;
}();
exports.EmptyError = EmptyErrorImpl;
