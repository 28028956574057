import { Injectable } from '@angular/core';
import { MediaDevicesService } from '../../lib-media-devices/services/media-devices.service';
import { BehaviorSubject, combineLatest, from, Observable, of, Subject } from 'rxjs';
import { mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { PlatformDetectorService } from '../../services/platform-detector/platform-detector.service';
import { BotService } from '../../services/bot/bot.service';
import { logger } from 'src/app/lib-core/logger';

@Injectable({
  providedIn: 'root'
})

export class VideoSettingsService {
  isUserNameEntered: boolean = false;
  fromStartPage: boolean = false;
  permission = new BehaviorSubject<boolean>(false);
  static mediaPermissions = new BehaviorSubject<any>( {mic: true, cam: false});
  // static mediaPermissions = new BehaviorSubject<any>( {mic: false, cam: false});
  //  changed for testing D.R. 
  devicePermission;
  private unsubscribe$ = new Subject<void>();
  private isSafari: boolean;
  private isFirefox: boolean;


  constructor(private mediaDevicesService: MediaDevicesService,
  private platformDetectorService: PlatformDetectorService
  ) {
    this.isSafari = this.platformDetectorService.isSafari();
    this.isFirefox = this.platformDetectorService.isFirefox();
    if (this.isFirefox) {
      this.permission.subscribe(perm => {
        VideoSettingsService.mediaPermissions.next({mic: perm, cam: perm, camCanBeCalled: true});
      })
    }
  }

  public saveNameEnteredState(enter: boolean) {
    this.isUserNameEntered = enter;
  }

  public async checkSafariPermission(video, audio): Promise<MediaStream | null> {
    // console.log("trigger-two")
    const params = {
      video,
      audio
    };
    try {
      return await this.mediaDevicesService.getUserMedia(params);
    } catch (e) {
      return null;
    }
  }

   public async hasPermission(videoID?, audioId?): Promise<boolean> {

    logger.log(videoID, audioId);
     const params = videoID && audioId ? {
       video: {deviceId: videoID},
       audio: {deviceId: audioId}
     } : {
       video: VideoSettingsService.mediaPermissions.value.camCanBeCalled,
       audio: true
     };

    //  console.log("params");
    //  console.log(params);

     try {
       this.devicePermission = await this.mediaDevicesService.getUserMedia(params);
       logger.log("devicePermission Media stream: ")
       logger.log(this.devicePermission);
       logger.log(!!this.devicePermission);
       return !!this.devicePermission;
     } catch (e) {
       return false;
     }
  }

  checkPermissions(state?): Observable<any> {
    if (BotService.isBot) {
      return of({mic: true, cam: false, camCanBeCalled: false});
    }
    const browser = this.platformDetectorService.deviceInfo.browser;
    if (browser && browser === 'Firefox') {
      const perm = this.permission.getValue();
      return of({ mic: perm, cam: perm, camCanBeCalled: true });
      //  if (state) {
      //    // TODO
      //    return of({ mic: perm, cam: perm, camCanBeCalled: true });
      //  }
      //  return this.permission.pipe(takeUntil(this.unsubscribe$));
    }

    if (!this.isSafari || (this.isSafari && this.platformDetectorService.getSafariVersion() >= 16)) {
      const micPermission = 'microphone' as PermissionName;
      const camPermission = 'camera' as PermissionName;
      const hasMicDevice = 'hasMic' as any;
      const hasCamDevice = 'hasCam' as any;
      const checkAudioPermission = from(this.mediaDevicesService.checkNavigatorPermissions(micPermission))
        .pipe(
          mergeMap(permission => {
            permission[micPermission] = true
            return of(permission);
          }),
          mergeMap((permission) => {
            return from(navigator.mediaDevices.enumerateDevices()).pipe(
              switchMap((devices) => {
                permission[hasMicDevice] = !!devices.find(devInfo => devInfo.kind === 'audioinput');
                return of(permission);
              }));
          })
        );
      const checkCameraPermission = from(this.mediaDevicesService.checkNavigatorPermissions(camPermission))
        .pipe(
          mergeMap(permission => {
            permission[camPermission] = true
            return of(permission);
          }),
          mergeMap((permission) => {
            return from(navigator.mediaDevices.enumerateDevices()).pipe(
              switchMap((devices) => {
                permission[hasCamDevice] = !!devices.find(devInfo => devInfo.kind === 'videoinput');
                return of(permission);
              }));
          })
        );
      return combineLatest([checkAudioPermission, checkCameraPermission]).pipe(
        switchMap(  (response: any) => {
          const hasPermission = response.filter(item => item.state === 'granted');
          let hasMicPermission = !!hasPermission.find(permType => permType[micPermission] === true && permType[hasMicDevice] === true);
          let hasCamPermission = !!hasPermission.find(permType => permType[camPermission] === true && permType[hasCamDevice] === true);
          const camera = response.find(item => item[camPermission] === true);
          const camCanBeCalled = (camera && camera.state !== 'denied' && camera[hasCamDevice] === true);
          VideoSettingsService.mediaPermissions.next({mic: hasMicPermission, cam: hasCamPermission, camCanBeCalled});
          return of({mic: hasMicPermission, cam: hasCamPermission, camCanBeCalled});
        })
      );
    } else {
      return from(this.mediaDevicesService.getDevices()).pipe(
        switchMap(async (response: any) => {
          const hasPermission = response.filter(item => item.deviceId !== '' && (item.kind === 'audioinput' || item.kind === 'videoinput'));
          // TODO
          const perm = hasPermission.length > 1;
          VideoSettingsService.mediaPermissions.next({mic: perm, cam: perm, camCanBeCalled: true});
          return of({mic: perm, cam: perm, camCanBeCalled: true});
          }
        ),
        switchMap((data) => data)
      );
          /* const hasMicDevice = !!hasPermission.find(permType => permType.kind === 'audioinput');
           const hasCamDevice = !!hasPermission.find(permType => permType.kind === 'videoinput');
           const result = {mic: false, cam: false, hasMicDevice, hasCamDevice};
           if (hasMicDevice) {
              const stream = await this.checkSafariPermission(false, hasMicDevice);
             if (!stream) {
               this.mediaPermissions.next(result);
               return of(result);
             } else {
               result.mic = true;
               this.mediaPermissions.next(result);
               return of(result);
             }
           }
           return of(result);
         }),
         switchMap((data) => data),
         switchMap(async (result: any) => {
           const combineResult = result;
           if (combineResult.hasCamDevice) {
             const stream = await this.checkSafariPermission(combineResult.hasCamDevice, false);
             if (!stream) {
               this.mediaPermissions.next(combineResult);
               return of(combineResult);
             } else {
               combineResult.cam = true;
               this.mediaPermissions.next(combineResult);
               return of(combineResult);
             }
           }
           this.mediaPermissions.next(combineResult);
           return of(combineResult);
         }),
         switchMap((data) => data) */
    }
  }

  unsubscribe() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async getDevPerm() {
    const getAudio = navigator.mediaDevices.getUserMedia({audio: true});
    const getVideo = navigator.mediaDevices.getUserMedia({video: true});
    const result = await Promise.all([getAudio, getVideo].map((p: any) => p.catch(e => e)));
    let activeStreams = result.filter(stream => (stream instanceof MediaStream));
    if (activeStreams && activeStreams.length > 0) {
      activeStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
          stream.removeTrack(track);
        });
      })
    }
    activeStreams = null;
    return true;
  }

  stopTrack() {
    if (this.devicePermission) {
      this.devicePermission.getTracks().forEach((track) => track.stop());
      // console.log("stopped device Permission")
    }
  }
}
