<div class="w-full flex justify-between flex-wrap">
	<div>
		<div class="font-bold mb-2">
			{{ "photo-upload.title" | translate }}
		</div>
		<!-- <input #fileUpload hidden type="file" (change)="onFileChanged($event)" /> -->
		<button (click)="uploadPhoto()" mat-flat-button class="s-mat-button" color="primary">
			{{ "buttons.upload-photo" | translate }}
		</button>
		<div class="error mt-2 text-alert" *ngIf="error">
			{{ error | translate }}
		</div>
		<div class="text-xs mt-2">{{ "photo-upload.format" | translate }}</div>
		<div class="text-xs mb-2">{{ "photo-upload.size" | translate }}</div>
	</div>
	<div>
		<!-- <div class="text-sm">
			{{ preview ? ("photo-upload.preview" | translate) : '&nbsp;'}}
		</div> -->
		<div *ngIf="preview" class="h-32 w-32">
			<img class="object-cover h-full w-full" [src]="preview" alt="image preview" />
		</div>
	</div>
</div>