import { Injectable } from '@angular/core';
import { RoomStateStorage } from '../../components/call-room/service/storage/RoomStateStorage';
import { Observable } from 'rxjs';
import { JOIN, JOIN_SPY, VIEW, WebSocketService } from './web-socket.service';
import { RoomDataParserService } from './room-data-parser.service';
import { UserRole } from '../role.service';
import { RoomState } from '../../components/call-room/data/room-state';
import { USER_KEY } from '../../constants/localstorage-constants';
import { CallStorage } from '../../components/call-room/service/storage/call-storage.service';
import { LoggingService } from '../logging/logging.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IS_ROOM_FULL, TOWER_IS_ROOM_FULL } from '../../constants/connection-constants';
import { RoomInfo } from '../../models/room-info';

@Injectable()
export class RoomAuthService {
  constructor(private roomState: RoomStateStorage,
    private webSocketService: WebSocketService,
    private callStorage: CallStorage,
    private roomDataParserService: RoomDataParserService,
    private loggingService: LoggingService, // important to include
    private http: HttpClient,
    private authenticationService: AuthenticationService) { }

	private get authHttpOptions() {
		return {
			withCredentials: true,
			headers: new HttpHeaders({
				'Content-Type': 'application/json; charset=utf-8',
				Authorization: this.authenticationService.accessToken
			})
		};
	}

  checkJoinPossibility(roomId: string, userId?): // todo refactor (role: UserRole, roomId: string) to (params:IJoinParams)
    Observable<{ status: boolean, redirect?: string, error?: any }> {
    if (roomId) {

      this.callStorage.roomId = roomId;
      this.roomState.role = UserRole.AGENT;
      this.roomState.roomId = roomId;
      const params = this.defaultParams();
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const password = urlParams.get('password');
		const roomKey = urlParams.get('roomKey');
        const role = urlParams.get('role');
        const isBot = urlParams.get('recordingBot');

		if (roomKey) {
			params.roomKey = roomKey;
		}
		if (password) {
			params.password = password;
		}
		if (!roomKey && !password) {
			params.password = sessionStorage.roomPassword;
		}
        if (role) {
            params.role = role;
        }
		if (userId) {
			params.userId = userId;
		}
        else {
            let userIdParam= urlParams.get('userId');
            params.userId = userIdParam;
        }
        if (!!isBot) {
            params.recordingBot = isBot;
        }
      params.token = this.authenticationService.accessToken;

    //   console.log('view params', params); // params data wil be sent via ws

      this.webSocketService.dispatch(VIEW, params);
    }
    return new Observable<{ status: boolean, redirect?: string, error?: any }>(observer => {
      const stateSubscribe = this.roomDataParserService.getState().subscribe(state => {
        unsubscribe();
        observer.next({ status: state !== RoomState.CANCEL && state !== RoomState.FINISH });
      });
      const errSubscribe = this.roomDataParserService.isError.subscribe((err: any) => {
        unsubscribe();
        observer.next({ status: false, error: err });
      });
      const unsubscribe = () => {
        stateSubscribe.unsubscribe();
        errSubscribe.unsubscribe();
      };
    });
  }
  isAuth(): boolean {
    const username: string = sessionStorage.getItem(USER_KEY);
    const isLiveStatus: boolean = sessionStorage.isLive === 'true';
    if (isLiveStatus && username && username.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  joinRoom = (userId?): Observable<{ status: boolean, redirect?: string, error?: any }> => {
    return new Observable<{ status: boolean, redirect?: string, error?: any }>(observer => {
      const data = this.callStorage.serverData;
      if (data) {
        const params = this.defaultParams();
		  const queryString = window.location.search;
		  const urlParams = new URLSearchParams(queryString);
		  const password = urlParams.get('password');
		  const roomKey = urlParams.get('roomKey');
          const role = urlParams.get('role');
          const isBot = urlParams.get('recordingBot');
		  if (password) {
			  params.password = password;
		  }
		  if (roomKey) {
			  params.roomKey = roomKey;
		  }
			if (role) {
				params.role = role;
			}
        params.name = sessionStorage.getItem(USER_KEY);
		  if (!roomKey) {
			  params.password = sessionStorage.roomPassword;
		  } 
        params.token = this.authenticationService.accessToken;
        if (userId) {
            params.userId = userId;
        }
        const skipDemand = (window.sessionStorage.getItem('skipDemand') === 'true');
        if (skipDemand) {
          params.skipDemand = true;
        }
        if (!!isBot) {
            params.recordingBot = isBot;
        }
		  this.webSocketService.dispatch(JOIN, params);
        observer.next({ status: true });
      } else {
        observer.next({ status: false });
      }
    });
  }
  joinSpy = (): Observable<{ status: boolean, err?: any }> => {
    return new Observable<{ status: boolean, err?: any }>(observer => {
      this.webSocketService.dispatch(JOIN_SPY, this.defaultParams());
      const unsubscribe = () => {
        successSubscribe.unsubscribe();
        errorSubscribe.unsubscribe();
      };
      const successSubscribe = this.callStorage.usersStorage.selfUpdate$.subscribe(() => {
        unsubscribe();
        observer.next({ status: true });
      });
      const errorSubscribe = this.roomDataParserService.isError.subscribe((err) => {
        unsubscribe();
        observer.next({ status: false, err });
      });
    });

  }
  private defaultParams(isDeviseInfo: boolean = false): any {
    const params: any = {
      slug: this.callStorage.roomId
    };
    if (isDeviseInfo) {
      params.agent = this.loggingService.getAgent();
      params.device = this.loggingService.getDevice();
      params.camera = this.loggingService.getCamera();
      params.microphone = this.loggingService.getMicrophone();
    }
    return params;
  }

  public getRoomInfo(roomId: string): Observable<RoomInfo> {
    return this.http.get<RoomInfo>(IS_ROOM_FULL.replace(':id', roomId), this.authHttpOptions);
  }

  public getRoomInfoForUnauthorisedUser(roomId: string): Observable<RoomInfo> {
	  return this.http.get<RoomInfo>(TOWER_IS_ROOM_FULL.replace(':id', roomId));
  }

}

