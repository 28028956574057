<div class="container p-4 max-w-xs">
    <div *ngIf="status=='accepted'">
        <p class="mb-2">

            {{"lessons.accept-string" | translate}}
        </p>
        <div class="flex justify-end">
            <button (click)="close()">{{"lessons.cancel" | translate}}</button>
            <button (click)="updateBookingStatus()" class="btn btn-primary ml-2 block">
                {{"lessons.accept-request" |
                translate}}</button>
        </div>
    </div>
    <div *ngIf="status=='declined'">
        <p class="mb-2">
            {{"lessons.decline-string" | translate}}

        </p>
        <div class="flex justify-end">
            <button (click)="close()">{{"lessons.cancel" | translate}}</button>
            <button (click)="updateBookingStatus()" class="btn button btn-primary ml-2 block">
                {{"lessons.decline-request"
                | translate}}</button>
        </div>
    </div>
</div>