import { Injectable } from "@angular/core";
import {
	FILES_CLOUD,
	GET_FILES_CLOUD
} from "../../constants/connection-constants";
import { AuthenticationService } from "../authentication/authentication.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class FilesCloudService {
	constructor(
		private http: HttpClient,
		private auth: AuthenticationService
	) {}

	private get authHttpOptions() {
		return {
			withCredentials: true,
			headers: new HttpHeaders({
				Authorization: this.auth.accessToken
			})
		};
	}

	getSharedFilesByUser(
		userId: string,
		limit: number,
		page: number = 1
	): Observable<any> {
		const url = GET_FILES_CLOUD.replace(":userId", userId);

		return this.http.get(
			`${url}/shared?limit=${limit}&page${page}`,
			this.authHttpOptions
		);
	}

	getMyFiles(
		userId: string,
		limit: number,
		page: number = 1
	): Observable<any> {
		const url = GET_FILES_CLOUD.replace(":userId", userId);

		return this.http.get(
			`${url}/my?limit=${limit}&page=${page}`,
			this.authHttpOptions
		);
	}

	getFileInfo(fileId: string): Observable<any> {
		return this.http.get(`${FILES_CLOUD}/${fileId}`, this.authHttpOptions);
	}

	addFile(
		userId: string,
		file: File,
		type: string = "material"
	): Observable<any> {
		const formData: FormData = new FormData();
		formData.append("userId", userId);
		formData.append("file", file, file.name);
		formData.append("type", type);

		return this.http.post(`${FILES_CLOUD}`, formData, this.authHttpOptions);
	}

	renameFile(fileId: string, newFileName: string): Observable<any> {
		const formData: FormData = new FormData();
		formData.append("name", newFileName);

		return this.http.put(
			`${FILES_CLOUD}/${fileId}`,
			formData,
			this.authHttpOptions
		);
	}

	removeFile(fileId: string): Observable<any> {
		return this.http.delete(
			`${FILES_CLOUD}/${fileId}`,
			this.authHttpOptions
		);
	}

	shareFile(fileId: string, recipientId: string): Observable<any> {
		const formData: FormData = new FormData();
		formData.append("recipientId", recipientId);

		return this.http.put(
			`${FILES_CLOUD}/${fileId}/share`,
			formData,
			this.authHttpOptions
		);
	}

	revokeSharedFile(fileId: string, recipientId: string): Observable<any> {
		const formData: FormData = new FormData();
		formData.append("recipientId", recipientId);

		return this.http.put(
			`${FILES_CLOUD}/${fileId}/revoke`,
			formData,
			this.authHttpOptions
		);
	}
}
