import { Injectable } from '@angular/core';
import { copyParams } from './data/ServerResponceParcer';
import { MediaSettingsStorageService } from '../components/call-room/service/storage/media-settings-storage.service';
import { MediaDevicesService } from '../lib-media-devices/services/media-devices.service';
import {PlatformDetectorService} from "./platform-detector/platform-detector.service";

@Injectable({
  providedIn: 'root'
})
// todo remove
export class CaptureMediaService {
  private selectDeviceIndex = 0;
  constructor(
    private platformDetector: PlatformDetectorService,
    private msStorage: MediaSettingsStorageService,
    private mediaDevicesService: MediaDevicesService) { }
  /*public get audioConfig() {
    const urlParams = new URLSearchParams(window.location.search);
    // @ts-ignore
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    const disableAP = urlParams.get('disableAP') === 'true';  // disables all audio processing listed bellow
    const disableAEC = disableAP || urlParams.get('disableAEC') === 'true';
    const disableNS = disableAP || urlParams.get('disableNS') === 'true';
    const disableAGC = disableAP || urlParams.get('disableAGC') === 'true';
    // const disableHPF = disableAP || urlParams.get('disableHPF') === 'true';

    const cfg = Object.assign({}, this.msStorage.aConfig); // clone current config
    if (isChrome) {  // no idea about Safari
      // logger.log('Case for Chrome.');
      // @ts-ignore
      cfg.advanced = [
        // { sourceId: 'default' },
        { echoCancellation: disableAEC ? false : cfg.echoCancellation },
        { autoGainControl: disableAGC ? false : cfg.autoGainControl },
        { noiseSuppression: disableNS ? false : cfg.noiseSuppression },
        // @ts-ignore
        // { googHighpassFilter: disableHPF ? false : cfg.googHighpassFilter },
      ];
    } else {
      logger.log('Case for NOT a Chrome');
      cfg.echoCancellation = disableAEC ? false : cfg.echoCancellation;
      cfg.noiseSuppression = disableNS ? false : cfg.noiseSuppression;
      cfg.autoGainControl = disableAGC ? false : cfg.autoGainControl;
    }

    // deviceId handling is different in Firefox and Chrome
    if (cfg.deviceId && cfg.deviceId !== 'any') {
      return cfg;
    } else {
      const returnParams: any = Object.assign({}, cfg);
      return returnParams;
    }
  }*/
  /*public get videoConfig(): any | null {
    try {
      if (!this.msStorage.vConfig.deviceId || this.msStorage.vConfig.deviceId === 'any') {
        this.msStorage.vConfig.deviceId = this.getDefaultId();
      }
      return this.msStorage.vConfig;
    } catch (e) {
      console.error('video error', e);
      return null;
    }
  }*/
  public get displayConfig() {
    if (this.platformDetector.isDesktop() && this.platformDetector.isBrowserSafari()) {
      return {
        cursor: 'always', frameRate: 12,
      };
    }
    return {
      width: 1280, height: 720, cursor: 'always', frameRate: 12,
    };
  }
  get outputConfig() {
    return this.msStorage.outputConfig;
  }
  updateVideoConfig(value: { width?: number, height?: number, deviceId?: string }) {
    const params = { width: 'width', height: 'height', frameRate: 'frameRate', deviceId: 'deviceId' };
    copyParams(this.msStorage.vConfig, value, params);
  }
  updateAudioConfig(value: { deviceId: string }) {
    const params = { deviceId: 'deviceId' };
    copyParams(this.msStorage.aConfig, value, params);
  }
  updateOutputConfig(value: { deviceId: string }) {
    this.msStorage.outputConfig = value;
  }

  getDisplay = async(next: (audio: MediaStream, video: MediaStream) => void, onError?: (reason: any) => void) => {
    try {
      const videoStream = await this.mediaDevicesService.getDisplayVideo(this.displayConfig);
      const audioStream = await this.mediaDevicesService.getDisplayAudio();
      next(audioStream, videoStream);
    } catch (err) {
      onError(err);
    }
  }

  onError(next: () => void, onError: () => void) {
    if (this.msStorage.vDeviceList.length > this.selectDeviceIndex + 1) {
      this.selectDeviceIndex++;
      this.msStorage.vConfig.deviceId = 'any';
      next();
    } else {
      onError();
    }
  }

  private getDefaultId = (): string => {
    try {
      if (this.msStorage && this.msStorage.vDeviceList && this.msStorage.vDeviceList.length > 0 &&
        this.msStorage.vDeviceList[this.selectDeviceIndex]) {
        return this.msStorage.vDeviceList[this.selectDeviceIndex].deviceId;
      } else {
        return 'any';
      }
    } catch (e) {
      return 'any';
    }
  }
}
