import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { WS_URL } from 'src/app/lib-core/constants/constants';
import { logger } from 'src/app/lib-core/logger';

// event names
export const VIEW = 'view';
export const JOIN = 'join';
export const JOIN_SPY = 'joinSpy';
export const GONE = 'gone';
export const ROOM = 'room';
export const SEND = 'send';
export const CHAT = 'chat';
export const ERROR = 'error';
export const TURN = 'turn';
export const DEMAND = 'demand';
export const AFFECT = 'affect';
export const KICK = 'kick';
export const MUTE = 'mute';
export const UNMUTE = 'unmute';
export const HIDE = 'hide';
export const UNHIDE = 'unhide';
export const NOTIFY = 'notify';
export const RECORDING = 'recording';
export const WAITING = 'waiting';
export const CLEAR_CHAT = 'clearChat';

export interface IWebSocketService {
  sendEvent(eventName: string, eventObj: any): void;
  eventByName(name: string): Observable<any>;
  subscribe(eventName: string, listener: ( ...params: any) => void);
  dispatch(eventName: string, data?: any, listener?: ( ...data: any) => void);
  unSubscribe(evenName: string, listener: ( ...params: any) => void);
}
// please use it service only in OnDestroy class, we need to clean listeners;
// todo add remove logic;
@Injectable({
    providedIn: 'root'
})
export class WebSocketService implements IWebSocketService { // interface just for good look
  private isConnected: boolean = false;
  private socket = this.initSocket();
  private listeners = {}; // associative array

  public get socketId(): string {
    return this.socket.id;
  }

  public sendEvent(eventName: string, eventObj: any): void {
    // logger.log('send "' + eventName + '" ', eventObj); // TODO aam del;
    this.socket.emit(eventName, eventObj);
  }

  public eventByName(name: string): Observable<any> {
    if (!name) {
      throw new Error('The required parameter "name" is not specified.');
    }
    return new Observable(observer => {
      this.socket.on(name, (msg: any) => {
        // logger.log('get "' + name + '" ', msg); // TODO aam del;
        observer.next([msg]);
      });
    });
  }

  subscribe = (eventName: string, listener: ( ...params: any) => void) => {
    this.addSubscribe(eventName, listener);
  }
  dispatch = (eventName: string, data?: any, listener?: ( ...data: any) => void) => {
    // logger.log('[webS] dispatch ', eventName, 'data ', data);
    if (eventName === VIEW && !this.socket.connected) {
      this.socket.connect();
    }
    if (eventName === JOIN) {
      // don't see any send in this error
      if (this.isConnected) {
        throw new Error('you already joined');
      }
      this.isConnected = true;
    } else if (eventName === GONE) {
      // if (!this.isConnected) {
      //   throw new Error('you already gone');
      // }
      this.isConnected = false;
    }
    if(data) {
      // Todo: fix permanently for localhost
      // data.subdomain = "localhost"; // uncomment this line for local mobile access
      data.subdomain = location.hostname.split('.')[0];
    }
    this.socket.emit(eventName, data, (answer) => {
      if (listener) {
        listener(answer);
      }
    } );
  }
  unSubscribe = (evenName: string, listener: ( ...params: any) => void) => {
    const listenerVO = this.listeners[evenName] as ListenerVo;
    if ( listenerVO) {
      listenerVO.removeListener(listener);
    }
  }
  private initSocket() {
	  return io(WS_URL, {
		  path: '/socket.io',
	  });
  }
  private isExist = (evenName: string, listener: ( ...params: any) => void): boolean => {
    const listenerVO = this.listeners[evenName] as ListenerVo;
    if (listenerVO) {
      if (listenerVO.listeners.indexOf(listener) > -1) {
        return true;
      }
    }
    return false;
  }
  private addSubscribe = (evenName: string, newListener: ( ...params: any) => void) => {
    let listenerVO = this.listeners[evenName] as ListenerVo;
    if (!listenerVO) {
      listenerVO = new ListenerVo();
      this.listeners[evenName] = listenerVO;
      this.socket.on(evenName, (...data) => {
        listenerVO.listeners.map( (localListener) => {
          localListener(data);
        });
      });
    }
    listenerVO.addListener(newListener);
  }
}
class ListenerVo {
  listeners: Array<(...params: any) => void> = new Array<(...params: any) => void>();
  addListener(listener: (...params: any) => void) {
    if (!this.listeners.includes(listener)) {
      this.listeners.push(listener);
    }
  }
  removeListener(listener: (...params: any) => void) {
    this.listeners =  this.listeners.filter(l => l !== listener );
  }
}

