import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
	IS_LOGGED_IN,
	AUTH_URL,
	RECOVERY,
	REGISTRATION,
	SIGN_UP,
	LOG_OUT,
	GET_USER_PROFILE,
	CHECK_CONFIRMATION,
	REGISTRATION_CONFIRM,
	RECOVERY_CONFIRM,
	AUTH_ADMIN_URL,
	TOKEN_REFRESH,
	TEACH_SIGN_UP
} from "../../constants/connection-constants";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { Profile } from "src/app/types/profile.types";
import { catchError, first, map, skip, tap, shareReplay } from "rxjs/operators";
import { ENGLISH } from "../../constants/localstorage-constants";
import { LanguageService } from "../languages/language.service";
import { Language } from "src/app/models/language";
import { InitialAuthorizationService } from "src/app/lib-core/services/initial-authorization.service";
import { logger } from "src/app/lib-core/logger";
import { profileMap } from "src/app/profile/profile-map";

const WARNING_FINISH_THE_TARIFF_FREE = "warningFinishTheTariffFree";

const HTTP_OPTIONS = {
	withCredentials: true
};

export interface TokenPayload {
	authorized: string;
	email: string;
	exp: number;
	iat: number;
	id: string;
	languageId: string;
	name: string;
	organizationId: string;
	roleId: string;
	subroleId: string;
}

@Injectable({
	providedIn: "root"
})
export class AuthenticationService {
	private refreshingToken = false;

	get isInTheRoom(): boolean {
		return this.isInTheRoomPrivate;
	}
	public get accessToken(): string {
		// Missing accessToken provokes empty profile when signing up or entering with 'stay signed in'
		// Please check this case when changing the line below
		return (
			this._accessToken ||
			window.localStorage.getItem("accessToken") ||
			window.sessionStorage.getItem("accessToken")
		);
	}
	public set accessToken(value: string) {
		this._accessToken = value;
		if (value === null) {
			window.sessionStorage.removeItem("accessToken");
			this.storage.removeItem("accessToken");
		} else {
			window.sessionStorage.setItem("accessToken", value);
			this.storage.setItem("accessToken", value);
		}
	}
	public get refreshToken(): string {
		return (
			this._refreshToken ||
			window.localStorage.getItem("refreshToken") ||
			window.sessionStorage.getItem("refreshToken")
		);
	}
	public set refreshToken(value: string) {
		this._refreshToken = value;
		if (value === null) {
			window.sessionStorage.removeItem("refreshToken");
			this.storage.removeItem("refreshToken");
		} else {
			window.sessionStorage.setItem("refreshToken", value);
			this.storage.setItem("refreshToken", value);
		}
	}
	public get profile(): Promise<Profile> {
		return new Promise((resolve, reject) => {
			if (!this._profile) {
				if (!this.isLoggedIn$.value) {
					if (this.refreshToken) {
						setTimeout(() => {
							resolve(this.profile);
						}, 50);
					} else {
						resolve(null);
					}
				} else {
					if (this._loadingProfile) {
						setTimeout(() => {
							resolve(this.profile);
						}, 50);
					} else {
						if (!this.accessToken) {
							setTimeout(() => {
								resolve(this.profile);
							}, 50);
						} else {
							this._loadingProfile = true;
							const AUTH_HTTP_OPTIONS = {
								...HTTP_OPTIONS,
								headers: this.headers
							};
							this.http
								.get(GET_USER_PROFILE, AUTH_HTTP_OPTIONS)
								.pipe(first())
								.subscribe((profile: Profile) => {
									this._loadingProfile = false;
									const parsed = profileMap(profile);
									this._profile = parsed;
									logger.log("profile loaded!");
									resolve(parsed);
								});
						}
					}
				}
			} else {
				resolve(this._profile);
			}
		});
	}

	get isLoaderTriggered(): Observable<number> {
		return this._isLoaderTriggered.asObservable();
	}

	public permissions$ = new BehaviorSubject<any>(null);

	public get permissions(): any {
		return this.permissions$.value;
	}

	public set permissions(data: any) {
		if (data.users) {
			const selfUser = data.users.find((user) => user.self === true);

			if (selfUser) {
				this.permissions$.next(selfUser.permissions);
			}
		}
	}

	public get remembered(): boolean {
		return !!window.localStorage.getItem("rememberMe");
	}

	constructor(
		private http: HttpClient,
		private languageService: LanguageService,
		private initialAuthorizationService: InitialAuthorizationService
	) {
		this.addOpenedTab(this.tabId);
		this.checkLocalStorage();
		this.isLoggedIn$.subscribe(async (loggedIn) => {
			//   if (!!loggedIn && !this.email) { // if logged in and email is not set, fetch from backend
			//     this.email = (await this.profile).email;
			//   } else if (!loggedIn && !this.email && this.refreshToken) { // if logged out and email is not set, but there is a refresh token, fetch from backend
			//     const profile = await this.profile;
			//     if (profile) {
			//       this.email = profile.email;
			//     }
			//   } else if (!loggedIn && this.email) { // if logged out and email is set, clear it, and tokens
			//     this.email = null;
			//     this.accessToken = null;
			//     this.refreshToken = null;
			//   }
			if (!loggedIn && this.email) {
				this.email = null;
				this.accessToken = null;
				this.refreshToken = null;
			}
		});
	}

	get headers(): HttpHeaders | {} {
		try {
			return this.accessToken
				? new HttpHeaders({
						"Content-Type": "application/json; charset=utf-8",
						Authorization: this.accessToken
				  })
				: {};
		} catch (e) {
			console.error(e);
		}
	}

	// Due to Angular bug we get empty payload if we set Content-Type header explicitly to any value
	get formDataHeaders(): HttpHeaders | {} {
		try {
			return this.accessToken
				? new HttpHeaders({
						Authorization: this.accessToken
				  })
				: {};
		} catch (e) {
			console.error(e);
		}
	}
	storage = window.localStorage;
	tabId = window.sessionStorage.getItem("tabId") || Math.random().toString();

	private _isLoaderTriggered: Subject<number> = new Subject<number>();
	public isLoggedIn$ = new BehaviorSubject<boolean>(false);
	private isRoomEntryPage = false;
	private _profile: Profile | null = null;
	private _loadingProfile = false;
	// ToDo: Rework those. And move all the necessary data to users storage
	email: string;
	firstName: string;
	lastName: string;
	// SIRIUS-754 Modify Sign up detail page
	// timeZone: number;
	name: string;
	// ToDo: Refactor this
	private isInTheRoomPrivate: boolean = false;
	private isInTheRoom$: Subject<boolean> = new Subject<boolean>();
	private _accessToken: string;
	private _refreshToken: string;
	private lastTokenUpdate: number = 0;
	private updateTokenThrottleTime: number = 5000; // 5 seconds

	public profileInfo(): Profile | null {
		return this._profile;
	}

	public isApprover(): boolean {
		return this.checkPermission("approver");
	}

	public canJoin(): boolean {
		return this.checkPermission("join");
	}

	private checkPermission(permission: string): boolean {
		return !!this.permissions.find(
			(userPermission: string) => userPermission === permission
		);
	}

	public setRoomEntryPage(value: boolean): void {
		this.isRoomEntryPage = value;
	}
	public getRoomEntryPage(): boolean {
		return this.isRoomEntryPage;
	}

	public async userAuthorization(
		userEmail: string,
		userPassword: string,
		rememberUser: boolean
	): Promise<any> {
		const lowerCaseEmail = userEmail && userEmail.toLowerCase();
		await this.authorize$(
			lowerCaseEmail,
			userPassword,
			!!rememberUser
		).toPromise();
		this.isLoggedIn$.next(true);
		this.initialAuthorizationService.setHasInitialAuthorization(true);
		this.resetProfile();
		const profile = await this.profile;
		window.sessionStorage.setItem("firstName", profile.firstName);
		const language: Language = this.languageService.getLanguageById(
			profile.languageId
		);
		await this.languageService.change(!!language ? language.iso : null);
		return null;
	}

	triggerLoader(value?: number): void {
		this._isLoaderTriggered.next(value);
	}

	addOpenedTab(tabId: string) {
		window.sessionStorage.setItem("tabId", tabId);
		const savedTabs = window.localStorage.getItem("tabs");
		let openedTabs = [];
		if (savedTabs) {
			openedTabs = JSON.parse(savedTabs);
		}
		openedTabs.push(tabId);
		this.accessToken = this.accessToken; // set to sessionStorage if present in local
		this.refreshToken = this.refreshToken;
		window.localStorage.setItem("tabs", JSON.stringify(openedTabs));
	}

	checkLocalStorage() {
		if (
			window.localStorage.getItem("refreshToken") &&
			window.localStorage.getItem("accessToken")
		) {
			this.storage = window.localStorage;
		}
	}

	updateInTheRoomStatus(value: boolean): void {
		this.isInTheRoomPrivate = value;
		this.isInTheRoom$.next(value);
	}

	isInTheRoomStatus$(): Observable<boolean> {
		return this.isInTheRoom$.asObservable();
	}

	checkLoggedIn$(): Observable<any> {
		if (!this.accessToken) {
			if (this.refreshToken) {
				this._accessToken = this.refreshToken;
				this.refreshToken = null;
			} else {
				return of(false);
			}
		}

		const AUTH_HTTP_OPTIONS = {
			...HTTP_OPTIONS,
			headers: this.headers
		};

		return this.http.get(IS_LOGGED_IN, AUTH_HTTP_OPTIONS).pipe(
			catchError((error, caught) => {
				if (error.status === 401) {
					if (this.refreshToken && this.accessToken) {
						this.accessToken = this.refreshToken;
						this.refreshToken = null;
						return this.checkLoggedIn$();
					} else {
						this.refreshToken = null;
						this.accessToken = null;
						return of(false);
					}
				}
				throw error;
			})
		);
	}

	authorizeAdmin$(
		userEmail: string,
		userPassword: string,
		remember?: boolean
	): Observable<any> {
		this.storage = window.localStorage;
		if (!!remember) {
			window.localStorage.setItem("rememberMe", "true");
		}
		return this.http
			.post(
				AUTH_ADMIN_URL,
				{ email: userEmail, password: userPassword },
				HTTP_OPTIONS
			)
			.pipe(
				tap((response) => {
					this.accessToken = response.accessToken;
					this.refreshToken = response.refreshToken;
				})
			);
	}

	authorize$(
		userEmail: string,
		userPassword: string,
		remember?: boolean
	): Observable<any> {
		this.storage = window.localStorage;
		if (!!remember) {
			window.localStorage.setItem("rememberMe", "true");
		}
		return this.http
			.post(
				AUTH_URL,
				{
					email: userEmail,
					password: userPassword,
					staySignIn: remember
				},
				HTTP_OPTIONS
			)
			.pipe(
				tap((response) => {
					this.accessToken = response.accessToken;
					this.refreshToken = response.refreshToken;
					return null;
				})
			);
	}

	signup$(): Observable<any> {
		return this.http.post(
			SIGN_UP,
			{
				email: this.email,
				locale: this.languageService.selectedLocale || ENGLISH,
				language:
					this.languageService.selectedLangId ||
					"cf75bf5b21b7f6103679a1c7fd2a975a",
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
			},
			HTTP_OPTIONS
		);
	}

	teachSignup$(): Observable<any> {
		return this.http.post(
			TEACH_SIGN_UP,
			{
				email: this.email,
				locale: this.languageService.selectedLocale || ENGLISH,
				language:
					this.languageService.selectedLangId ||
					"cf75bf5b21b7f6103679a1c7fd2a975a",
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
			},
			HTTP_OPTIONS
		);
	}

	recoverEmail$(): Observable<any> {
		return this.http.post(
			RECOVERY,
			{
				email: this.email,
				locale: this.languageService.selectedLocale || ENGLISH
			},
			HTTP_OPTIONS
		);
	}

	register$(key: string, data: any): Observable<any> {
		return this.http
			.put(REGISTRATION + "/" + key, data, HTTP_OPTIONS)
			.pipe(tap((response) => {}));
	}

	checkRegistration$(id: string): Observable<any> {
		return this.http.get(
			CHECK_CONFIRMATION + REGISTRATION_CONFIRM + "/" + id
		);
	}

	checkRecovery$(id: string): Observable<any> {
		return this.http.get(CHECK_CONFIRMATION + RECOVERY_CONFIRM + "/" + id);
	}

	resetPass$(key: string, data: any): Observable<any> {
		return this.http.put(RECOVERY + "/" + key, data, HTTP_OPTIONS);
	}

	getWarningFinishTheTariffFree(): string {
		return sessionStorage.getItem(WARNING_FINISH_THE_TARIFF_FREE);
	}

	setWarningFinishTheTariffFree(value: string = "1"): void {
		sessionStorage.setItem(WARNING_FINISH_THE_TARIFF_FREE, value);
	}

	logOut$(): Observable<any> {
		this.resetProfile();
		sessionStorage.removeItem(WARNING_FINISH_THE_TARIFF_FREE);
		sessionStorage.removeItem("musicDialogOpened");
		localStorage.removeItem("rememberMe");
		const AUTH_HTTP_OPTIONS = {
			...HTTP_OPTIONS,
			headers: this.headers
		};
		// TODO: remove body token
		return this.http
			.post(LOG_OUT, { token: this.refreshToken }, AUTH_HTTP_OPTIONS)
			.pipe(
				tap(() => {
					this.accessToken = null; // clear all tokens if successfull
					this.refreshToken = null;
					this.isLoggedIn$.next(false); // let others know that logged out
					this.initialAuthorizationService.setHasInitialAuthorization(
						false
					);
				})
			);
	}

	removeOpenedTab(): void {
		const savedTabs = window.localStorage.getItem("tabs");
		if (savedTabs) {
			let openedTabs = JSON.parse(savedTabs);
			openedTabs = openedTabs.filter((tabId) => {
				return tabId !== this.tabId;
			});
			if (
				!window.localStorage.getItem("accessToken") &&
				!window.localStorage.getItem("refreshToken")
			) {
				window.sessionStorage.removeItem("accessToken"); // clear session token if local is not present
				window.sessionStorage.removeItem("refreshToken"); // in case if logged out in other tab
			}
			if (openedTabs.length > 0) {
				window.localStorage.setItem("tabs", JSON.stringify(openedTabs));
			} else {
				// if this is only tab opened
				/*
        if (!window.localStorage.getItem('rememberMe')) { // and stay signed in not checked
          window.localStorage.removeItem('accessToken'); // remove localstorage tokens
          window.localStorage.removeItem('refreshToken');
        }
    */
				window.localStorage.removeItem("tabs");
			}
		}
	}

	resetProfile(): void {
		this._profile = null;
	}

	private getTokenExpirationDate(token: TokenPayload): Date {
		return new Date(token.exp * 1000);
	}

	private getTokenPayload(token: string): TokenPayload {
		try {
			return JSON.parse(window.atob(token.split(".")[1]));
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	getSessionExpirationDate(): Date {
		if (this.refreshToken && !this.remembered) {
			// exclude 'stay signed in' users
			return this.getTokenExpirationDate(
				this.getTokenPayload(this.refreshToken)
			);
		} else {
			return null;
		}
	}

	updateToken(): Observable<string> {
		return new Observable<string>((obs) => {
			const now = Date.now();

			if (now - this.lastTokenUpdate < this.updateTokenThrottleTime) {
				obs.next(null);
				obs.complete();
				return;
			}

			this.lastTokenUpdate = now;

			if (!this.refreshToken || this.refreshingToken) {
				obs.next(null);
				obs.complete();
			} else {
				this.refreshingToken = true;
				this.http
					.post(
						TOKEN_REFRESH,
						{ token: this.refreshToken },
						{
							withCredentials: true,
							headers: { "Content-Type": "application/json" }
						}
					).pipe(
                        shareReplay(1) 
                    )
                    .subscribe(
						(data: {
							accessToken: string;
							refreshToken: string;
						}) => {
							this.accessToken = data.accessToken;
							this.refreshToken = data.refreshToken;
							this.refreshingToken = false;
							obs.next(this.accessToken);
							obs.complete();
						},
						(error) => {
							obs.error(error);
						}
					);
			}
		});
	}

	clearTokens() {
		this.accessToken = null;
		this.refreshToken = null;
	}

	getSubrole() {
		return this.profile.then((profile) => {
			return profile.subrole;
		});
	}
}
