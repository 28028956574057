"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var reduce_1 = require("./reduce");
function max(comparer) {
  var max = typeof comparer === 'function' ? function (x, y) {
    return comparer(x, y) > 0 ? x : y;
  } : function (x, y) {
    return x > y ? x : y;
  };
  return reduce_1.reduce(max);
}
exports.max = max;
