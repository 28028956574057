import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RatingComponent } from "./rating/rating.component";
import { TranslateModule } from "@ngx-translate/core";
import { IntlTelInputComponent } from "./intl-tel-input/intl-tel-input.component";
import { PhotoUploadComponent } from "./photo-upload/photo-upload.component";
import { MatButtonModule } from "@angular/material/button";
import { MatFormField } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@NgModule({
	declarations: [
		RatingComponent,
		IntlTelInputComponent,
		PhotoUploadComponent
	],
	imports: [CommonModule, TranslateModule, MatButtonModule, MatFormField, MatInputModule],
	exports: [RatingComponent, IntlTelInputComponent, PhotoUploadComponent]
})
export class FormElementsModule {}
