import { Injectable } from "@angular/core";
// import { BROWSERS, DeviceDetectorService, OS } from '@yberion/ngx-device-detector_tmp';
// import { DeviceInfo } from '@yberion/ngx-device-detector_tmp/lib/device-detector.service';

import { DeviceDetectorService, OS, BROWSERS } from "ngx-device-detector";

interface IosVersion {
	User_Agent: string;
	As_Reported: string;
	Major_Release: string;
	Full_Release: string;
	Major_Release_Numeric: number;
	Full_Release_Numeric: number;
}

const TABLET_MIN_WIDTH = 600;
const TABLET_MAX_WIDTH = 1366; // ipad pro

@Injectable({
	providedIn: "root",
})
export class PlatformDetectorService {
	public deviceInfo;
	public os: string;

	constructor(private deviceDetectorService: DeviceDetectorService) {
		this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
		switch (this.deviceInfo.os) {
			case OS.MAC:
				this.os = "mac";
				break;
			case OS.IOS:
				this.os = "ios";
				break;
			case OS.ANDROID:
			case OS.WINDOWS_PHONE:
				this.os = "android";
				break;
			default:
				this.os = "windows";
		}
	}

	isIPad(): boolean {
		return (
			(/Macintosh/.test(navigator.userAgent) &&
				"ontouchend" in document) ||
			/iPad/.test(navigator.userAgent)
		);
	}

	isIphone(): boolean {
		return ["iPhone"].includes(navigator.platform);
	}

	isSafari(): boolean {
		return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	}

	isAndroid(): boolean {
		return this.os === "android";
	}

	isMobile(): boolean {
		return this.deviceDetectorService.isMobile();
	}

    isHuaweiBrowser(): boolean {
        return navigator.userAgent.includes('HuaweiBrowser');
    }

    isTabletDeviceDetectorOnly() {
        return this.deviceDetectorService.isTablet()
     }

	isTablet(): boolean {
		const isTouchDevice =
			"ontouchstart" in window ||
			navigator.maxTouchPoints > 0 ||
			// @ts-ignore
			navigator.msMaxTouchPoints > 0;

		const screenWidth = window.screen.width;
		const screenHeight = window.screen.height;

		const isScreenSizeTablet =
			screenWidth >= TABLET_MIN_WIDTH &&
			screenWidth <= TABLET_MAX_WIDTH &&
			screenHeight >= TABLET_MIN_WIDTH &&
			screenHeight <= TABLET_MAX_WIDTH;

		return (
			this.deviceDetectorService.isTablet() 
            || (isTouchDevice && isScreenSizeTablet)
		);
	}

	isDesktop(): boolean {
		return this.deviceDetectorService.isDesktop();
	}

	isBrowserSafari(): boolean {
		return this.deviceInfo.browser && this.deviceInfo.browser === "Safari";
	}

	isFirefox(): boolean {
		return this.deviceInfo.browser && this.deviceInfo.browser === "Firefox";
	}

	isOperaMini(): boolean {
		return (
			navigator.userAgent.indexOf("Opera Mini") > -1 ||
			//  @ts-ignore
			Object.prototype.toString.call(window.operamini) ===
				"[object OperaMini]"
		);
	}

	isIE(): boolean {
		return this.deviceInfo.browser === BROWSERS.IE;
	}

	isOrientationPortrait(): boolean {
		let result = false;
		try {
			if (this.deviceInfo.browser === "Safari") {
				const mql = window.matchMedia("(orientation: portrait)");
				result = mql.matches;
			} else {
				result = window.screen.orientation.type === "portrait-primary";
			}
		} catch (err) {
			console.error(err);
		}
		return result;
	}

	isOrientationLandscape(): boolean {
		return !this.isOrientationPortrait();
	}

	getIOS_version(): IosVersion {
		const iosInfo = {} as IosVersion;
		if (this.isSafari()) {
			const matchValue =
				navigator.userAgent.match(/OS (\d)?\d_\d(_\d)?/i) ||
				navigator.userAgent.match(/version\/\d\d?\.?\d?/i);
			iosInfo.User_Agent = navigator.userAgent;
			iosInfo.As_Reported = matchValue[0];
			iosInfo.Major_Release = matchValue[0].split("_")[0];
			iosInfo.Full_Release = matchValue[0].replace(/_/g, ".");
			iosInfo.Major_Release_Numeric = +matchValue[0]
				.split("_")[0]
				.replace("OS ", "")
				.replace("Version/ ", "");
			// converts versions like 4.3.3 to numeric value 4.33 for ease of numeric comparisons
			iosInfo.Full_Release_Numeric = +matchValue[0]
				.replace("_", ".")
				.replace("_", "")
				.replace("OS ", "");
		}
		return iosInfo;
	}

	isSupportedSafariVersion(): boolean {
		if (/apple/i.test(navigator.vendor)) {
			const matchValue =
				navigator.userAgent.match(/OS (\d)?\d_\d(_\d)?/i) ||
				navigator.userAgent.match(/version\/\d\d?\.?\d?/i);
			const version = matchValue && matchValue[0].replace("Version/", "");
			const num = version.replace(/\D+/g, "").substring(0, 2);
			return Number(num) >= 14;
		} else {
			return true;
		}
	}

	getSafariVersion(): number {
		if (/apple/i.test(navigator.vendor)) {
			const matchValue =
				navigator.userAgent.match(/OS (\d)?\d_\d(_\d)?/i) ||
				navigator.userAgent.match(/version\/\d\d?\.?\d?/i);
			const version = matchValue && matchValue[0].replace("Version/", "");
			const num = version.replace(/\D+/g, "").substring(0, 2);
			return Number(num);
		}
	}

	isRecommendedBrowser(): boolean {
		return [BROWSERS.CHROME, BROWSERS.FIREFOX].includes(
			this.deviceInfo.browser
		);
	}

	checkSupportBrowserType(): boolean {
		return !this.isOperaMini() && !this.isIE() && !this.isHuaweiBrowser();
	}

	//   checkSupportBrowserType(): boolean {
	//     let result = false;
	//     if (
	//       // For MacOS desktop version it should be shown for all browsers except of Chrome, Firefox, safari
	//       (this.deviceInfo.os === OS.MAC &&
	//         [BROWSERS.CHROME, BROWSERS.FIREFOX, BROWSERS.SAFARI, BROWSERS.OPERA].includes(this.deviceInfo.browser)) ||
	//       // For windows Desktop Version it should be shown for all browsers ex rot of Chrome, Firefox (safari does not exist for windows)
	//       (
	//         [OS.WINDOWS, OS.LINUX, OS.UNIX, OS.CHROME_OS, OS.FIREFOX_OS, OS.UNKNOWN].includes(this.deviceInfo.os) &&
	//         (this.deviceInfo.userAgent.includes('Chrome') || this.deviceInfo.browser === BROWSERS.FIREFOX)
	//       ) ||
	//       // For iPad/iPhone it should be shown for all browsers except of safari
	//       (this.deviceInfo.os === OS.IOS && this.deviceInfo.browser === BROWSERS.SAFARI) ||
	//       (this.deviceInfo.os === OS.IOS && this.deviceInfo.browser === BROWSERS.CHROME) ||
	//       // For Android smartphone/tablets it should be shown for all browsers except of chrome (safari does not exist for android)
	//       (this.deviceInfo.os === OS.ANDROID && this.deviceInfo.browser === BROWSERS.CHROME) ||
	//       (this.deviceInfo.os === OS.ANDROID && this.deviceInfo.browser === BROWSERS.SAMSUNG)
	//     ) {
	//       result = true;
	//     }
	//     return result;
	//   }
}
