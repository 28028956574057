import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MainVideoData} from '../../data/main-video-data';
import {UsersStorage} from './UsersStorage';
import {LanguageVo} from '../../../../services/data/LanguageVo';

export const MEDIA_ERROR = 'media error';

@Injectable({
  providedIn : 'root'
})
export class CallStorage {
  // a crutch to support bad code
  public serverData: any; // todo remove

  private mainVideoPrivate$: Subject<MainVideoData> = new Subject<MainVideoData>();
  private titlePrivate$: Subject<string> = new Subject<string>();
  private accessStatusPrivate$: Subject<string> = new Subject<string>();
  private languagesPrivate: Array<LanguageVo>;
  // default values

  private mainVideoPrivate = new MainVideoData(null, null);
  private usersStoragePrivate: UsersStorage = new UsersStorage();
  private titlePrivate = 'no name';

  // todo refactor
  public startTime: number;
  public role: string;
  public roomId: string;
  public settingParams: {micro: boolean, cam: boolean};

  constructor() {} // todo separate and move

  get accessStatus$(): Observable<string> {
    return this.accessStatusPrivate$.asObservable();
  }

  set accessStatus(value: string) {
    this.accessStatusPrivate$.next(value);
  }

  get usersStorage(): UsersStorage {
    return this.usersStoragePrivate;
  }
  get mainVideo$(): Observable<MainVideoData> {
    return this.mainVideoPrivate$.asObservable();
  }

  get mainVideo(): string {
    return this.mainVideoPrivate.currentVideo;
  }
  get prevVideo(): string {
    return this.mainVideoPrivate.prevVideo;
  }
  get title(): string {
    return this.titlePrivate;
  }
  get languages(): LanguageVo[] {
    return this.languagesPrivate;
  }
  updateMainVideo(value: string) {
    if (this.mainVideoPrivate.currentVideo !== value) {
      const data = new MainVideoData(value, this.mainVideoPrivate.currentVideo);
      this.mainVideoPrivate = data;
      this.mainVideoPrivate$.next(data );
    }
  }
  updateTitle(value: string) {
    if (this.titlePrivate !== value) {
      this.titlePrivate = value;
      this.titlePrivate$.next(value );
    }
  }
  updateLanguages(data: LanguageVo[]) {
    if (!this.languagesPrivate && data.length > 0) {
      this.languagesPrivate = data;
    }
  }
}
