"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
var Subscriber_1 = require("./Subscriber");
var OuterSubscriber = function (_super) {
  __extends(OuterSubscriber, _super);
  function OuterSubscriber() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  OuterSubscriber.prototype.notifyNext = function (outerValue, innerValue, outerIndex, innerIndex, innerSub) {
    this.destination.next(innerValue);
  };
  OuterSubscriber.prototype.notifyError = function (error, innerSub) {
    this.destination.error(error);
  };
  OuterSubscriber.prototype.notifyComplete = function (innerSub) {
    this.destination.complete();
  };
  return OuterSubscriber;
}(Subscriber_1.Subscriber);
exports.OuterSubscriber = OuterSubscriber;
